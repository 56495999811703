import { parseISO, format } from 'date-fns';

import { Box, Button, Container, Grid, GridItem, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Switch, Text, Textarea, useDisclosure } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { formatCurrency } from "../../services/formatCurrency";

export function PartnerMenu() {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [categories, setCategories] = useState([]);
  const [discountError, setDiscountError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [message, setMessage] = useState("");
  const [maxPriceWarning, setMaxPriceWarning] = useState(false);

  const [isCreatingProduct, setIsCreatingProduct] = useState(false);

  const [imageInputs, setImageInputs] = useState([
    {
      productFiles: [],
      previews: [],
    },
    {
      productFiles: [],
      previews: [],
    },
    {
      productFiles: [],
      previews: [],
    },
    {
      productFiles: [],
      previews: [],
    },
  ]);

  function handleImageUpload(index, e) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedImageInputs = [...imageInputs];
        updatedImageInputs[index] = {
          productFiles: [file],
          previews: [reader.result],
        };
        setImageInputs(updatedImageInputs);
      };
      reader.readAsDataURL(file);
    }
  }

  function handleDeleteImage(inputIndex, previewIndex) {
    const updatedImageInputs = [...imageInputs];
    updatedImageInputs[inputIndex].productFiles.splice(previewIndex, 1);
    updatedImageInputs[inputIndex].previews.splice(previewIndex, 1);
    setImageInputs(updatedImageInputs);
  }

  function formatDateForDisplay(dateString) {
    const parsedDate = parseISO(dateString);
    return format(parsedDate, 'dd/MM/yyyy');
  }
  
  
  const[productsInputs, setProductsInputs] = useState({
    productName: "",
    productCategory: "",
    productMarketValue: null,
    productValue: null,
    productAmount: "",
    productDescription: "",
    productStartDate: "",
    productEndDate: "",
    productDiscount: null,
    productIsAlcoholic: false,
    productFiles: []
  })
  
  useEffect(() => {
    if (productsInputs.productValue && productsInputs.productMarketValue) {
      let formattedProductMarketValue = formatCurrency(productsInputs.productMarketValue);
      formattedProductMarketValue = parseFloat(formattedProductMarketValue.replace(/\D/g, '')) / 100;
  
      let formattedProductValue = formatCurrency(productsInputs.productValue);
      formattedProductValue = parseFloat(formattedProductValue.replace(/\D/g, '')) / 100;
  
      console.log("Original Price:", formattedProductMarketValue);
      console.log("Discounted Price:", formattedProductValue);
  
      const discountPercentage = ((formattedProductMarketValue - formattedProductValue) / formattedProductMarketValue) * 100;
  
      console.log("Discount Percentage:", discountPercentage);
  
      const actualDiscount = Math.round(discountPercentage);
  
      console.log("Rounded Discount Percentage:", actualDiscount);
  
      setProductsInputs({
          ...productsInputs,
          productDiscount: actualDiscount
      });
    }
  }, [productsInputs.productMarketValue, productsInputs.productValue]);
  
  

  function handleIsAlcoholicProduct(e, isAlcoholic) {
    e.preventDefault()
    setProductsInputs({...productsInputs, productIsAlcoholic: !isAlcoholic})
  }

  async function handleNewProductCreation(e) {
    e.preventDefault();

    if(productsInputs.productCategory === "" || productsInputs.productCategory === null) {
      alert("Selecione uma categoria")
      return;
    }
    
    if (isCreatingProduct === true) {
      return;
    }

    if (descriptionError) {
      alert("Por favor, corrija os erros antes de enviar o formulário.");
      return;
    }

    setIsCreatingProduct(true);
    const data = new FormData();

    data.append("product[name]", productsInputs.productName);
    data.append("product[price]", productsInputs.productValue);
    data.append("product[market_value]", productsInputs.productMarketValue);
    data.append("product[description]", productsInputs.productDescription);
    data.append("product[stock_amount]", productsInputs.productAmount);
    data.append("product[category_id]", productsInputs.productCategory);
    data.append("product[discount_percentage]", productsInputs.productDiscount);
    data.append("product[company_id]", user.company_id);
    data.append("product[start_date]", productsInputs.productStartDate);
    data.append("product[end_date]", productsInputs.productEndDate);
    data.append("product[is_alcoholic]", productsInputs.productIsAlcoholic);
    data.append("product[total_rate]", 0);

    for (let i = 0; i < imageInputs.length; i++) {
      const imageInput = imageInputs[i];

      if (imageInput.productFiles.length === 0) {
        continue; 
      }
      
      const files = Array.from(imageInput.productFiles);
      files.forEach((file) => {
        data.append("product[photos][]", file);
      });
    }
    
    try {
      const response = await api.post("/api/v1/products", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
          uid: user.uid,
          client: user.client,
          "access-token": user.accessToken,
          "Access-Control-Allow-Origin": "*",
        },
      });
    
      if (response) {
        alert("Produto criado com sucesso");
        onClose();
      }
    } catch (error) {
      onClose();
      window.location.reload();
      // Handle the error here, such as displaying an error message to the user.
    }
  }


  function handleMaxLimit(e) {
    e.preventDefault()
    const marketValueFormatted = parseInt(productsInputs.productMarketValue.replace(/\D/g, ''), 10);
    const priceValueFormatted = parseInt(e.target.value.replace(/\D/g, ''), 10);
  
    if(marketValueFormatted < priceValueFormatted) {
      setMaxPriceWarning(true)
    } else {
      setMaxPriceWarning(false)

      setProductsInputs({
        ...productsInputs,
        productValue: e.target.value,
      })
    }
  }

  const handleCalculateEndDate = (e) => {
    const startDate = e.target.value;
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 7);
    const endDateFormatted = endDate.toISOString().split("T")[0];
    setProductsInputs({
      ...productsInputs,
      productStartDate: startDate,
      productEndDate: endDateFormatted
    })
  }

  
  function handleDescriptionLength(e) {
    const description = e.target.value;
    if (description.length > 150) {
      setDescriptionError("A descrição deve ter no máximo 150 caracteres");
    } else {
      setDescriptionError("");
    }
    setProductsInputs({
      ...productsInputs,
      productDescription: description
    });
  }

  async function fetchCategories() {
    const response = await api.get("/api/v1/categories_avaialable");
    const categories = response.data;
    setCategories(categories);
  }

  useEffect(() => {
    fetchCategories();
  }, [user])
    

  return (
    <Container
      maxW={["container.sm", "container.xl"]}
    >
      <Box
        display="flex"
        flexDirection={["column", "row"]}
        justifyContent="space-evenly"
      >
        <Box
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="space-evenly"
          margin={"20px, 0px"}
          width="100%"
        >
          <Text
            fontSize="28px"
            color={theme.colors.primary}
            marginRight="60px"
          >
            <Link to="/partner/dashboard">
              Home
            </Link>
          </Text>
          <Text
            fontSize="28px"
            marginRight="60px"
          >
            <Link to="/partner/dashboard/products">
              Produtos cadastrados
            </Link>
          </Text>
          <Text
            fontSize="28px"
          >
            <Link to="/partner/dashboard/orders">
              Pedidos
            </Link>
          </Text>
          <Text
            fontSize="28px"
          >
            <Link to="/partner/dashboard/qr_code_validator">
              Validar QR Code
            </Link>
          </Text>
        </Box>
        <Box>
          <Button
            background={theme.colors.primary}
            color={theme.colors.white}
            _hover={{
              background: theme.colors.primary,
              color: theme.colors.white,
              opacity: 0.8,
            }}
            onClick={onOpen}
          >
            Novo Produto
          </Button>

          <Modal 
            isOpen={isOpen} 
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent
              maxW="container.xl"
              background={`linear-gradient(152.97deg, rgba(203, 191, 191, 0.8) 100%, rgba(21, 21, 21, 0) 100%)`}
              backgroundFilter="blur(21px)"
              borderRadius="16px"
              transform="rotate(90deg)"
            >
              <ModalHeader>
                <Box
                  background={theme.colors.primary}
                  width="250px"
                  borderRadius="20px"
                  margin="50px auto"
                >
                  <Text
                    fontSize="28px"
                    textAlign="center"
                  >
                    Novo produto
                  </Text>
                </Box>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={["column", "row"]}
                  gap={4}
                  padding="50px 10px"
                >
                  <Box
                    width={["100%", "50%"]}
                  >
                    <Input 
                      background={theme.colors.white}
                      placeholder="Nome do produto"
                      _focus={{
                        borderColor: theme.colors.primary,
                      }}
                      onChange={(e) => setProductsInputs({...productsInputs, productName: e.target.value})}
                    />
                    <Select
                      margin="20px 0"
                      background={theme.colors.white}
                      placeholder="Escolha uma categoria"
                      _focus={{
                        borderColor: theme.colors.primary,
                      }}
                      onChange={(e) => setProductsInputs({...productsInputs, productCategory: e.target.value})}
                    >
                      {categories.map((category) => {
                        return (
                          <option key={category.id} value={category.id}>{category.title}</option>
                        )
                      })}
                    </Select>
                    <Box>
                      <Text>
                        É bebida alcoólica?
                        <Switch
                          marginLeft="10px"
                          color={theme.colors.primary}
                          size='lg'
                          onChange={(e) => handleIsAlcoholicProduct(e, productsInputs.productIsAlcoholic)}
                        />
                      </Text>
                    </Box>
                    <Box
                      margin="20px 0 10px 0"
                      display="flex"
                      flexDirection={["column", "row"]}
                      justifyContent="space-between"
                      gap={4}
                    >
                      <Input
                        minWidth="160px"
                        id="productMarketValue"
                        background={theme.colors.white}
                        placeholder="Valor sem desconto"
                        _focus={{
                          borderColor: theme.colors.primary,
                        }}
                        value={formatCurrency(productsInputs.productMarketValue)}
                        onChange={(e) =>
                          setProductsInputs({
                            ...productsInputs,
                            productMarketValue: e.target.value,
                          })
                        }
                      />
                      <Box
                        minWidth="180px"
                      >
                        <Input
                          id="productValue"
                          background={theme.colors.white}
                          placeholder="Valor com desconto"
                          _focus={{
                            borderColor: theme.colors.primary,
                          }}
                          value={formatCurrency(productsInputs.productValue)}
                          onChange={(e) => handleMaxLimit(e)}
                        />
                        {maxPriceWarning && 
                          <Text
                            fontWeight="bold"
                          >
                            O valor do produto com desconto tem que ser inferior ao valor de mercado.
                          </Text>
                        }
                      </Box>
                      <Input
                        background={theme.colors.white}
                        placeholder="Desc min de 30%"
                        disabled={true}
                        _focus={{
                          borderColor: theme.colors.primary,
                        }}
                        value={productsInputs.productDiscount !== null ? productsInputs.productDiscount : "Desconto de %"}
                      />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                      >
                        {discountError && (
                          <Text
                            color={theme.colors.red500}
                            fontSize="14px"
                          >
                            {discountError}
                          </Text>
                        )}
                    </Box>
                    <Textarea
                      marginTop="10px"
                      background={theme.colors.white}
                      placeholder="Descrição..."
                      _focus={{
                        borderColor: theme.colors.primary,
                      }}
                      onBlur={handleDescriptionLength}
                      onChange={(e) =>
                        setProductsInputs({
                          ...productsInputs,
                          productDescription: e.target.value
                        })
                      }
                    />
                    <Box display="flex" justifyContent="end" alignItems="center">
                      {descriptionError && (
                        <Text color={theme.colors.red500} fontSize="14px">
                          {descriptionError}
                        </Text>
                      )}
                    </Box>
                    <Box
                      margin="20px 0"
                      display="flex"
                      flexDirection={["column", "row"]}
                      justifyContent="space-between"
                      gap={4}
                    >
                      <Input 
                        background={theme.colors.white}
                        placeholder="Início da oferta"
                        type="text" 
                        value={productsInputs.productStartDate}
                        _focus={{
                          borderColor: theme.colors.primary,
                        }}
                        onChange={(e) => handleCalculateEndDate(e)}
                        onFocus={(e) => e.target.type = "date"}
                        onBlur={(e) => {
                          e.target.type = "text";
                          const inputValue = e.target.value;
                          const formattedDate = formatDateForDisplay(inputValue);
                          setProductsInputs({
                            ...productsInputs,
                            productStartDate: formattedDate,
                          });
                        }}
                      />
                      <Input
                        background={theme.colors.white}
                        placeholder="Fim da oferta"
                        type="date"
                        disabled={productsInputs.productEndDate ? true : false}
                        _focus={{
                          borderColor: theme.colors.primary,
                        }}
                        value={productsInputs.productEndDate}
                        onChange={(e) => setProductsInputs({...productsInputs, productEndDate: e.target.value})}
                        onFocus={(e) => e.target.type = "text"}
                        onBlur={(e) => e.target.type = "date"}
                      />
                      <Input
                        background={theme.colors.white}
                        placeholder="Quantidade"
                        _focus={{
                          borderColor: theme.colors.primary,
                        }}
                        onChange={(e) => setProductsInputs({...productsInputs, productAmount: e.target.value})}
                      />
                    </Box>
                  </Box>
                  <Box
                    width={["100%", "50%"]}
                  >
                    <Text>Adicionar imagens</Text>
                    {imageInputs.map((input, inputIndex) => (
                      <div key={inputIndex}>
                        <Input
                          type="file"
                          multiple
                          onChange={(e) => handleImageUpload(inputIndex, e)}
                        />
                        <Grid 
                          templateColumns='repeat(2, 1fr)' 
                          gap={6} 
                          margin="20px 0"
                        >
                          {input.previews.map((preview, previewIndex) => (
                            <GridItem 
                              key={previewIndex} 
                              border={`3px solid ${theme.colors.primary}`}
                            >
                              <Image 
                                src={preview} 
                                key={previewIndex} 
                                width="100%" 
                                height="125px" 
                              />
                              <Button 
                                width={"100%"}
                                background={theme.colors.red500}
                                color={theme.colors.white}
                                onClick={() => handleDeleteImage(inputIndex, previewIndex)}
                                _hover={{
                                  background: `${theme.colors.red500}`,
                                  opacity: 0.8
                                }}
                              >
                                Excluir
                              </Button>
                            </GridItem>
                          ))}
                        </Grid>
                      </div>
                    ))}
                  </Box>
                </Box>
              </ModalBody>

              <ModalFooter>
                {/* ... other modal content */}
                {message && <div>{message}</div>}
                {/* ... other modal content */}
                {productsInputs.productDescription.length > 10 && productsInputs.productDiscount >= 30 && maxPriceWarning === false ? (
                  <Button
                    background={theme.colors.primary}
                    color={theme.colors.white}
                    width="100%"
                    marginRight="20px"
                      _hover={{
                      background: theme.colors.primary,
                      color: theme.colors.white,
                      opacity: 0.8,
                    }}
                    onClick={(e) => handleNewProductCreation(e)}
                  >
                    Cadastrar Produto
                  </Button>
                ) : (
                  <Text
                    width="100%"
                    marginRight="100px"
                    fontWeight={"bold"}
                  >
                    Você precisa adicionar um produto com desconto acima de 30% para habilitar o cadastro e adicionar descrição.
                  </Text>
                )}
                <Button colorScheme='red' mr={3} onClick={onClose}>
                  Cancelar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      </Box>

    </Container>
    
  )
}
