import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';

import { theme } from './styles/theme';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { AuthContextProvider } from './contexts/AuthContext';
import { CategoryContextProvider } from './contexts/CategoryContext';
import { CartContextProvider } from './contexts/CartContext';
import { PartnerStepProvider } from './contexts/PartnerStepContext';
import { MenuSelectionProvider } from './contexts/MenuSelectionContext';

export function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthContextProvider>
        <PartnerStepProvider>
          <MenuSelectionProvider>
            <CartContextProvider>
              <CategoryContextProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </CategoryContextProvider>
            </CartContextProvider>
          </MenuSelectionProvider>
        </PartnerStepProvider>
      </AuthContextProvider>
    </ChakraProvider>
  );
}