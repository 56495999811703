import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"

export function MakeCollectiongProduct({ productName, product, qrCodeDelivery, qrCodeValidationNumber, isDisabled}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button 
        width="100%"
        background="blue"
        color="white"
        onClick={onOpen}
        isDisabled={isDisabled}
      >
        Retirada do produto
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Retirada: {productName} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              display="flex"
              justify-content="center"
              flexDirection={"column"}
            >
              <Box
                minWidth="185px" 
                minHeight="185px"
                dangerouslySetInnerHTML={{ __html: qrCodeDelivery }}
              />
              <Text
                fontSize="16px"
                fontWeight="bold"
              >
                {qrCodeValidationNumber}
              </Text>
              <Text
                fontSize="18px"
                fontWeight="bold"
                margin="20px 0"
              >
                Apresente o QR Code no ato de retirada do seu produto
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}