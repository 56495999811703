import { Box, Image, Text, Button } from '@chakra-ui/react';
import { Navbar } from '../../components/Navbar';
import { theme } from '../../styles/theme';

import SmileLogo from '../../assets/images/smile_logo.svg';
import Rectangle from '../../assets/images/rectangle.png';
import { Link } from 'react-router-dom';

export function Success() {
  return (
    <>
      <Navbar />
        <Box
          width="100%"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
          backgroundImage={`url(${Rectangle})`}
          backgroundPosition={'center'}
          backgroundRepeat={'no-repeat'}
          backgroundSize={'contain'}
          marginTop={'75px'}
        >
          <Box
        />
      <Image
        src={SmileLogo}
        alt="Logo"
        marginTop={'15px'}
        width={'150px'}
        height={'187px'}
      />
       
      <Text
        fontSize={'30px'}
        fontWeight={'bold'}
        marginTop={'15px'}
        color={theme.colors.secondary}
        textAlign={'center'}
      >
        Conta criada com <br /> sucesso!
      </Text>

      <Text
        fontSize={'20px'}
        marginTop={'15px'}
        color={theme.colors.gray500}
        textAlign={'center'}
      >
        Agora você já pode começar a aproveitar <br /> os melhores Descontos
      </Text>
      <Link to="/configuration">
        <Button
          width={'315px'}
          height={'50px'}
          color={'white'}
          margin={'30px auto'}
          backgroundColor={theme.colors.primary}
          _hover={{
            backgroundColor: theme.colors.primary,
            opacity: 0.9,
            transition: '0.2s',
          }}
        >
          Continuar
        </Button>
      </Link>
    </Box>
    </>
  );
}
