import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Text, Image, Container, GridItem } from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import { theme } from "../../styles/theme";
import { api } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';

import PartnerIcon1 from "../../assets/images/dashboard_partner_01.png";
import PartnerIcon2 from "../../assets/images/dashboard_partner_02.png";
import PartnerIcon3 from "../../assets/images/dashboard_partner_03.png";
import PartnerIcon4 from "../../assets/images/dashboard_partner_04.png";
import PartnerIcon5 from "../../assets/images/dashboard_partner_05.png";
import PartnerIcon6 from "../../assets/images/dashboard_partner_06.png";
import { PartnerMenu } from "../../components/PartnerMenu";

import LineChart from "../../components/ChartSales/LineChart";
import BarChart from "../../components/ChartSales/BarChart";
import { formatCurrency } from "../../services/formatCurrency";
import { MobileNavbar } from "../../components/MobileNavbar";

export function PartnerDashboard() {
  const { user } = useContext(AuthContext);
  const history = useNavigate();

  const [userProfile, setUserProfile] = useState([]);
  const [product, setProduct] = useState([]);

  const [dashboardInformation, setDashboardInformation] = useState([])
  const [allowedAccess, setAllowedAccess] = useState(true);

  async function fetchUserAddress() {
    if (!user) return;
    const response = await api.get(`/api/v1/profiles/?user_id=${user.id}`)
    setUserProfile(response.data[0])
  }

  async function fetchAllProducts() {
    const response = await api.get(`api/v1/products`);
    const data = response.data;
    setProduct(data);
  }

  async function fetchDashboardInformation() {
    if(!user) {
      return;
    }

    const response = await api.get(`/api/v1/companies/${user && user.company_id}/company_dashboard`);
    const data = response.data;
    setDashboardInformation(data);
  }

  useEffect(() => {
    fetchUserAddress();
    fetchAllProducts();
  }, []);

  useEffect(() => {
    fetchDashboardInformation();
  }, [user])

  useEffect(() => {
    if (user && user.access !== "partner") {
      setAllowedAccess(false);
      history("/");
    } else {
      setAllowedAccess(true);
    }
  }, [user, history]);

  if (!allowedAccess) {
    return null;
  }

  return (
    <>
      {user && user.access === "partner" ? (
        <>
          <Navbar />
          <MobileNavbar />
          <Container  
            maxW={["container.sm", "container.xl"]}
            padding="30px 0"
          >
            <PartnerMenu /> 
            {product.length === 0 ? ( 
            <Box>
              <Text
                fontSize="42px"
                fontWeight="bold"
              >
                Tem tudo
              </Text>
              <Text>Você ainda não tem produtos cadastrados</Text>
              <Box
                margin="20px 0"
              >
                <Grid templateColumns='repeat(6, 1fr)' gap={6}>
                  <GridItem>
                    <Image src={PartnerIcon1} />
                  </GridItem>
                  <GridItem
                    marginTop="20px"
                  >
                    <Image src={PartnerIcon2} />
                  </GridItem>
                  <GridItem>
                    <Image src={PartnerIcon3} />
                  </GridItem>
                  <GridItem
                    marginTop="20px"
                  >
                    <Image src={PartnerIcon4} />
                  </GridItem>
                  <GridItem>
                    <Image src={PartnerIcon5} />
                  </GridItem>
                  <GridItem
                    marginTop="20px"
                  >
                    <Image src={PartnerIcon6} />
                  </GridItem>
                </Grid>
              </Box>
            </Box>
            ) : ( 
            <Box
              margin="15px 0"
            >
                <Grid 
                  templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']} 
                  gap={4}
                >
                  <GridItem>
                    <Box
                      backgroundColor={theme.colors.gray400}
                      borderRadius="10px"
                      padding="10px"
                      borderColor={theme.colors.gray500}
                      borderWidth="1px"
                      maxWidth={["100%", "100%"]}
                      margin={["0 15px", "0"]}
                    >
                      <Text
                        fontSize="24px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        alignContent={"center"}
                      >
                        Produtos cadastrados
                      </Text>
                      <Text
                        fontSize="48px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        color={theme.colors.lightBlue300}
                      >
                        {(dashboardInformation && dashboardInformation.number_of_products) || 0}
                      </Text>
                      <Text
                        fontSize="18px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                      >
                      Ver histórico
                      </Text>
                      </Box>
                  </GridItem>
                  <GridItem>
                    <Box
                      backgroundColor={theme.colors.gray400}
                      padding="10px"
                      borderRadius="10px"
                      borderColor={theme.colors.gray500}
                      borderWidth="1px"
                      height={"100%"}
                      maxWidth={["100%", "100%"]}
                      margin={["0 15px", "0"]}
                    >
                      <Text
                        fontSize="24px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                      >
                      Vendas nos últimos 7 dias
                      </Text>
                      <Text
                        fontSize="48px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        color={theme.colors.yellow500}
                      >
                        {(dashboardInformation && dashboardInformation.last_days_orders) || 0}
                      </Text>
                      <Text
                        fontSize="18px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                      >
                      Ver histórico
                      </Text>
                      </Box>
                  </GridItem>
                  <GridItem>
                  <Box
                      backgroundColor={theme.colors.gray400}
                      padding="10px"
                      borderRadius="10px"
                      borderColor={theme.colors.gray500}
                      borderWidth="1px"
                      maxWidth={["100%", "100%"]}
                      margin={["0 15px", "0"]}
                    >
                      <Text
                        fontSize="24px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                      >
                      Promoções acabando
                      </Text>
                      <Text
                        fontSize="48px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        color={theme.colors.red500}
                      >
                        {(dashboardInformation && dashboardInformation.last_days_discounts) || 0}
                      </Text>
                      <Text
                        fontSize="18px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                      >
                      Ver histórico
                      </Text>
                      </Box>
                  </GridItem>
                  <GridItem>
                    <Box
                      backgroundColor={theme.colors.gray400}
                      padding="10px"
                      borderRadius="10px"
                      borderColor={theme.colors.gray500}
                      borderWidth="1px"
                      height={"100%"}
                      maxWidth={["100%", "100%"]}
                      margin={["0 15px", "0"]}
                    >
                      <Text
                        fontSize="24px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        paddingBottom="30px"
                      >
                      Faturamento nos últimos 7 dias
                      </Text>
                      <Text
                        fontSize="52px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        color={theme.colors.primary}
                      >
                        {(dashboardInformation && formatCurrency(dashboardInformation.last_days_income)) || 0}
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box
                      backgroundColor={theme.colors.gray400}
                      padding="20px"
                      borderRadius="10px"
                      borderColor={theme.colors.gray500}
                      borderWidth="1px"
                      maxWidth={["100%", "100%"]}
                      margin={["0 15px", "0"]}
                    >
                      <Text
                        fontSize="24px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        VENDAS
                      </Text>
                      <LineChart 
                        productsOnDatabase={dashboardInformation && dashboardInformation.number_of_products}
                        productsSoldLastDays={dashboardInformation && dashboardInformation.last_days_orders}
                        productsLastDaysDiscounts={dashboardInformation && dashboardInformation.last_days_discounts}
                        lastDaysOrdersByDay={dashboardInformation && dashboardInformation.last_days_orders_by_day}
                      />
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box
                      backgroundColor={theme.colors.gray400}
                      padding="20px"
                      borderRadius="10px"
                      borderColor={theme.colors.gray500}
                      borderWidth="1px"
                      height={"435px"}
                      maxWidth={["100%", "100%"]}
                      margin={["0 15px", "0"]}
                    >
                      <Text
                        fontSize="24px"
                        fontWeight="bold"
                        display={"flex"}
                        justifyContent={"center"}
                        paddingBottom={"60px"}
                      >
                        FATURAMENTO
                      </Text>
                      {/* {BarChart()} */}
                      </Box>
                  </GridItem>
                </Grid>
              </Box>
            )}
          </Container>
        </>
      ) : (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
        height={"100vh"}
      >
        <Text
          fontSize="24px"
          fontWeight="bold"
          display={"flex"}
          justifyContent={"center"}
        >
          Você não tem permissão para acessar essa página
        </Text>
      </Box>
    )}
    </>
  );
}
