import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure } from "@chakra-ui/react";
import { theme } from "../../../styles/theme";
import { api } from "../../../services/api";
import { userToken } from "../../../contexts/AuthContext";

export function UpdateOrder({order}) {
  const STATUS_OPTIONS = [
    { value: "3", label: "Em preparo" },
    { value: "4", label: "Disponível para retirada"},
    { value: "5", label: "Pedido retirado e finalizado" },
    { value: "6", label: "Pedido cancelado pela empresa"},
  ]

  const {
    isOpen: isUpdateModalOpen,
    onOpen: openUpdateModal,
    onClose: closeUpdateModal,
  } = useDisclosure();

  const {
    isOpen: isDetailsModalOpen,
    onOpen: openDetailsModal,
    onClose: closeDetailsModal,
  } = useDisclosure();

  const formatTime = dateTimeString => {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
    });
    return formattedTime;
  };

  async function handleUpdateOrderStatus(value, id) {
    const data = {
      status: value,
    }

    const response = await api.patch(`/api/v1/order_products/${id}/update_order_status` , data, {
      headers: {
        Authorization: `Bearer ${userToken()}`,
        "access-token": userToken(),
        client: window.localStorage.getItem("@app/client"),
        uid: window.localStorage.getItem("@app/uid"),
        "Access-Control-Allow-Origin": "*",
      },
    });

    window.location.reload();

  }


  return (
    <>
      <Button
        width="200px"
        margin="10px 0"
        padding="20px"
        background={theme.colors.lightPurple500}
        _hover={{
          background: theme.colors.lightPurple500,
        }}
        cursor="pointer"
        color={theme.colors.white}
        onClick={openUpdateModal}
      >
        Atualizar
      </Button>
      <Modal
        isOpen={isUpdateModalOpen}
        onClose={closeUpdateModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          maxW="container.xl"
          // background={`linear-gradient(152.97deg, rgba(239, 239, 239, 0.9) 100%, rgba(21, 21, 21, 0) 100%)`}
          background={theme.colors.white}
          backgroundFilter="blur(121px)"
          borderRadius="16px"
          transform="rotate(90deg)"
        >
          <ModalHeader>
            <Box
              display="flex"
              flexDirection={["column", "row"]}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                background={theme.colors.primary}
                width={["100%", "580px"]}
                borderRadius="20px"
                margin={["30px 0", "0 0 0 65px"]}
                padding="20px"
              >
                <Text fontSize="28px" textAlign="center">
                  Atualizar status do pedido {order.order.id}
                </Text>
              </Box>
              <Box
                marginRight={["0", "65px"]}
              >
                {order.payment.paid === true && (
                  <Select
                    placeholder="Atualize o status do pedido"
                    width="100%"
                    options={STATUS_OPTIONS}
                    onChange={(e) => handleUpdateOrderStatus(e.value, order.order_products[0].id)}
                  />
                )}
              </Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          {order.order_products[0].status === "canceled" ? (
            <>
              <Text
                color={theme.colors.red500}
                fontWeight="bold"
                fontSize="32px"
                textAlign="center"
              >
                O pedido foi cancelado pela própria empresa
              </Text>
            </>
          ) : (
            <>
              <ModalBody>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  alignItems="center"
                  marginLeft={["0", "65px"]}
                >
                  <Text>
                    <Button
                      width="240px"
                      height="39px"
                      margin="10px 0"
                      padding="20px"
                      background={theme.colors.primary}
                      _hover={{
                        background: theme.colors.primary,
                      }}
                      cursor="pointer"
                      color={theme.colors.white}
                      onClick={openUpdateModal}
                    >
                      Pedido realizado
                    </Button>
                  </Text>
                  <Text
                    color={theme.colors.gray100} 
                    px="20px" 
                  >
                    Pedido recebido e confirmado.
                  </Text>
                  <Box
                    margin="0 140px 0 auto"
                  >
                    <Text
                      fontSize="18px"
                      lineHeight="21px"
                      fontWeight="900"
                    >
                      {formatTime(order.order.created_at)}h
                    </Text>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  alignItems="center"
                  marginLeft={["0", "65px"]}
                >
                  <Text>
                    <Button
                      width="240px"
                      height="39px"
                      margin="10px 0"
                      padding="20px"
                      background={order.payment.paid === false ? theme.colors.gray500 : theme.colors.primary }
                      _hover={{
                        background: order.payment.paid === false ? theme.colors.gray500 : theme.colors.primary,
                      }}
                      cursor="pointer"
                      color={theme.colors.white}
                      onClick={openUpdateModal}
                    >
                      {order.payment.paid === false ? "Pagamento pendente" : "Pagamento confirmado"}
                    </Button>
                  </Text>
                  <Text
                    color={theme.colors.gray100} 
                    px="20px" 
                  >
                    {order.payment.paid === false ? "O usuário já fez o pedido, estamos aguardando a confirmação de pagamento." : "O usuário já efetuou o pagamento."}
                  </Text>
                  <Box
                    margin="0 140px 0 auto"
                  >
                    <Text
                      fontSize="18px"
                      lineHeight="21px"
                      fontWeight="900"
                    >
                      {formatTime(order.payment.updated_at) }h
                    </Text>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  alignItems="center"
                  marginLeft={["0", "65px"]}
                >
                  <Text>
                    <Button
                      width="240px"
                      height="39px"
                      margin="10px 0"
                      padding="20px"
                      background={order.order_products[0].status === "preparation" || order.order_products[0].status === "remove" || order.order_products[0].status === "finished" || order.payment.paid === true  ? theme.colors.primary : theme.colors.gray500 }
                      _hover={{
                        background: theme.colors.primary,
                      }}
                      cursor="pointer"
                      color={theme.colors.white}
                      onClick={openUpdateModal}
                    >
                      {order.order_products[0].status === "preparation" || order.order_products[0].status === "remove" || order.order_products[0].status === "finished" ? "Pronto para retirada." : "Pronto para retirada." }
                    </Button>
                  </Text>
                  <Text
                    color={theme.colors.gray100} 
                    px="20px" 
                  >
                    {order.order_products[0].status === "preparation" || order.order_products[0].status === "remove" || order.order_products[0].status === "finished" ? "Preparo finalizado. Produto pronto para entrega." : "Produto aguardando retirada." }
                  </Text>
                  <Box
                    margin="0 140px 0 auto"
                  >
                    <Text
                      fontSize="18px"
                      lineHeight="21px"
                      fontWeight="900"
                    >
                      {formatTime(order.payment.updated_at)}h
                    </Text>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  alignItems="center"
                  marginLeft={["0", "65px"]}
                >
                  <Text>
                    <Button
                      width="240px"
                      height="39px"
                      margin="10px 0"
                      padding="20px"
                      background={order.payment.paid === true && (order.order_products[0].status === "remove" || order.order_products[0].status === "finished") ? theme.colors.primary : theme.colors.gray500}
                      _hover={{
                        background: order.payment.paid === true ? theme.colors.primary : theme.colors.gray500,
                      }}
                      cursor="pointer"
                      color={theme.colors.white}
                      onClick={openUpdateModal}
                    >
                      {order.order_products[0].status === "remove" ? "Produto Retirado pelo cliente" : ""}
                    </Button>
                  </Text>
                  <Box
                    margin="0 140px 0 auto"                      
                  >
                    {/* <Text
                      fontSize="18px"
                      lineHeight="21px"
                      fontWeight="900"
                    >
                      {formatTime(order.order_products[0].updated_at)}h
                    </Text> */}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  alignItems="center"
                  marginLeft={["0", "65px"]}
                >
                  <Text>
                    <Button
                      width="240px"
                      height="39px"
                      margin="10px 0"
                      padding="20px"
                      background={order.order_products[0].status === "remove" ? theme.colors.primary : theme.colors.gray500 }
                      _hover={{
                        background: order.order_products[0].status === "remove" ? theme.colors.primary : theme.colors.gray500
                      }}
                      cursor="pointer"
                      color={theme.colors.white}
                      onClick={openUpdateModal}
                    >
                      Finalizado
                    </Button>
                  </Text>
                  <Text
                    color={theme.colors.dark} 
                    px="20px" 
                    fontWeight="500"
                    fontSize="18px"
                  >
                    {order.order_products[0].status === "finished" ? "O pedido foi entregue." : "" }
                  </Text>
                </Box>
              </ModalBody>
            </>
          )}
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}