import { useEffect, useState } from "react";
import { createContext } from "react";

export const PartnerStepContext = createContext({});

export function PartnerStepProvider(props) {
  const [step, setStep] = useState(1);

  function handleSetStepper(id) {
    setStep(id);
  }

  return (
    <PartnerStepContext.Provider
      value={{ step, handleSetStepper}}
    >
      {props.children}
    </PartnerStepContext.Provider>
  );
}
