import { useEffect, useState } from "react";
import { createContext } from "react";
import { useAuth } from "../hooks/useAuth";
import { api } from "../services/api";
import { userToken } from "./AuthContext";

export const CartContext = createContext({});

export function CartContextProvider(props) {
  const { user } = useAuth();
  const [profile, setProfile] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([]);

  function handleSetSelectedProduct(e, product) {
    console.log(user)
    if (!user) {
      window.location.href = "/sign_in";
    }
    e.preventDefault();
    if (profile == null) {
      window.location.href = "/configuration";
    } else {
      setSelectedProduct(product);
      handleCreateCart(product);
    }
  }

  // async function handleRemoveCart(cartProduct) {  
  //   const response = await api.delete(`/api/v1/cart_products/${cartProduct}`, {
  //     headers: {
  //       "access-token": userToken(),
  //       client: window.localStorage.getItem("@app/client"),
  //       uid: window.localStorage.getItem("@app/uid"),
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   });
  //   if (response.status === 204) {
  //     setSelectedProduct([]);
  //     window.location.href = "/cart";
  //   }
  // }

  async function handleCreateCart(product) {
    const cart = {
      user_id: user.id,
      product_id: product.id,
      amount: 1,
      total_price: product.price,
    }
    try {
      const response = await api.post("/api/v1/cart_products", cart);
    } catch (error) {
      window.location.href = `/configuration`
      alert("Erro ao adicionar produto ao carrinho. Verifique seus dados.")
    }
  }

  async function handleRemoveCart(cartProduct) {
    const response = await api.delete(`/api/v1/cart_products/${cartProduct[0].id}?user_id=${user.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken()}`,
        "access-token": userToken(),
        client: window.localStorage.getItem("@app/client"),
        uid: window.localStorage.getItem("@app/uid"),
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (response.status === 204) {
      setSelectedProduct([]);
      window.location.href = "/cart";
    }
  }

  async function handleCreateMyOrder(cartProduct) {
    const { id: orderId } = cartProduct[0];
    const response = await api.delete(`/api/v1/cart_products/${orderId}/finish_my_order`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken()}`,
        "access-token": userToken(),
        client: window.localStorage.getItem("@app/client"),
        uid: window.localStorage.getItem("@app/uid"),
        "Access-Control-Allow-Origin": "*",
      },
    })
    setSelectedProduct([]);
    window.location.href = "/orders";
  }

  return (
    <CartContext.Provider
      value={{ selectedProduct, setSelectedProduct, handleSetSelectedProduct, handleRemoveCart, handleCreateMyOrder }}
    >
      {props.children}
    </CartContext.Provider>
  );
}