import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    primary: "#47D91E",
    secondary: "#132214",
    background: "#F5F5F5",
    opacity: "#EDFBE9",
    
    dark: "#262B2E",

    gray400: "#F4F5F7",
    
    gray500: "#8A8D9F",

    orange500: "#FE724E",
    
    yellow500: "#FFC529",

    red500: "#FE2121",

    lightBlue300: "#58C0FC",
    lightBlue500: "#2DD0C3",
    lightPurple500: "#5634D2",
  },
  fonts: {
    signature: `'Caveat', cursive`,
    body: `font-family: 'Roboto', sans-serif;`,    
  }
});


