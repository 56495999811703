import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure } from "@chakra-ui/react"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { theme } from "../../../styles/theme";

export function MakePayment({order}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function refreshPage() {
    onClose();
    window.location.reload();
  }

  
  function handleCopyToClipboard() {
    const invoiceUrl = order?.payment?.pix_qrcode_text;

    if (invoiceUrl) {
      navigator.clipboard.writeText(invoiceUrl)
        .then(() => {
          alert("PIX Copiado")
        })
        .catch((err) => {
          console.error('Error copying to clipboard', err);
          // Handle error if clipboard copy fails
        });
    }
  }


  return (
    <>
      <Button 
        backgroundColor={theme.colors.lightPurple500}
        color={theme.colors.white}
        onClick={onOpen}
      >
        Efetuar pagamento
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Efetue o pagamento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontWeight="bold"
              marginBottom="10px"
            >
              Para garantir sua oferta efetue o pagamento dentro de 5 (cinco) minutos ou seu pedido poderá ser cancelado.
            </Text>
          <Tabs isFitted variant='enclosed'>
            <TabList mb='1em'>
              <Tab>Pagar com PIX</Tab>
              <Tab>Pagar com Cartão</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Text
                  textAlign="center"
                  fontSize="32px"
                >{order.order.name}</Text>
                <Text
                  textAlign="center"
                  fontSize="28px"
                >Valor: {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(order.payment.price_in_cents / 100)}</Text>
                <Image 
                  src={order.payment.pix_qrcode}
                />
                <Text>
                  {order?.payment?.pix_qrcode_text}
                </Text>
                <Button 
                  width="100%"
                  onClick={handleCopyToClipboard}
                >
                  PIX COPIA E COLA                   
                </Button>
              </TabPanel>
              <TabPanel>
                <Link to={order.payment.invoice_url}
                  target="_blank"
                >
                  <Button
                    width="100%"
                    backgroundColor={theme.colors.primary}
                    color={theme.colors.white}
                    _hover={{
                      backgroundColor: theme.colors.primary,
                      opacity: 0.8
                    }}
                  >Abrir ambiente seguro</Button>
                </Link>
              </TabPanel>
            </TabPanels>
          </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={refreshPage}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}