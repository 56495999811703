import { Box, Container, Grid, GridItem, Image, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { ProductCard } from "../../components/ProductCard";

import { MobileNavbar } from "../../components/MobileNavbar";
import { Navbar } from "../../components/Navbar";
import { api } from "../../services/api";
import { theme } from "../../styles/theme";
import { format } from "date-fns";

export function Company() {
  const { id } = useParams();

  const [company, setCompany] = useState()
  const [isLoading, setIsLoading] = useState(false);


  async function handleCompanyData() {
    const response = await api.get(`/api/v1/companies/${id}/company_products`)
    setCompany(response.data)
    console.log(response.data)
    setIsLoading(false);
  }
  useEffect(() => {
    setIsLoading(true)
    handleCompanyData()
  }, [])


  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW={["container.sm", "container.xl"]}
      >
        {
          isLoading && (
            <Spinner
            margin="30px 0"
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color={theme.colors.primary}
            size='xl'
            />
          )
        }

        <Box
          margin="20px 0"
        >
          <Box
            border={`1px solid ${theme.colors.primary}`}
            display="flex"
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            boxShadow="xl"
          >
            <Box>
              {company && (
                <Image 
                  src={company?.company?.logo} 
                  width={["100%", "300px"]}
                />
              )}
            </Box>
            <Box
              padding="30px"
              display="flex"
            >
              <Text
                fontSize="32px"
                fontWeight="bold"
                textAlign="center"
              >
                {company?.company?.fantasy_name}
              </Text>
            </Box>
          </Box>
          <Grid
              templateColumns={[`repeat(1, 366px)`, `repeat(2, 266px)`, `repeat(3, 33%)`]}
              gap={6}
              padding={["0", "0 39px"]}
            >
              {company?.company?.products?.map((product) => {
                const formattedEndDate = format(new Date(product.end_date), 'dd/MM/yyyy');
                return (
                  <GridItem
                    margin="20px 0"
                  >
                    <ProductCard 
                      key={product.id}
                      company={product.company}
                      productCompanyName={product.company?.name}
                      productCompanyFantasyName={product.company?.fantasy_name}
                      productId={product.id}
                      productName={product.name}
                      productPrice={product.price}
                      productMarketValue={product.market_value}
                      productPhoto={product.photo}
                      productEndDate={formattedEndDate}
                      productDescription={product.description}
                      productDiscountPercentage={product.discount_percentage}
                      isAlcoholic={product.is_alcoholic}
                    />
                  </GridItem>
                )
              })}
          </Grid>
        </Box>
      </Container>
    </>
  )
}