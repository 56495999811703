import { Box, Text } from "@chakra-ui/react";
import { MapPinLine } from "phosphor-react";
import { theme } from "../../styles/theme";

export default function AddressCard({
  address,

}) {
  return (
    <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    margin="50px 0"
  >
    <Box
      border={`1px solid ${theme.colors.dark}`}
      borderRadius={20}
      width={["100%", "389px"]}
      height="135px"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
    >
      <Box
        backgroundColor={theme.colors.opacity}
        width="64px"
        height="64px"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <MapPinLine 
          weight="light"
          size={32} 
          color={theme.colors.dark}
        />  
      </Box>
      <Box>
        <Text>Avenida X, nº X, </Text>
        <Text>Bairro, Cidade, Estado</Text>
      </Box>
    </Box>
  </Box>
  )
}
