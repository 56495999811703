import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  InputGroup,
  Stack,
  InputLeftElement,
  Spinner,
} from "@chakra-ui/react";

import { Navbar } from "../../components/Navbar";
import { PartnerMenu } from "../../components/PartnerMenu";
import { ShowPartnerProduct } from "./components/show";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import { format, isAfter, parse, startOfDay } from "date-fns";

import { theme } from "../../styles/theme";
import SearchIcon from "../../assets/images/pesquisa.png";

import { BsCardText, BsToggleOn } from "react-icons/bs";

export function PartnerProducts() {
  const { user } = useAuth();

  const [listOfProducts, setListOfProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const currentDate = startOfDay(new Date());
  const endDate = parse(currentDate, 'dd/MM/yyyy', new Date());


  useEffect(() => {
    setIsLoading(true);
    fetchCompanyProducts();
  }, [user]);

  async function fetchCompanyProducts() {
    if(!user) {
      return 
    }

    try {
      const response = await api.get(`/api/v1/partner_products?partner_id=${user && user.id}`, {
        headers: {
          Authorization: `Bearer ${user && user.token}`,
          uid: user && user.uid,
          client: user && user.client,
          "access-token": user && user.accessToken,
          "Access-Control-Allow-Origin": "*",
        },
      });
      setListOfProducts(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const productsFilter = listOfProducts.filter((product) =>
    product.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <Container 
        maxW={["container.sm", "container.xl"]} 
        padding="30px 0"
      >
        <PartnerMenu />
        {isLoading ? (
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color={theme.colors.primary}
              size="xl"
            />
          </Box>
        ) : (
          <>
            <Box
            display="flex"
            flexDirection={["column", "row"]}
            justifyContent="space-between"
            marginTop="30px"
            padding="20px"
            >
              <Text 
                fontSize="20px" 
                color={theme.colors.gray500}
              >
                Produtos cadastrados | {listOfProducts.length} produto(s)
              </Text>
              <Stack 
                spacing={4} 
                width={"100%"} 
                height={"70px"}
              >
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Image 
                      src={SearchIcon} 
                      color={theme.colors.gray400} 
                    />
                  </InputLeftElement>
                  <Input
                    placeholder="Pesquisa de produtos"
                    paddingLeft={"50px"}
                    borderRadius="10px"
                    fontSize="20px"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </InputGroup>
              </Stack>
            </Box>
            <Box>
              <Grid 
                templateColumns={["repeat(1, 1fr)", "repeat(4, 1fr)"]} 
                gap={6}
                padding="20px"
              >
                {productsFilter.map((product) => (
                  
                  <GridItem 
                    margin="16px 0" 
                    key={product.id}
                  >
                    <Box 
                      background={theme.colors.background} 
                      minHeight="357px" 
                      width={["100%", "286px"]} 
                      padding="10px" 
                      borderRadius="16px"
                    >
                      <Box 
                        display="flex"
                      >
                        <Text>
                          Desconto de:
                        </Text>
                        <Text 
                          color={theme.colors.red500} 
                          fontWeight="bold"
                        >
                          {product.discount_percentage}%
                        </Text>
                      </Box>
                      <Box 
                        display="flex"
                      >
                        <Text>
                          Tempo da Oferta:
                        </Text>
                        <Text 
                          color={theme.colors.red500} 
                          fontWeight="bold"
                        >
                          {product.end_date}
                        </Text>
                      </Box>
                      <Image
                        src={product.photos[0] || "https://images.unsplash.com/photo-1622483767028-3f66f32aef97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"}
                        borderRadius="16px"
                        width="100%"
                      />
                      <Text 
                        fontSize="18px" 
                        fontWeight="bold" 
                        textAlign="center" 
                        margin="10px 0"
                      >
                        {product.name}
                      </Text>
                      <Text>
                        Quantidade disponível: {product.stock_amount}
                      </Text>
                      <Text>
                        Descrição: {product.description.substring(0, 50)} (...)
                      </Text>
                      <Box 
                        display="flex" 
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => handleOpenModal(product)}
                          cursor="pointer"
                        >
                          <BsCardText />
                          <Text 
                            marginLeft="10px"
                          >
                            DETALHES
                          </Text>
                        </Box>
                        <Box 
                          display="flex" 
                          justifyContent="center" 
                          alignItems="center"
                        >
                          <BsToggleOn 
                            fontSize="32px" 
                            color={product.is_active ? theme.colors.primary : theme.colors.red500} 
                          />
                        </Box>
                      </Box>
                    </Box>
                  </GridItem>
                ))}
              </Grid>
            </Box>
          </>
        )}

        {selectedProduct && (
          <Modal 
            isOpen={!!selectedProduct} 
            onClose={handleCloseModal}
          >
            <ModalOverlay 
              background="rgba(0, 0, 0, 0.1)" 
            />
            <ModalContent 
              maxW="container.xl"
            >
              <ModalHeader>
                Product Details
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ShowPartnerProduct
                  key={selectedProduct.id}
                  productId={selectedProduct.id}
                  name={selectedProduct.name}
                  description={selectedProduct.description}
                  marketValue={selectedProduct.market_value}
                  price={selectedProduct.price}
                  discountPercentage={selectedProduct.discount_percentage}
                  startDate={selectedProduct.start_date}
                  endDate={selectedProduct.end_date}
                  stockAmount={selectedProduct.stock_amount}
                  photos={selectedProduct.photos}
                  categoryId={selectedProduct.category_id}
                  isActive={selectedProduct.is_active}
                  isDeleted={selectedProduct.is_deleted}
                  alreadyHasOrders={selectedProduct.already_has_orders}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </Container>
    </>
  );
}
