import { Box, Container, Grid, GridItem, Text, theme } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar } from '../../components/Navbar';
import { useAuth } from '../../hooks/useAuth';
import { Profile } from './component/Profile';
import { PartnerProfile } from './component/PartnerProfile';
import { MobileNavbar } from '../../components/MobileNavbar';

export function Configuration() {
  const { user, isAuthenticated } = useAuth();

  const history = useNavigate();
  
  const [pageActive, setPageActive] = useState("");

  function handleDisplayContent() {
    if(!user) {
      return;
    }
    
    if (user && user?.access !== "partner" && (pageActive === "" || pageActive === "Editar Perfil")) {
      return (
        <Profile />
      )
    } else if (user && user?.access === "partner") {
      return (
        <PartnerProfile /> 
      )
    } 
    else {
      return (
        <Text></Text>
      )
    }
  }

  useEffect(() => {
    isAuthenticated();
    console.log(user && user)
    // if (!user) {
    //   history("/sign_in")
    // }
  }, [])

  useEffect(() => {
    handleDisplayContent();
  }, [pageActive])
  
  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW="container.xl"
        marginTop={"30px"}
      >
        <Grid templateColumns={['repeat(1, fr)', 'repeat(3, 1fr)']} gap={2}>
          <GridItem
            width={"100%"}
            colSpan={[2, 1]}
          >
            <Text
              fontWeight={"bold"}
              fontSize={"40px"}
            >
              Configurações
            </Text>
            <Box
              marginTop={"10px"}
              borderRadius={"10px"}
              boxShadow={"xl"}
              padding={"20px"}
            >
              <Text
                fontSize={"30px"}
                fontWeight={"bold"}
                onClick={(e) => setPageActive("Editar Perfil")}
              >
                Editar perfil
              </Text>
              <Text
                fontSize={"30px"}
                fontWeight={"bold"}
              >
                <Link to={user?.access === "partner" ? "/partner/dashboard" : "/orders"}>
                  {user?.access === "partner" ? "Dashboard" : "Meus pedidos" }
                </Link>
              </Text>
              <Text
                color={"red500"}
              >
                Excluir conta
              </Text>
            </Box>

          </GridItem>
          <GridItem 
            width='100%'
            colSpan={2}
            paddingBottom="40px"
          >
          {handleDisplayContent()}
          </GridItem>
        </Grid>
      </Container>
    </>
  )
}