import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import IconLogo from "../../../../assets/images/icon.svg";
import { useAuth } from "../../../../hooks/useAuth";
import { api } from "../../../../services/api";
import { theme } from "../../../../styles/theme";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";

export function Profile() {
  const history = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [userProfile, setUserProfile] = useState("");

  const [isLoading, setIsLoading] = useState(false)

  const [userFullName, setUserFullName] = useState("");
  const [userCpf, setUserCpf] = useState("");

  const [userCep, setUserCep] = useState("");
  const [userStreet, setUserStreet] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userComplement, setUserComplement] = useState("");
  const [userNeighborhood, setUserNeighborhood] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");

  const [userIsCreatingProfile, setUserIsCreatingProfile] = useState(false);

  const [address, setAddress] = useState({})

  const [userIsEditingAddress, setUserIsEditingAddress] = useState(false)

  const [updateUserFullName, setUpdateUserFullName] = useState("");
  const [updateUserAddressStreet, setUpdateUserAddressStreet] = useState("");
  const [updateUserAddressNumber, setUpdateUserAddressNumber] = useState("");
  const [updateUserAddressComplement, setUpdateUserAddressComplement] = useState("");
  const [updateUserAddressNeighborhood, setUpdateUserAddressNeighborhood] = useState("");
  const [updateUserAddressCity, setUpdateUserAddressCity] = useState("");
  const [updateUserAddressState, setUpdateUserAddressState] = useState("");


  async function handleFecthProfile() {
    isAuthenticated();
    if (!user) {
      return;
    }
    
    const response = await api.get(`/api/v1/profiles?user_id=${user.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    });
    
    if (response) {
      setUserProfile(response.data);
    }
  }

  async function handleCEP() {
    const response = await api.get(`https://viacep.com.br/ws/${userCep}/json/`);
    setAddress(response.data);
  }

  async function handleSubmitProfile(e) {
    e.preventDefault();
    const response = await api.post(`/api/v1/profiles`, {
      user_id: user.id,
      full_name: userFullName,
      cpf: userCpf,
      address_postal_code: userCep,
      address_street: address.logradouro,
      address_number: userNumber,
      address_complement: userComplement,
      address_neighborhood: address.bairro,
      address_city: address.localidade,
      address_state: address.uf
    })

    if(response.status === 200) {
      setUserIsCreatingProfile(false);
      history('/configuration');  
    }
  }

  useEffect(() => {
    setIsLoading(true)
    handleFecthProfile();
    setIsLoading(false)
  }, [user])

  async function handleUpdateProfile(e) {
    const data = {
      full_name: updateUserFullName ? updateUserFullName : userProfile[0]?.full_name,
      address_street: updateUserAddressStreet ? updateUserAddressStreet : userProfile[0]?.address_street,
      address_number: updateUserAddressNumber ? updateUserAddressNumber : userProfile[0]?.address_number,
      address_complement: updateUserAddressComplement ? updateUserAddressComplement : userProfile[0]?.address_complement,
      address_neighborhood: updateUserAddressNeighborhood ? updateUserAddressNeighborhood : userProfile[0]?.address_neighborhood,
      address_city: updateUserAddressCity ? updateUserAddressCity : userProfile[0]?.address_city,
      address_state: updateUserAddressState ? updateUserAddressState : userProfile[0]?.address_state
    }

    const response = await api.put(`/api/v1/profiles/${userProfile[0]?.id}`, data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        user_id: user.id,
      },
    })
    
    if(response.status === 200) {
     history('/');
    }
  }
    
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      marginTop={"50px"}
    >
      <Image
        src={IconLogo}
      />
      {userProfile.length === 0 ? (
        <>
        <Text
          fontSize={"50px"}
          fontWeight={"bold"}
        >
          Cadastrar Perfil
        </Text>

         <Box>
           <Input 
             placeholder={"Insira seu nome completo"}
             margin={"10px 0"}
             value={userFullName}
             onChange={(e) => setUserFullName(e.target.value)}
           />
            
            <Input 
             placeholder={"Digite o seu CPF"}
             margin={"10px 0"}
             value={userCpf}
             onChange={(e) => setUserCpf(e.target.value)}
             as={InputMask} mask="999.999.999-99" maskChar={null}
           />
           <Box
             display={"flex"}
             justifyContent={"space-between"}
             gap={2}
             margin={"10px 0"}
           >
             <Input
               width={"80%"}
               placeholder={"Digite o CEP"}
               onChange={(e) => setUserCep(e.target.value)}
               as={InputMask} mask="99999-999" maskChar={null}
             />
             <Button
               width={"20%"}
               backgroundColor={theme.colors.primary}
               color={"white"}
               onClick={(e) => handleCEP(e)}
             >
               Buscar
             </Button>
           </Box>
           <Box>
             <Text>
               {address && (
                 <Box>
                   <Box
                     display={"flex"}
                     justifyContent={"space-between"}  
                     gap={2}
                   >
                   <Input
                     width={"80%"} 
                     value={address.logradouro}
                   />
                   <Input
                     width={"20%"}
                     placeContent={"Número"}
                     onChange={(e) => setUserNumber(e.target.value)}
                   />
                   </Box>
                   <Box
                     display={"flex"}
                     justifyContent={"space-between"}
                     gap={2}
                     margin={"10px 0"}
                   >
                     <Input
                       width={"50%"}
                       placeholder={"Complemento"}
                       onChange={(e) => setUserComplement(e.target.value)}
                     />
                     <Input
                       width={"30%"}
                       value={address.bairro}
                     />
                     <Input
                       width={"20%"}
                       value={address.uf}
                     />
                   </Box>
                   <Button
                     backgroundColor={theme.colors.primary}
                     color={"white"}
                     fontWeight={"bold"}
                     width={"100%"}
                     _hover={{
                       backgroundColor: theme.colors.primary,
                       opacity: 0.8
                     }}
                     onClick={(e) => handleSubmitProfile(e)}
                   >
                     Cadastrar
                   </Button>
                 </Box>
               )}
             </Text>
           </Box>
         </Box>
       </>
      ) : (
        <> 
        <Text
          fontSize={"50px"}
          fontWeight={"bold"}
        >
          Editar Perfil
        </Text> 
        {userIsEditingAddress === true ? (
          <>
            <Box width={"90%"}
              display={"flex"}
              justifyContent={"space-between"}
              margin={"10px 0"}
              gap={2}
            >
              <Box
                width={"70%"}
              >
                <Text>Nome:</Text>
                <Input 
                  value={updateUserFullName ? updateUserFullName : userProfile[0]?.full_name}
                  onChange={(e) => setUpdateUserFullName(e.target.value)}
                  disabled={userProfile[0]?.full_name}
                />
              </Box>
              <Box
                width={"30%"}
              >
                <Text>CPF</Text>
                <Input
                  value={userProfile[0]?.cpf}
                  disabled
                />
              </Box>
            </Box>
            <Box
              width={"90%"}
              display={"flex"}
              justifyContent={"space-between"}
              margin={"10px 0"}
              gap={2}
            >
              <Box
                width={"50%"}
              >
                <Text>Logradouro</Text>
                <Input
                  onChange={(e) => setUpdateUserAddressStreet(e.target.value)}
                />
              </Box>
              <Box
                width={"25%"}
              >
                <Text>Número</Text>
                <Input
                  onChange={(e) => setUpdateUserAddressNumber(e.target.value)}
                />

              </Box>
              <Box
                width={"25%"}
              >
                <Text>Complemento</Text>
                <Input
                  onChange={(e) => setUpdateUserAddressComplement(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              width={"90%"}
              display={"flex"}
              justifyContent={"space-between"}
              margin={"10px 0"}
              gap={2}
            >
              <Box>
                <Text>Bairro</Text>
                <Input
                  onChange={(e) => setUpdateUserAddressNeighborhood(e.target.value)}
                />
              </Box>
              <Box>
                <Text>Cidade</Text>
                <Input 
                  onChange={(e) => setUpdateUserAddressCity(e.target.value)}
                />
              </Box>
              <Box>
                <Text>Estado</Text>
                <Input
                  onChange={(e) => setUpdateUserAddressState(e.target.value)}
                />
              </Box>
            </Box> 
            <Button
              backgroundColor={theme.colors.primary}
              color={"white"}
              fontWeight={"bold"}
              width={"40%"}
              _hover={{
                backgroundColor: theme.colors.primary,
                opacity: 0.8
              }}
              onClick={(e) => handleUpdateProfile(e)}
            >
              Atualizar
            </Button>
          </>
        ) : (
          <>
          <Box width={"90%"}
          display={"flex"}
          justifyContent={"space-between"}
          margin={"10px 0"}
          gap={2}
        >
          <Box
            width={"70%"}
          >
            <Text>Nome:</Text>
            <Input 
              value={updateUserFullName ? updateUserFullName : userProfile[0]?.full_name}
              onChange={(e) => setUpdateUserFullName(e.target.value)}
              disabled={userProfile[0]?.full_name}
            />
          </Box>
          <Box
            width={"30%"}
          >
            <Text>CPF</Text>
            <Input
              value={userProfile[0]?.cpf}
              disabled
            />
          </Box>
        </Box>
        <Box
          width={"90%"}
          display={"flex"}
          justifyContent={"space-between"}
          margin={"10px 0"}
          gap={2}
        >
          <Box
            width={"50%"}
          >
            <Text>Logradouro</Text>
            <Input
              value={updateUserAddressStreet ? updateUserAddressStreet : userProfile[0]?.address_street}
              onChange={(e) => setUpdateUserAddressStreet(e.target.value)}
              disabled={userProfile[0]?.address_street ? true : false}
            />
          </Box>
          <Box
            width={"25%"}
          >
            <Text>Número</Text>
            <Input
              value={updateUserAddressNumber ? updateUserAddressNumber : userProfile[0].address_number}
              onChange={(e) => setUpdateUserAddressNumber(e.target.value)}
              disabled={userProfile[0]?.address_number ? true : false}
            />

          </Box>
          <Box
            width={"25%"}
          >
            <Text>Complemento</Text>
            <Input
              value={updateUserAddressComplement ? updateUserAddressComplement : userProfile[0]?.address_complement}
              onChange={(e) => setUpdateUserAddressComplement(e.target.value)}
              disabled={userProfile[0]?.address_complement ? true : false}
            />
          </Box>
        </Box>
        <Box
          width={"90%"}
          display={"flex"}
          justifyContent={"space-between"}
          margin={"10px 0"}
          gap={2}
        >
          <Box>
            <Text>Bairro</Text>
            <Input
              value={updateUserAddressNeighborhood ? updateUserAddressNeighborhood : userProfile[0]?.address_neighborhood}
              onChange={(e) => setUpdateUserAddressNeighborhood(e.target.value)}
              disabled={userProfile[0]?.address_neighborhood ? true : false}
            />
          </Box>
          <Box>
            <Text>Cidade</Text>
            <Input 
              value={updateUserAddressCity ? updateUserAddressCity : userProfile[0]?.address_city}
              onChange={(e) => setUpdateUserAddressCity(e.target.value)}
              disabled={userProfile[0]?.address_city ? true : false}
            />
          </Box>
          <Box>
            <Text>Estado</Text>
            <Input
              value={updateUserAddressState ? updateUserAddressState : userProfile[0]?.address_state}
              onChange={(e) => setUpdateUserAddressState(e.target.value)}
              disabled={userProfile[0]?.address_state ? true : false}
            />
          </Box>
        </Box> 
        {userIsEditingAddress === true ? (
          <></>
        ) : (
          <Button
            backgroundColor={theme.colors.primary}
            color={"white"}
            fontWeight={"bold"}
            width={"40%"}
            _hover={{
              backgroundColor: theme.colors.primary,
              opacity: 0.8
            }}
            onClick={(e) => setUserIsEditingAddress(true)}
          >
            Editar
          </Button>
        )}
        </>
        )}
      </>
      )}
    </Box>
  )
}
