import { Box, Button, Container, Image, Input, Select, Text, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { Trash } from "phosphor-react";
import { useContext, useEffect, useState } from "react";
import AddressCard from "../../components/Address";
import { Navbar } from "../../components/Navbar";
import { CartContext } from "../../contexts/CartContext";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";
import { MobileNavbar } from "../../components/MobileNavbar";

export function Cart() {
  const { user } = useAuth();
  const { selectedProduct, setSelectedProduct, handleRemoveCart, handleCreateMyOrder } = useContext(CartContext)

  const [chosenProduct, setChosenProduct] = useState([]);
  const [productId, setProductId] = useState();

  const [ cartProduct, setCartProduct ] = useState(null);

  const [orderIsConfirmed, setOrderIsConfirmed] = useState(false);

  async function fetchCart() {
    if (user) {
      const response = await api.get(`/api/v1/cart_products?user_id=${user.id}`);
      const cart = response.data;
      setProductId(cart[0].product_id);
      setCartProduct(cart);
    }
  }

  async function fetchProduct(id) {
    const response = await api.get(`/api/v1/products/${id}`);
    
    const product = response.data;
    setChosenProduct(product);
  }

  useEffect(() => {
    fetchCart();
  }, [user])

  useEffect(() => {
    if (cartProduct) {
      fetchProduct(cartProduct[0].product_id)
    }
  }, [cartProduct])

  useEffect(() => {
    fetchCart();
  }, [selectedProduct])

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW="container.xl"
      >
        <Box
          margin="42px 0"
        >
          <Text
            fontSize="40px"
            fontWeight="bold"
          >
            Seu carrinho
          </Text>
        </Box>
        <Box>
          {cartProduct === undefined || cartProduct === null ? (
            <>
              <Text>
                Seu carrinho está vazio
              </Text>
            </>
          ) : (
            <>
            <Box
              width="100%"
              height={["420px", "177px"]}
              marginTop={["50px", "0px"]}
              shadow="2xl"
              borderRadius="22px"
              padding="10px"
              display="flex"
              flexDirection={["column", "row"]}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Box>
                
                <Image 
                  width="233px"
                  height="140px"
                  src={chosenProduct.photos && chosenProduct.photos[0]} 
                  borderRadius="22px"
                />
              </Box>
              <Box
                display="flex"
                flexDirection={["column", "row"]}
              >
                <Box
                  margin="0 20px"
                >
                  <Text
                    fontSize="20px"
                    textAlign={["center", "left"]}
                  >
                    Produto
                  </Text>
                  <Text
                    textAlign="center"
                    fontSize="25px"
                  >
                    {chosenProduct.name} 
                  </Text>
                </Box>
                <Box
                  margin={["20px 0 0 0", "0 0 0 120px"]}
                  paddingRight={["0", "20px"]}
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={["row", "column"]}
                  alignItems={["center", "flex-start"]}
                >
                  <Text
                    fontSize={["16px", "20px"]}
                    marginRight={["20px", "0"]}
                  >
                    Valor do produto
                  </Text>
                  <Text
                    fontSize="25px"
                    fontWeight="bold"
                    color={theme.colors.red500}
                    textAlign="center"
                  >
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(chosenProduct.market_value)}
                  </Text>
                </Box>
               
                <Box
                  margin={["0", "0 20px"]}
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={["row", "column"]}
                  alignItems={["center", "flex-start"]}
                >
                  <Text
                    fontSize={["16px", "20px"]}
                    marginRight={["20px", "0"]}
                  >
                    Você economizou
                  </Text>
                  <Text
                    fontSize="25px"
                    fontWeight="bold"
                    color={theme.colors.lightBlue300}
                    textAlign="center"
                  >
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(chosenProduct.market_value - chosenProduct.price)}
                  </Text>
                </Box>
                <Box
                  margin={["0", "0 20px"]}
                  paddingRight={["0", "20px"]}
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={["row", "column"]}
                  alignItems={["center", "flex-start"]}
                >
                  <Text
                    fontSize={["16px", "20px"]}
                    marginRight={["20px", "0"]}
                  >
                    Valor total
                  </Text>
                  <Text
                    fontSize="25px"
                    fontWeight="bold"
                    color={theme.colors.primary}
                    textAlign="center"
                  >
                     {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(chosenProduct.price)}
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin={["15px 0", "0 20px"]}
                >
                  <Trash 
                    size={32} 
                    weight="fill" 
                    color={theme.colors.red500}
                    cursor="pointer"
                    onClick={() => handleRemoveCart(cartProduct)}
                  />
                </Box>
              </Box>
            </Box>
            </>
          )}
        </Box>
        <Box>
          <Link to="/orders">
            <Button
              background={theme.colors.primary}
              color={theme.colors.white}
              borderRadius="5px"
              width="100%"
              margin="20px 0"
              onClick={() => handleCreateMyOrder(cartProduct)}
              _hover={{
                background: theme.colors.primary,
                color: theme.colors.white,
                opacity: 0.8
              }}
              >
              Confirmar pedido e ir para pagamento
            </Button>
          </Link>
        </Box>
      </Container>
    </>
     
  )
}