import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { theme } from "../../styles/theme";

export function CountDown({ productEndDate }) {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    // Parse the custom date format "DD/MM/YYYY" to create a valid Date object
    const endDateParts = productEndDate.split('/');
    const year = parseInt(endDateParts[2], 10);
    const month = parseInt(endDateParts[1], 10) - 1; // Months are 0-indexed
    const day = parseInt(endDateParts[0], 10);

    // Set the time to 23:59:59
    const endTime = new Date(year, month, day, 23, 59, 59);

    const now = new Date();
    const timeDiff = endTime - now;

    if (timeDiff <= 0) {
      // Offer has expired
      return { days: 0, hours: 0, minutes: 0 };
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return { days, hours, minutes };
  }

  function handleDaysAndDay(number) {
    const intValue = parseInt(number, 10);

    if (intValue > 1) {
      return `${intValue} dias`;
    } else if (intValue === 1) {
      return `${intValue} dia`;
    } else if (intValue === 0) {
      return `${timeRemaining.hours}h ${timeRemaining.minutes}min`;
    } else {
      return '';
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [productEndDate]);

  return (
    <Box display="flex">
      <Text>A oferta expira em: {" "}</Text>
      <Text
        color={theme.colors.red500}
        marginLeft="10px"
      >
        {handleDaysAndDay(timeRemaining.days)}
      </Text>
    </Box>
  );
}