import { Box, Button, Container, Input, Text } from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import { MobileNavbar } from "../../components/MobileNavbar";
import { useState } from "react";
import { theme } from "../../styles/theme";
import { api } from "../../services/api";

export function Password() {
  const [userEmail, setUserEmail] = useState(null);

  async function handlePasswordRecoverySubmit(e) {
    try {
      const data = {
        email: userEmail
      };
  
      const response = await api.post("api/v1/users/password_recovery", data);
      alert("Recuperação de senha solicitada. Confira seu email.");
      window.location.reload();
    } catch (error) {
      // Handle the error here
      console.error("Error during password recovery:", error);
    }
  }

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
      maxW={["container.sm", "container.xl"]}
      marginTop="20px"
    >
      <Box>
        <Text
          fontSize="32px"
          fontWeight="bold"
        >
          RECUPERAR SENHA
        </Text>
        <Text>
          Digite o seu email abaixo. Caso uma conta com o email vinculado exista, você receberá os dados para redefinir sua senha.
        </Text>

        <Box
          margin="20px 0"
        >
          <Input placeholder="E-mail" onChange={(e) => setUserEmail(e.target.value)}/>
        </Box>

        <Button
          background={theme.colors.primary}
          color={theme.colors.white}
          _hover={{
            background: theme.colors.primary,
            color: theme.colors.white,
            opacity: 0.8
          }}
          onClick={(e) => handlePasswordRecoverySubmit(e)}
        >
          Recuperar Senha
        </Button>
      </Box>
    </Container>
    </>

  )
}