import { Box, Container, Grid, GridItem, SimpleGrid, Spinner, Text } from "@chakra-ui/react";
import { MobileNavbar } from "../../components/MobileNavbar";
import { Navbar } from "../../components/Navbar";
import { useEffect, useState } from "react";
import { api } from "../../services/api";

import { theme } from '../../styles/theme';
import { ProductCard } from "../../components/ProductCard";

export function Products() {
  const [products, setProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchProducts() {
    setIsLoading(true);
    const response = await api.get("api/v1/products")
    setProducts(response.data)
    setIsLoading(false);
  }

  useEffect(() => {
    fetchProducts()
  }, [])

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW={["container.sm", "container.xl"]}
        marginTop="20px"
        marginBottom="50px"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box>
            <Text
              margin="40px 0"
              fontSize="32px"
              fontWeight="bold"
            >
              Todos os destaques
            </Text>
          </Box>
          <Box>
            {isLoading && (
              <Spinner
              margin="30px 0"
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color={theme.colors.primary}
              size='xl'
            />
            )}  
          </Box>
        </Box>
        {products && products.length > 0 && isLoading === false && (
          <Grid
            // templateColumns="repeat(4, 266px)" 
            templateColumns={[`repeat(1, 366px)`, `repeat(2, 266px)`, `repeat(3, 33%)`]}
            gap={[0, 12]}
          >
            {products.map((product) => {
              return (
                <GridItem
                  margin="20px 0"
                >
                  <ProductCard 
                    key={product.id}
                    company={product.company}
                    productCompanyName={product.company?.name}
                    productId={product.id}
                    productName={product.name}
                    productPhoto={product.photos[0]}
                    productPrice={product.price}
                    productMarketValue={product.market_value}
                    productEndDate={product.end_date}
                    productDescription={product.description}
                    productDiscountPercentage={product.discount_percentage}
                  />
                </GridItem>
              )
            })}
          </Grid>
        )}
      </Container>
    </>
  )
}