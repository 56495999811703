import { Container, Box, Image } from "@chakra-ui/react";
import { FaRegUserCircle, FaShoppingCart } from "react-icons/fa";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo_landing.png"
import { useAuth } from "../../hooks/useAuth";

export function MobileNavbar() {
  const { user } = useAuth();
  const linkTo = user ? "/configuration" : "/sign_in";

  return (
    <Container
      maxW="container.sm"
    >
      <Box
        display={["flex", "none"]}
        justifyContent="space-between"
        alignItems="center"
        margin="20px"
      >
        <Box>
          <Link to="/">
            <Image 
              src={Logo} 
              marginRight="10px"
            />
          </Link>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"

        >
          <Box
            marginRight="15px"
          >{
            user && user.access !== "partner" && (
              <Link to="/cart">
                <FaShoppingCart 
                  color={theme.colors.primary}
                  fontSize="24px"
                />
              </Link>
            )
          }
          </Box>
          <Box>
            <Link to={linkTo}>
              <FaRegUserCircle
                color={theme.colors.primary}
                fontSize="24px"
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}