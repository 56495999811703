import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  Text,
  Box,
  Select,
  Button,
  SimpleGrid,
  Image,
  GridItem,
  Grid,
  Switch,
  ModalOverlay,
  ModalContent,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { theme } from "../../../styles/theme";
import { useAuth } from "../../../hooks/useAuth";
import { formatCurrency } from "../../../services/formatCurrency";

export function ShowPartnerProduct({
  productId,
  name,
  description,
  categoryId,
  stockAmount,
  discountPercentage,
  price,
  marketValue,
  startDate,
  photos,
  isActive,
  isDeleted,
  alreadyHasOrders
}) {
  const { user } = useAuth();

  const [listOfCategories, setListOfCategories] = useState([{}]);

  const [isUpdatingDate, setIsUpdatingDate] = useState(false);

  const [descriptionError, setDescriptionError] = useState("");

  const [isUpdatingPrice, setIsUpdatingPrice] = useState(false);

  const[productsInputs, setProductsInputs] = useState({
    productName: name,
    productCategory: categoryId,
    productMarketValue: marketValue,
    productValue: price,
    productAmount: stockAmount,
    productDescription: description,
    productStartDate: startDate,
    productEndDate: "",
    productDiscount: discountPercentage,
    productIsActive: isActive,
    productIsDeleted: isDeleted,
    productFiles: [],
    previews: photos
  })

  function clearInputValuesForProductPrices(e) {
    e.preventDefault();
    setProductsInputs({...productsInputs, productMarketValue: 0, productValue: 0})
    displayFieldToEditPrice()
    setIsUpdatingPrice(!isUpdatingPrice)
  }

  const handleDeleteImage = (index) => {
    const updatedFiles = [...productsInputs.productFiles];
    const updatedPreviews = [...productsInputs.previews];
  
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
  
    setProductsInputs((prevInputs) => ({
      ...prevInputs,
      productFiles: updatedFiles,
      previews: updatedPreviews,
    }));
  };

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const previews = [];
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
  
      reader.onload = () => {
        previews.push(reader.result);
  
        if (previews.length === files.length) {
          setProductsInputs((prevInputs) => ({
            ...prevInputs,
            productFiles: files,
            previews: previews,
          }));
        }
      };
  
      reader.readAsDataURL(file);
    }
  };

  function displayFieldToEditPrice() {
    return (
      <>
      <Box>
        <Text>
          Valor sem desconto
        </Text>
        <Input
          background={theme.colors.white}
          value={formatCurrency(productsInputs.productMarketValue)}
          onChange={(e) => setProductsInputs({...productsInputs, productMarketValue: e.target.value})}
        />
      </Box>
      <Box>
        <Text>
          Valor com desconto
        </Text>
        <Input 
          background={theme.colors.white}
          value={formatCurrency(productsInputs.productValue)}
          onChange={(e) => setProductsInputs({...productsInputs, productValue: e.target.value})}
        />
      </Box>
      <Box>
        <Text>Desconto</Text>
        <Input 
          disabled
          background={theme.colors.white}
          value={productsInputs.productDiscount !== null ? productsInputs.productDiscount : 0}
        />
      </Box>
      </>
    )
  }

  useEffect(() => {
    if(productsInputs.productValue && productsInputs.productMarketValue) {
      let formattedproductMarketValue = formatCurrency(productsInputs.productMarketValue).split('R$')[1]
      formattedproductMarketValue = parseFloat(formattedproductMarketValue)
      
      let formattedProductValue = formatCurrency(productsInputs.productValue).split('R$')[1]
      formattedProductValue = parseFloat(formattedProductValue)
      
      const discountPerCentage = Math.abs((formattedProductValue * 100 / formattedproductMarketValue));

      const formattedDiscount = 100 - parseInt(discountPerCentage.toString().substring(0,2))

      setProductsInputs({
        ...productsInputs,
        productDiscount: formattedDiscount
      })
    }
  }, [productsInputs.productMarketValue, productsInputs.productValue])

  useEffect(() => {
    async function fetchCategories() {
      const response = await api.get('/api/v1/categories_avaialable');
      setListOfCategories(response.data);
    }

    fetchCategories();
  }, []);

  function handleDeleteProduct() {
    const data = {
      is_deleted: true
    }

    const response = api.patch(`/api/v1/products/${productId}/delete_product`, data, {
      headers: {
        Authorization: `Bearer ${user && user.token}`,
        uid: user && user.uid,
        client: user && user.client,
        'access-token': user && user.accessToken,
        'Access-Control-Allow-Origin': '*',
      }
    })

    if (response.status === 200) {
      alert('Produto apagado com sucesso!')
      window.location.href = '/partner/dashboard/products'
    }
  }


  function handleDescriptionLength(e) {
    const description = e.target.value;
    if (description.length > 150) {
      setDescriptionError("A descrição deve ter no máximo 150 caracteres");
    } else {
      setDescriptionError("");
    }
    setProductsInputs({
      ...productsInputs,
      productDescription: description
    });
  }

  async function handleUpdateProduct() {
    
    const data = {
      product: {
      //   market_value: productsInputs.productMarketValue ? productsInputs.productMarketValue : marketValue,
      //   price: productsInputs.productValue ? productsInputs.productValue : price,
      }
    }

    console.log(data)

    if(name !== productsInputs.productName) {
      data.product.name = productsInputs.productName
    }

    if(description !== productsInputs.productDescription) {
      data.product.description = productsInputs.productDescription
    }

    if(categoryId !== productsInputs.productCategory) {
      data.product.category_id = productsInputs.productCategory
    }

    if(stockAmount !== productsInputs.productAmount) {
      data.product.stock_amount = productsInputs.productAmount
    }

    if(isUpdatingPrice === true && discountPercentage !== productsInputs.productDiscount) {
      data.product.discount_percentage = productsInputs.productDiscount
    }

    if(isUpdatingPrice === true && price !== productsInputs.productValue) {
      data.product.price = productsInputs.productValue
    }

    if(isUpdatingPrice === true && marketValue !== productsInputs.productMarketValue) {
      data.product.market_value = productsInputs.productMarketValue
    } 

    if(startDate !== productsInputs.productStartDate) {
      data.product.start_date = productsInputs.productStartDate
    }

    if(isActive !== productsInputs.productIsActive) {
      data.product.is_active = productsInputs.productIsActive
    }

    if(isDeleted !== productsInputs.productIsDeleted) {
      data.product.is_deleted = productsInputs.productIsDeleted
    }

    if(productsInputs.productFiles.length > 0) {
      data.product.photos = productsInputs.productFiles
    }

    const response = await api.patch(`/api/v1/products/${productId}`, data, {
      headers: {
        Authorization: `Bearer ${user && user.token}`,
        uid: user && user.uid,
        client: user && user.client,
        'access-token': user && user.accessToken,
        'Access-Control-Allow-Origin': '*',
      }
    })
    
    if (descriptionError) {
      alert("Por favor, corrija os erros antes de enviar o formulário.");
      return;
    }

    if (response.status === 200) {
      alert('Produto atualizado com sucesso!')
      window.location.href = '/partner/dashboard/products'
    }

  }

  return (
    <>
      <ModalOverlay />
      <ModalContent
        maxW={["container.sm", "container.xl"]}
        background={`linear-gradient(152.97deg, rgba(203, 191, 191, 0.8) 100%, rgba(21, 21, 21, 0) 100%)`}
        backgroundfilter="blur(21px)"
        borderRadius="16px"
        transform="rotate(90deg)"
      >
        <ModalHeader
          display="flex"
          flexDirection={["column", "row"]}
          alignItems="center"
        >
          <Text
            marginLeft="10px"
            background={isActive ? theme.colors.primary : theme.colors.red500}
            borderRadius="8px"
            color={theme.colors.white}
            padding="5px 15px"
          >
            {isActive ? "Anúncio ativo" : "Anúncio pausado"}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={[0, 2]} spacing={10}>
            <Box
              flexDirection={["column", "row"]}
            >
              <Input
                background={theme.colors.white}
                value={productsInputs.productName}
                onChange={(e) => setProductsInputs({...productsInputs, productName: e.target.value})}
              />
              <Text>
                Descrição:
              </Text>
              <Textarea 
                value={productsInputs.productDescription}
                background={theme.colors.white}
                onBlur={handleDescriptionLength}
                onChange={(e) => setProductsInputs({...productsInputs, productDescription: e.target.value})}
              />
              <Box 
                display="flex" 
                justifyContent="end" 
                alignItems="center"
              >
                {descriptionError && (
                  <Text color={theme.colors.red500} fontSize="14px">
                    {descriptionError}
                  </Text>
                )}
              </Box>
              <Box
                margin="20px 0"
                display="flex"
                flexDirection={["column", "row"]}
                gap={8}
                width="100%"
              >
                <Box
                  width="100%"
                >
                  <Text>Selecione a categoria</Text>
                  <Select
                    background={theme.colors.white}
                  >
                    {listOfCategories.map((category) => {
                      return (
                        <option 
                          key={category.id} 
                          value={category.id}
                        >
                          {category.title}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
                <Box
                  width="100%"
                >
                  <Text>Quantidade</Text>
                  <Input 
                    background={theme.colors.white}
                    value={productsInputs.productAmount}
                    onChange={(e) => setProductsInputs({...productsInputs, productAmount: e.target.value})}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={["column", "row"]}
                gap={8}
                margin="20px 0"
              >
                {isUpdatingPrice === false ? (
                  <>
                  <Box>
                    <Text>
                      Valor sem desconto
                    </Text>
                    <Input
                      background={theme.colors.white}
                      value={productsInputs.productMarketValue}
                      onChange={(e) => setProductsInputs({...productsInputs, productMarketValue: e.target.value})}
                      disabled
                    />
                  </Box>
                  <Box>
                    <Text>
                      Valor com desconto
                    </Text>
                    <Input 
                      background={theme.colors.white}
                      value={productsInputs.productValue}
                      onChange={(e) => setProductsInputs({...productsInputs, productValue: e.target.value})}
                      disabled
                    />
                  </Box>
                  <Box>
                    <Text>Desconto</Text>
                    <Input 
                      background={theme.colors.white}
                      disabled
                      value={productsInputs.productDiscount}
                      onChange={(e) => setProductsInputs({...productsInputs, productDiscount: e.target.value})}
                    />
                  </Box>
                  </>
                ): (
                  <>
                    {displayFieldToEditPrice()}
                  </>
                )}
              </Box>
              <Box>
                {isUpdatingPrice === false && (
                  <Button
                    background={theme.colors.primary}
                    color={theme.colors.white}
                    onClick={(e) => clearInputValuesForProductPrices(e)}
                    _hover={{
                      backgroundColor: theme.colors.primary,
                      opacity: 0.8
                    }}
                  >
                    Habilitar edição de valores
                  </Button>
                )}
              </Box>
              <Box>
                <Text>Início da oferta</Text>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  gap={8}
                >
                  {isUpdatingDate ? (
                    <>
                      <Input
                        background={theme.colors.white}
                        type="date"
                        onChange={(e) => setProductsInputs({...productsInputs, productStartDate: e.target.value})}
                      />
                      <Button
                        backgroundColor={theme.colors.primary}
                        color={theme.colors.white}
                        _hover={{
                          backgroundColor: theme.colors.primary,
                          opacity: 0.8
                        }}
                        onClick={(e) => setIsUpdatingDate(false)}
                      >
                        Salvar
                      </Button>
                    </>
                  ) : (
                    <>
                      <Input 
                        background={theme.colors.white}
                        value={productsInputs.productStartDate}
                        disabled
                      />
                      <Button
                        backgroundColor={theme.colors.primary}
                        color={theme.colors.white}
                        disabled={!descriptionError}
                        _hover={{
                          backgroundColor: theme.colors.primary,
                          opacity: 0.8
                        }}
                        onClick={(e) => setIsUpdatingDate(true)}
                      >
                        Alterar data
                      </Button>
                    </>
                    )}
                </Box>
                <Box
                  margin="20px 0"
                >
                  <Text>
                    Anúncio ativo:   
                    <Switch 
                      marginLeft="10px"
                      color={theme.colors.primary}
                      size='lg'
                      isChecked={productsInputs.productIsActive ? true : false} 
                      onChange={(e) => setProductsInputs({...productsInputs, productIsActive: e.target.checked})}
                    />
                  </Text>
                </Box>
              </Box>
              <Box
                margin="20px 0"
              >
                <Button
                  background={theme.colors.primary}
                  color={theme.colors.white}
                  width="100%"
                  onClick={(e) => handleUpdateProduct(e)}
                  _hover={{
                    backgroundColor: theme.colors.primary,
                    opacity: 0.8
                  }}
                >
                  Confirmar Edições
                </Button>
              </Box>
              <Box
                marginBottom="50px"
                display="flex"
                gap={8}
              >
                {!alreadyHasOrders && 
                  <Button
                    background={theme.colors.red500}
                    color={theme.colors.white}
                    width="100%"
                    _hover={{
                      backgroundColor: theme.colors.red500,
                      opacity: 0.8
                    }}
                    onClick={(e) => handleDeleteProduct(e)}
                  >
                    APAGAR ANÚNCIO
                  </Button>
                }
              </Box>
            </Box>
            <Box>
            <Text>Editar imagens</Text>
            {/* <Input
              type="file"
              multiple
              onChange={(e) => {
                const files = e.target.files;
                const previews = [];

                for (let i = 0; i < files.length; i++) {
                  const file = files[i];
                  const reader = new FileReader();

                  reader.onload = () => {
                    previews.push(reader.result);

                    if (previews.length === files.length) {
                      setProductsInputs({
                        ...productsInputs,
                        productFiles: files,
                        previews: previews,
                      });
                    }
                  };

                  reader.readAsDataURL(file);
                }
              }}
            /> */}
            <Input
              type="file"
              multiple
              onChange={handleImageUpload}
            />
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]} gap={6}>
              {productsInputs.previews.map((preview, index) => (
                <GridItem
                  key={index}
                  border={`3px solid ${theme.colors.primary}`}
                  boxShadow="md"
                  margin="20px 0"
                >
                  <Image src={preview} height="120px" width="100%" />
                  <Button
                    width="100%"
                    background={theme.colors.red500}
                    color={theme.colors.white}
                    onClick={() => handleDeleteImage(index)}
                    _hover={{
                      background: `${theme.colors.red500}`,
                      opacity: 0.8,
                    }}
                  >
                    Excluir
                  </Button>
                </GridItem>
              ))}
            </Grid>
            </Box>
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </>
  )
}
