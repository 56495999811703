import { Box, Container, Grid, GridItem, Text } from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import { Navbar } from '../../components/Navbar';
import { NextToMe } from '../../components/NextToMe';
import { api } from '../../services/api';

import { Link } from 'react-router-dom';
import Categories from '../../components/Categories';
import { CategoryContext } from '../../contexts/CategoryContext';

import Select from 'react-select'
import { useAuth } from '../../hooks/useAuth';
import { MenuSelectionContext } from '../../contexts/MenuSelectionContext';
import { MobileNavbar } from '../../components/MobileNavbar';
import { LastDays } from '../../components/LastDays';
import { ProductCard } from '../../components/ProductCard';

export function Home(){
  const { user } = useAuth();

  const { startIndex, setStartIndex } = useContext(MenuSelectionContext);

  const { category } = useContext(CategoryContext);

  const [productsHighlight, setProductsHighlight] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [productsFiltered, setProductsFiltered] = useState([]);
  const [produtsPerRow, setProductsPerRow] = useState(1);
  const [homeWidth, setHomeWidth] = useState(0)

  const [cocaColaProducts, setCocaColaProducts] = useState([])

  async function fetchHighlightProducts(){
    const response = await api.get("/api/v1/products");
    setProductsHighlight(response.data)

    const filteredProducts = response.data.filter(product => {
      const regex = /\bcoca[\s-]?cola\b/i
      return regex.test(product.name);
    });

    console.log(filteredProducts)

    setCocaColaProducts(filteredProducts)
  }

  async function fetchCompanies(){
    const response = await api.get("/api/v1/companies");
    setCompanies(response.data)
  }

  async function fetchProductFilter(e) {
    // const response = await api.get(`api/v1/filters/list_of_products?query=${e}`)
    const response = await api.get(`api/v1/products`)
    if(!response) {
      return;
    }

    const data = response.data.map((product) => {
      return {
        id: product.id,
        value: product.name,
        label: product.name,
      }
    })
    setProductsFiltered(data)
  }

  function handleUserProductSelection(e) {
      fetchProductFilter(e.target.value)
  }

  function handleProductSelected(e) {
    window.location.href = `/product/${e.id}`
  }

  // const handlePrev = () => {
  //   if (startIndex > 0) {
  //     setStartIndex(startIndex - produtsPerRow);
  //   }
  // };

  // const handleNext = () => {
  //   if (startIndex + 4 < productsHighlight.length) {
  //     setStartIndex(startIndex + produtsPerRow);
  //   }
  // };

  useEffect(() => {
    const width = window.innerWidth;
    if (width > 768) {
      setProductsPerRow(4)
      setHomeWidth(width)
    }
  }, [])

  useEffect(() => {
    fetchHighlightProducts()
    fetchCompanies()
  }, [])

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW={["container.sm", "container.xl"]}
        marginTop="20px"
      >
        <Box
          display="flex"
          gap={8}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            marginBottom={8}
            marginTop={8}
          >
            <Box
              width="100%"
              onChange={(e) => handleUserProductSelection(e)} 
            >
              <Select 
                min-width="100%"
                options={productsFiltered}
                placeholder="O que você procura?"
                defaultValue={productsFiltered[0]}
                noOptionsMessage={() => 'Nenhum produto encontrado'}
                onChange={(selectedOption) => handleProductSelected(selectedOption)}
              />
            </Box>
            <Categories />
          </Box>
        </Box>
        <Box
          width={["86%", "100%"]}
          display="flex"
          alignItems="center"
          marginBottom="40px"
        >
          <Text
            fontSize="30px"
            paddingRight="30px"
            fontWeight="bold"
            marginBottom="10px"
          >
            Destaques
          </Text>
          <Link to="/produtos">
            <Text>Ver mais</Text>
          </Link>
        </Box>
        <Box position="relative">
          {startIndex > 0 && homeWidth > 768 ? (
            // <IconButton
            //   icon={<FaChevronLeft />}
            //   onClick={() => setStartIndex(startIndex - produtsPerRow)}
            //   disabled={startIndex === 0}
            //   position="absolute"
            //   top="50%"
            //   left="0"
            //   transform="translateY(-50%)"
            // />
            <></>
          ) : (
            <></>
          )}
          {homeWidth > 768 && productsHighlight.length > 4 ? (
            <></>
            // <IconButton
            //   icon={<FaChevronRight />}
            //   position="absolute"
            //   top="50%"
            //   right="0"
            //   transform="translateY(-50%)"
            //   onClick={() => setStartIndex(startIndex + produtsPerRow)}
            // />
          ) : (
            <></>
          )}
          <Grid 
            // templateColumns="repeat(4, 266px)" 
            templateColumns={[`repeat(1, 366px)`, `repeat(2, 266px)`, `repeat(3, 33%)`]}
            gap={2}
            padding={["0", "0 39px"]}
          >
            {category === 1 ? (
              productsHighlight && productsHighlight?.slice(0, 3).map((product) => {
                return (
                  // <Link to={`/product/${product.id}`} key={product.id}>
                    <GridItem
                    >
                      <ProductCard 
                        key={product.id}
                        company={product.company}
                        productCompanyName={product.company?.name}
                        productCompanyFantasyName={product.company.fantasy_name}
                        productId={product.id}
                        productName={product.name}
                        productPhoto={product.photos[0]}
                        productPrice={product.price}
                        productMarketValue={product.market_value}
                        productEndDate={product.end_date}
                        productDescription={product.description}
                        productDiscountPercentage={product.discount_percentage}
                        isAlcoholic={product.is_alcoholic}
                      />
                    </GridItem>
                  // </Link>
                )
              })
            ) : (
              productsHighlight &&
                productsHighlight
                .filter((product) => product.category_id === category)
                .slice(startIndex, startIndex + produtsPerRow)
                .map((product) => {
                  return (
                    // <Link to={`/product/${product.id}`} key={product.id}>
                      <GridItem 
                        key={product.id}
                        width="100%"
                      >
                         <ProductCard 
                          key={product.id}
                          company={product.company}
                          productCompanyName={product.company?.name}
                          productId={product.id}
                          productName={product.name}
                          productPhoto={product.photos[0]}
                          productPrice={product.price}
                          productMarketValue={product.market_value}
                          productEndDate={product.end_date}
                          productDescription={product.description}
                          productDiscountPercentage={product.discount_percentage}
                          isAlcoholic={product.is_alcoholic}
                        />
                      </GridItem>
                    // </Link>
                  );
                }
              )
            )}
          </Grid>
        </Box>
        <Box
          width={["86%", "100%"]}
          margin="40px 0"
        >
          {cocaColaProducts.length > 0 && (
            <>
              <Text
                fontSize="30px"
                paddingRight="30px"
                fontWeight="bold"
                marginBottom="10px"
              >
                Produtos Coca-Cola
              </Text>
              <Box>
                <Grid 
                  // templateColumns="repeat(4, 266px)" 
                  templateColumns={[`repeat(1, 266px)`, `repeat(3, 33%)`]} 
                  gap={2}
                  padding={["0", "0 39px"]}
                >
                  {cocaColaProducts.map((product) => {
                    return (
                      <GridItem 
                        key={product.id}
                        width="100%"
                      >
                        <ProductCard 
                          key={product.id}
                          company={product.company}
                          productCompanyName={product.company?.name}
                          productId={product.id}
                          productName={product.name}
                          productPhoto={product.photos[0]}
                          productPrice={product.price}
                          productMarketValue={product.market_value}
                          productEndDate={product.end_date}
                          productDescription={product.description}
                          productDiscountPercentage={product.discount_percentage}
                          isAlcoholic={product.is_alcoholic}
                        />
                      </GridItem>
                    )
                  })}
                </Grid>
              </Box>
            </>
          )}
        </Box>
        <Box>
          <LastDays />
        </Box>
        <Box
          marginBottom="100px"
        >
          <NextToMe />
        </Box>
      </Container>
    </>
  )
}
