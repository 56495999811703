import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Navbar } from "../../components/Navbar";
import { Box, Button, Container, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { Star } from "phosphor-react";
import { Recommended } from "../../components/Recommended";
import { CartContext } from "../../contexts/CartContext";
import { AuthContext } from "../../contexts/AuthContext";
import { MobileNavbar } from "../../components/MobileNavbar";
import { isBefore, parse, set } from "date-fns";

export function Product() {
  const { user } = useContext(AuthContext)

  const { handleSetSelectedProduct } = useContext(CartContext)

  const [product, setProduct] = useState([]);
  const [productPhoto, setProductPhoto] = useState();
  const [recommended, setRecommended] = useState([])

  const [offerIsDone, setOfferIsDone] = useState(false)

  const { id } = useParams();

  async function fecthProduct() {
    const response = await api.get(`api/v1/products/${id}`);
    const data = response.data;
    setProductPhoto(data.photos); 
    setProduct(data);

    const endDate = set(parse(data.end_date, 'dd/MM/yyyy', new Date()), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });  

    if (isBefore(endDate, new Date())) {
      setOfferIsDone(true);
    } 
  }


  async function fetchRecommendedProducts() {
    const response = await api.get(`api/v1/recommended_products/${id}`);
    setRecommended(response.data)
  }

  function handleRedirectedButton() {
    return (
      <>
        {offerIsDone === false ? (
          user && user.profile == null ? (
          <Button
            width="100%"
            height="60px"
            borderRadius="20px"
            margin={["0px", "20px"]}
            background={theme.colors.primary}
            color={theme.colors.white}
            fontSize="20px"
            fontWeight="bold"
            _hover={{
              background: theme.colors.primary,
              opacity: 0.8
            }}
            onClick={(e) => handleSetSelectedProduct(e, product)}
          >
          <Link to="/cart">
            PEGAR CUPOM
          </Link>
        </Button>
        ) : (
          <Box
            display="flex"
            margin={["0px", "20px"]}
          >
            <Button
              width="100%"
              height="60px"
              borderRadius="20px"
              background={theme.colors.primary}
              color={theme.colors.white}
              fontSize="20px"
              fontWeight="bold"
              _hover={{
                background: theme.colors.primary,
                opacity: 0.8
              }}
              onClick={(e) => handleSetSelectedProduct(e, product)}
            >
              <Link to="/cart">
                PEGAR CUPOM
              </Link>
            </Button>
          </Box>
          )
        ) : (
          <Button
            width="100%"
            height="60px"
            borderRadius="20px"
            margin={["0px", "20px"]}
            background={theme.colors.red500}
            color={theme.colors.white}
            fontSize="20px"
            fontWeight="bold"
            _hover={{
              background: theme.colors.red500,
              opacity: 0.8,
            }}
          >
            OFERTA EXPIROU
          </Button>
        )}
      </>
    )
  }

  useEffect(() => {
    handleRedirectedButton()
  }, [user])

  useEffect(() => {
    fecthProduct();
  }, []);

  useEffect(() => {
    fetchRecommendedProducts()
  }, [])

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW={["container.sm", "container.xl"]}
      >
        <Box>
          <Text
            fontSize="28px"
            fontWeight="bold"
            margin={"30px 0px"}
          >
            Detalhes do cupom
          </Text>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
          >
            <Box
              boxShadow="2xl"
              borderRadius="20px"
            >
              <Carousel 
              autoPlay={true} 
              infiniteLoop={true} 
              interval={5000}
              showThumbs={false}
              showStatus={false}
              >
                {productPhoto?.map((photo, index) => (
                  <div key={index}>
                    <Image
                      src={photo}
                      // maxHeight= "auto"
                      height="230px"
                      maxWidth="auto"
                      borderRadius="20px"
                    />
                  </div>
                ))}
              </Carousel>
              {product && product.is_alcoholic && (
                <Text
                  margin="10px 0"
                  fontSize="14px"
                  fontWeight="light"
                  textAlign="center"
                >
                  Se beber não dirija. Produto destinado a adultos
                </Text>
              )}
              <Box
                display="flex"
                justifyContent="space-between"
                padding="10px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    margin={"0px 10px"}
                  >
                    <Star
                      size={24}
                      weight="fill"
                      color={theme.colors.yellow500}
                    />
                  </Box>
                  <Text
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    {product.total_rate > 0 ? product.total_rate : 0}
                  </Text>
                  <Text
                    fontSize="18px"
                    color={theme.colors.gray500}
                    margin={"0px 10px"}
                  >
                    {product.number_of_ratings > 0 ? `${product.number_of_ratings} avaliações` : "Produto sem avaliação" }
                  </Text>
                </Box>
                   
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection={["column", "column", "row"]}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width={["100%", "425px"]}
                  border={`1px solid ${theme.colors.gray500}`}
                  padding="20px"
                  margin={["20px 0", "0 20px"]}
                  borderRadius="20px"
                >
                  <Text
                    fontSize="25px"
                    fontWeight="bold"
                    marginBottom="10px"
                  >
                    {product.name}
                  </Text>
                  <Text>{product.description}</Text>
                  <Box
                    marginTop="auto"
                  >
                  <Text
                    margin="10px 0"
                    fontSize="14px"
                    fontWeight="light"
                    textAlign="center"
                  >
                    Imagens meramente ilustrativas
                  </Text>
                </Box>
                </Box>
                
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    width={["100%", "425px"]}
                    border={`1px solid ${theme.colors.gray500}`}
                    padding="20px"
                    margin={["0", "0 20px"]}
                    borderRadius="20px"
                  >
                    <Text
                      fontSize="25px"
                      fontWeight="bold"
                    >
                      Quantidade
                    </Text>
                    <Text
                      fontSize="25px"
                      fontWeight="bold"
                    >
                      1
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    width={["100%", "425px"]}
                    border={`1px solid ${theme.colors.gray500}`}
                    padding="20px"
                    margin={["20px 0", "20px"]}
                    borderRadius="20px"
                  >
                    <Text
                      fontSize="25px"
                      fontWeight="bold"
                    >
                      Preço
                    </Text>
                    <Box
                      display="flex"
                    >
                      <Text
                        color={theme.colors.gray500}
                        opacity="0.8"
                        textDecoration="line-through"
                        fontSize="24px"
                        fontWeight="bold"
                      >
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product.market_value)}
                      </Text>
                    </Box>
                    <Text
                      fontSize="50px"
                      fontWeight="bold"
                      color={theme.colors.red500}
                    >
                      {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product.price)}
                    </Text>
                  </Box>
                </Box>
              </Box>
              {handleRedirectedButton()}
            </Box>
          </Box>
          <Box
            margin={"50px 0px"}
          >
            <Text
              fontSize="24px"
              fontWeight="bold"
              lineHeight="36px"
              marginBottom="22px"
            >
              Recomendados
            </Text>
            <Box>
              <Grid templateColumns={['repeat(1, fr)', 'repeat(2, 1fr)']} gap={6}>
                {recommended && recommended.map((product) => {
                  return (
                    <GridItem w='100%'
                      key={product.id}
                    >
                      <Recommended
                        key={product.id}
                        id={product.id}
                        name={product.name}
                        description={product.description}
                        price={product.price}
                        photo={product.photos[0]}
                      />
                    </GridItem>
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
