import { Box, Image, Flex, IconButton, useDisclosure } from "@chakra-ui/react";


import { useEffect, useState } from "react";

const ImageCarousel = ({ images, autoPlayInterval = 5000 }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, autoPlayInterval);

    return () => {
      clearInterval(timer);
    };
  }, [images, autoPlayInterval]);

  // const handlePrevious = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  // };

  // const handleNext = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  // };

  return (
    <Box position="relative">
      <Image src={images[currentIndex]} alt={`Image ${currentIndex}`} boxSize="550px"  />
    </Box>
  );
};

export default ImageCarousel;
