import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { api } from "../../../../services/api";
import { theme } from "../../../../styles/theme";

export function PartnerProfile() {
  const { user } = useAuth();

  const [companyLogo, setCompanyLogo] = useState()
  const [company, setCompany] = useState();

  const [companyIsEditingInformations, setCompanyIsEditingInformations] = useState(false);

  const [updateCompanyName, setUpdateCompanyName] = useState("")
  const [updateCompanyFantasyName, setUpdateCompanyFantasyName] = useState("")
  const [updateCompanyCNPJ, setUpdateCompanyCNPJ] = useState("")

  async function fetchCompanyData() {
    if(!user) {
      return;
    }

    const response = await api.get(`/api/v1/companies/${user.company_id}`)
    setCompany(response.data)
  }

  async function updatePartnerProfile(e) {
    e.preventDefault()

    if(!user) {
      return;
    }

    const file = companyLogo
    const data = new FormData();
    data.append("company[logo]", file);

    const response = await api.patch(`/api/v1/companies/${company.id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user && user.token}`,
        uid: user && user.uid,
        client: user && user.client,
        "access-token": user && user.accessToken,
        "Access-Control-Allow-Origin": "*",
      },
    })

    window.location.reload()
  }

  async function UpdateCompanyPartnerInformations(e) {
    if (!user) {
      return;
    }

    const data = {
      name: updateCompanyName !== "" ? updateCompanyName : company.name,
      cnpj: updateCompanyCNPJ !== "" ? updateCompanyCNPJ : company.cnpj,
      fantasy_name: updateCompanyFantasyName !== "" ? updateCompanyFantasyName : company.fantasy_name
    }
    
    const response = await api.put(`/api/v1/companies/${company.id}`, data, {
      headers: {
        "access-token": user.token,
        client: user.client,
        uid: user.uid,
        accept: "application/json",
        user_id: user.id,
      },
    })


    if(response.status === 200) {
      window.location.href = "/configuration";
    }
  }

  useEffect(() => {
    fetchCompanyData();
  }, [])

  return (
    <Box
      padding="0 20px"
    >
      <Box>
        {company?.logo && (
          <Image 
            src={company?.logo} 
            width="150px"
            height="150px"
            borderRadius="50%"
          />
        )}
      </Box>
      <Text>Anexar logo</Text>
      <Input
        type="file"
        onChange={(e) => setCompanyLogo(e.target.files[0])}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          margin="10px 0"
          background={theme.colors.primary}
          color={theme.colors.white}
          onClick={(e) => updatePartnerProfile(e)}
        >
          Salvar logo
        </Button>
      </Box>
      <Box
        margin="20px 0"
        display="flex"
      >
        {companyIsEditingInformations === false ? (
          <>
            <Box
              display="flex"
              flexDirection="column"
              minWidth="100%"
            >
              <Box
                minWidth="100%"
                display="flex"
                flexDirection="column"
              >
                <Box
                  width="100%"
                >
                  <Text>Empresa</Text>
                  <Input
                    width="100%"
                    value={company?.name}
                    disabled
                  />
                </Box>
                <Box>
                  <Text>Nome fantasia</Text>
                  <Input
                    width="100%"
                    value={company?.fantasy_name}
                    disabled
                  />
                </Box>
                <Box>
                  <Text>CNPJ</Text>
                  <Input
                    width="100%"
                    value={company?.cnpj}
                    disabled
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  width={"100%"}
                  backgroundColor={theme.colors.primary}
                  color={"white"}
                  marginTop="50px"
                  onClick={(e) => setCompanyIsEditingInformations(true)}
                >
                  Atualizar dados
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Box
                width="100%"
                flexDirection="column"
              >
                <Text>Empresa</Text>
                <Input
                  width="100%"
                  onChange={(e) => setUpdateCompanyName(e.target.value)}
                />
              </Box>
              <Box
                width="100%"
                flexDirection="column"
              >
                <Text>Nome Fantasia</Text>
                <Input
                  width="100%"
                  onChange={(e) => setUpdateCompanyFantasyName(e.target.value)}
                />
              </Box>
              <Box>
                <Text>CNPJ</Text>
                <Input
                  width="100%"
                  onChange={(e) => setUpdateCompanyCNPJ(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              gap="8"
            >
              <Button
                width={"70%"}
                backgroundColor={theme.colors.primary}
                color={"white"}
                marginTop="50px"
                onClick={(e) => UpdateCompanyPartnerInformations(e)}
              >
                Atualizar dados
              </Button>
              <Button
                width={"30%"}
                backgroundColor={theme.colors.red500}
                color={"white"}
                marginTop="50px"
                onClick={(e) => setCompanyIsEditingInformations(false)}
              >
                Cancelar edição
              </Button>
            </Box>
          </Box>
        )}
        
      </Box>
    </Box>
  )
}