import { 
  Text, 
  Box, 
  Container, 
  Link, 
  Image, 
  Button,  
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, 
  InputGroup, 
  InputLeftElement, 
  Input, 
  Icon,
  keyframes
    } from "@chakra-ui/react";

import Logo from '../../assets/images/logo.svg';
import { theme } from '../../styles/theme';
import { MdEmail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';

import LogoLanding from '../../assets/images/logo_landing.png';
import LandingImage1 from '../../assets/images/landing_page_1.png';
import LandingImage2 from '../../assets/images/landing_page_2.png';
import LandingImage3 from '../../assets/images/landing_page_3.png';
import LandingSoup1 from '../../assets/images/landing_soup_1.png';
import LandingSoup2 from '../../assets/images/landing_soup_2.png';
import LandingSoup3 from '../../assets/images/landing_soup_3.png';
import LandingSoup4 from '../../assets/images/landing_soup_4.png';


export function LandingPage() {
  return (
    <>
<Container
  maxW="90%"
>
  <Box
    width={"100%"}
    display={"flex"}
    alignItems={"center"}
    justifyContent={"space-between"}
    marginTop={"20px"}
  >
    <Box
      display={"flex"}
      flexDirection={"column"}
      >
    <Link
      to="/"
    >
      <Image 
        src={LogoLanding} 
        alt="Logo" 
        marginTop={"20px"}
        width={"200px"}
        
      />
    </Link>
    </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingRight={"20px"}
      >
        <Button
          backgroundColor={theme.colors.white}
          borderColor={theme.colors.primary}
          borderWidth={"2px"}
          marginRight={"20px"}
          _hover={
            {
              textDecoration: "none",
              backgroundColor: theme.colors.primary,
            }}
        >
          <Link
            to="/sign_in"
            _hover={
              {
                textDecoration: "none",
              }
            }
          >
            <Text
              fontSize={"18px"}
              fontWeight={"bold"}
              color={theme.colors.primary}
              _hover={
                {
                  textDecoration: "none",
                  color: theme.colors.white,
                }
              }
            >
              Entrar
            </Text>
          </Link>
        </Button>

        <Button
          backgroundColor={theme.colors.primary}
          borderColor={theme.colors.primary}
          borderWidth={"2px"}
          _hover={
            {
              textDecoration: "none",
              backgroundColor: theme.colors.white,
              borderColor: theme.colors.primary,
              borderWidth: "2px",
            }}
        >
          <Link
            to="/sign_up"
            _hover={
              {
                textDecoration: "none",
              }
            }
          >
            <Text
              color={"#fff"}
              fontSize={"18px"}
              fontWeight={"bold"}
              _hover={
                {
                  textDecoration: "none",
                  color: theme.colors.primary,
                }
              }
            >
              Cadastrar
            </Text>
          </Link>
        </Button>
      </Box>
    </Box>

    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-around"}
      marginTop={"30px"}
      border={"3px solid"}
      borderColor={theme.colors.primary}
      borderRadius={"50px"}
      padding={"20px"}
      position={"relative"}
    >
      <Text as={"span"}
        backgroundRepeat={"no-repeat"}
        backgroundPosition={"right top"}
        backgroundSize={"cover"}
        content={""}
        border={"3px solid"}
      borderColor={theme.colors.primary}
      borderRadius={"50px"}
      padding={"20px"}
      position={"absolute"}
      top={"0"}
      right={"0"}
      height={"100%"}
      width={"100%"}
      transform={"rotate(-1deg)"}
      ></Text>
      <Text as={"span"}
        backgroundRepeat={"no-repeat"}
        backgroundPosition={"right top"}
        backgroundSize={"cover"}
        content={""}
        border={"3px solid"}
      borderColor={theme.colors.primary}
      borderRadius={"50px"}
      padding={"20px"}
      position={"absolute"}
      top={"0"}
      right={"0"}
      height={"100%"}
      width={"100%"}
      transform={"rotate(1deg)"}
      ></Text>



      <Box
      width={"50%"}
      marginRight={"50px"}
      paddingLeft={"20px"}
      display={"flex"}
      flexDirection={"column"}
      >
      <Text
        fontSize={"56px"}
        fontWeight={"bold"}
        color={theme.colors.gray600}
      >
        Call to action com a 
      </Text>
      <Box
        display={"flex"}
      >
      <Text
      fontSize={"56px"}
      fontWeight={"bold"}
      color={theme.colors.gray600}
      textDecorationStyle={"solid"}
      textDecorationColor={theme.colors.lightBlue300}
      textDecorationLine={"underline"}
      textDecorationThickness={"5px"}
      paddingBottom={"20px"}
      >
        ideia principal
      </Text>
      <Text
      fontSize={"56px"}
      fontWeight={"bold"}
      color={theme.colors.gray600}
      paddingLeft={"15px"}
      >
      do app</Text>
      </Box>
      <Text
        fontSize={"30px"}
        fontWeight={"bold"}
        color={theme.colors.gray500}
        >
          Lörem ipsum klimatmat att anadat.
            Kroviskap dilangen , i krisväska. 
        </Text>
        </Box>
        <Box>
          <Image
            src={LandingImage1} 
            paddingRight={"20px"}
            />
        </Box>
      </Box>
    </Container>
    <Box
      width={"100%"}
      marginTop={"50px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      overflow={"hidden"}
    >
        <Image 
          src={LandingImage2}  
          width={"85%"}
          position={"relative"}
          display={"block"}
        />
        <Text as={"span"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"right top"}
          backgroundSize={"cover"}
          content={""}
          filter={"blur(8px)"}
          backgroundImage={`url(${LandingImage2})`}
          height={"100%"}
          width={"40%"}
          position={"absolute"}
          top={"0"}
          right={"125px"}  
        >
        </Text>

        <Box
          alignItems={"center"}
          backgroundColor={"rgba(0,0,0,0.3)"}
          boxSizing={"border-box"}
          display={"flex"}
          justifyContent={"center"}
          color={theme.colors.white}
          fontSize={"30px"}
          height={"100%"}
          padding={"20px 120px"}
          position={"absolute"}
          top={"0"}
          right={"126px"}
          width={"40%"}
          zIndex={"1"}
          borderRadius={"0 40px 40px 0"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            width={"100%"}
            paddingBottom={"50px"}
          >
          <Text
            fontSize={"40px"}
            fontWeight={"bold"}
            color={theme.colors.white}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingBottom={"40px"}
          >
            Cadastre sua loja
          </Text>
          <InputGroup>
            <InputLeftElement 
              children={< MdEmail color="#47D91E" size={"sm"}/>} left={"5px"} top={"5px"}
            />
              <Input type="email" placeholder="Digite seu email" size={"lg"} variant={"filled"} paddingLeft={"50px"}
              // backgroundColor={ theme.colors.white}
              textColor={theme.colors.gray500}
              />
          </InputGroup>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
          <Box
            borderBottom ={"2px solid"}
            borderColor={theme.colors.white}
            margin={"40px 0"}
            width={"45%"}
          >
          </Box>
          <Text
            fontSize={"30px"}
            color={theme.colors.white}
            padding={"20px 20px"}
            >
            OU
            </Text>
            <Box
            borderBottom ={"2px solid"}
            borderColor={theme.colors.white}
            margin={"40px 0"}
            width={"45%"}
          >
          </Box>
          </Box>
          <InputGroup>
            <InputLeftElement 
              children={< AiFillPhone color="#47D91E" size={"sm"}/>} left={"5px"} top={"5px"}
            />
              <Input type="phone" placeholder="Digite seu telefone" size={"lg"} variant={"filled"} paddingLeft={"50px"}
              // backgroundColor={ theme.colors.white}
              textColor={theme.colors.gray500}
              />
          </InputGroup>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            marginTop={"50px"}
          >
          <Button
            backgroundColor={theme.colors.primary}
            borderColor={theme.colors.primary}
            borderWidth={"2px"}
            width={"80%"}
            
            _hover={
              {
                textDecoration: "none",
                backgroundColor: theme.colors.white,
                borderWidth: "2px",
                borderColor: theme.colors.white,
                
              }}
          >
            <Link
              to="/sign_up"
              _hover={
                {
                  textDecoration: "none",
                }
              }
            >
              <Text
                color={"#fff"}
                fontSize={"18px"}
                fontWeight={"bold"}
                _hover={
                  {
                    textDecoration: "none",
                    color: theme.colors.primary,
                  }
                }
              >
                Cadastrar
              </Text>
            </Link>
          </Button>
          </Box>
          </Box>
    </Box>
    </Box>

    <Container
      maxW="100%"
      padding={"0px"}
    >
    <Box
      width={"100%"}
      marginTop={"50px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      backgroundColor={theme.colors.lightBlue300}
      paddingTop={"60px"}
      paddingBottom={"60px"}
      >
      <Box
        width={"100%"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            fontSize={"40px"}
            fontWeight={"bold"}
            color={theme.colors.white}
            paddingBottom={"40px"}
            >
              EM X PASSOS VOCÊ SE TORNA NOSSO PARCEIRO
          </Text>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-around"}
          paddingBottom={"20px"}
          paddingLeft={"20px"}
          paddingRight={"20px"}
        >
          <Text
            fontSize={"30px"}
            fontWeight={"bold"}
            color={theme.colors.white}
            textShadow={"2px 2px 2px gray"}
          >
            PASSO 1
          </Text>
          <Text
          fontSize={"30px"}
          fontWeight={"bold"}
          color={theme.colors.white}
          textShadow={"2px 2px 2px gray"}
          >
            PASSO 2
          </Text>
          <Text
          fontSize={"30px"}
          fontWeight={"bold"}
          color={theme.colors.white}
          textShadow={"2px 2px 2px gray"}
          >
            PASSO 3
          </Text>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-around"}
          paddingLeft={"20px"}
          paddingRight={"20px"}
        >
          <Text
            fontSize={"24px"}
            fontWeight={"bold"}
            color={theme.colors.white}
          >
            Descrição do passo 1
          </Text>
          <Text
          fontSize={"24px"}
          fontWeight={"bold"}
          color={theme.colors.white}
          >
            Descrição do passo 2
          </Text>
          <Text
          fontSize={"24px"}
          fontWeight={"bold"}
          color={theme.colors.white}
          >
            Descrição do passo 3
          </Text>
        </Box>
      </Box>
    </Box>

    <Box
      width={"100%"}
      marginTop={"40px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      >
        <Box>
          <Image
            src={LandingImage3} />
        </Box>
        <Box
          width={"40%"}
          marginLeft={"50px"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
          >
          <Text
            fontSize={"36px"}
            fontWeight={"bold"}
            color={theme.colors.gray600}
            paddingBottom={"20px"}
          >
            Somos o 
            </Text>
            <Text
            fontSize={"36px"}
            fontWeight={"bold"}
            color={theme.colors.primary}
            paddingBottom={"20px"}
            paddingRight={"10px"}
            paddingLeft={"10px"}
            >
            parceiro certo 
            </Text>
            <Text
            fontSize={"36px"}
            fontWeight={"bold"}
            color={theme.colors.gray600}
            paddingBottom={"20px"}
            >
            pra você!
          </Text>
          </Box>

          <Box
          paddingLeft={"30px"}
          >
            <Box
              display={"flex"}
              paddingBottom={"20px"} 
            >
            <Image
              src={LandingSoup1} 
              paddingRight={"20px"}
              height={"50px"}
              />
            <Text
              fontSize={"20px"}
              paddingTop={"10px"}
              >
                Cadastro rápido e sem burocracia 
              </Text>
              </Box>
              <Box
              display={"flex"}
              paddingBottom={"20px"}
            >
            <Image
              src={LandingSoup2} 
              paddingRight={"20px"}
              height={"50px"}
              />
            <Text
              fontSize={"20px"}
              paddingTop={"10px"}
              >
                Gerenciamento dinâmico e fácil
              </Text>
              </Box>
              <Box
              display={"flex"}
              paddingBottom={"20px"}
            >
            <Image
              src={LandingSoup3} 
              paddingRight={"20px"}
              height={"50px"}
              />
            <Text
              fontSize={"20px"}
              paddingTop={"10px"}
              >
                Dados e gráficos com o desempenho semanal do seu negócio
              </Text>
              </Box>
              <Box
              display={"flex"}
            >
            <Image
              src={LandingSoup4} 
              paddingRight={"20px"}
              height={"50px"}
              />
            <Text
              fontSize={"20px"}
              paddingTop={"10px"}
              >
                Autonomia para você cadastrar produtos e promoções
              </Text>
              </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width={"100%"}
        marginTop={"50px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <Text
            fontSize={"40px"}
            fontWeight={"bold"}
            color={theme.colors.gray600}
          >
            Perguntas frequentes
          </Text>
        </Box>
        <Box
          width={"70%"}
          paddingTop={"50px"}
          paddingBottom={"50px"}
          >
        <Accordion>
          <AccordionItem
            backgroundColor={theme.colors.lightBlue500} 
            borderRadius={"5px"}
            padding={"10px"}
            marginBottom={"10px"}
            >
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'
                color={theme.colors.white}
                fontWeight={"bold"}
                fontSize={"20px"}
                >
                O Sconto cobra alguma taxa?
                </Box>
                <AccordionIcon 
                color={theme.colors.white}
                />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}
            color={theme.colors.white}
            fontSize={"18px"}
              >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem
            backgroundColor={theme.colors.lightBlue500} 
            borderRadius={"5px"}
            padding={"10px"}
            marginBottom={"10px"}
           >
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'
                color={theme.colors.white}
                fontWeight={"bold"}
                fontSize={"20px"}>
                Quais as vantagens do Sconto?
                </Box>
                <AccordionIcon 
                color={theme.colors.white}
                />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}
            color={theme.colors.white}
            fontSize={"18px"}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem
            backgroundColor={theme.colors.lightBlue500} 
            borderRadius={"5px"}
            padding={"10px"}
           >
            <h2>
              <AccordionButton>
                <Box as="span" flex='1' textAlign='left'
                color={theme.colors.white}
                fontWeight={"bold"}
                fontSize={"20px"}>
                Em quanto tempo depois do cadastro posso cadastrar meus produtos?
                </Box>
                <AccordionIcon 
                color={theme.colors.white}
                />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}
            color={theme.colors.white}
            fontSize={"18px"}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

    </Box>
  </Container>
      <Box
        width={"100%"}
        backgroundColor={theme.colors.primary}
        >
          <Image 
          src={Logo} 
          width={"300px"}
          padding={"20px"}
          marginLeft={"50px"}
          />
        </Box>
  </>
  )
}

