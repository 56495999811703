import { Box, Text } from "@chakra-ui/react";

export function ContractHeader(
  {title}
) {
  return (
    <Box
      margin="20px 0"
    >
      <Text
        fontSize="16px"
        textAlign="center"
        fontWeight="bold"
        textDecoration="underline"
      >
        {title}
      </Text>
    </Box>
  )
}