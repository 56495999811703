import { Box, Button, Container, Image, Modal, ModalFooter, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { theme } from "../../styles/theme";
import { RatingProduct } from "../../components/RatingProduct";
import { MakePayment } from "./component/makePayment";
import { MakeCollectiongProduct } from "./component/makeCollectiongProduct";
import { MobileNavbar } from "../../components/MobileNavbar";
import { addDays, isAfter, isBefore, parseISO, startOfToday } from "date-fns";

export function Orders() {
  const { user } = useAuth();
  const [userChoice, setUserChoice] = useState("Pendentes de pagamento")
  const [listOfOrders, setListOfOrders] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [orderedProducts, setOrderedProducts] = useState(null)

  useEffect(() => {
    if (!user) {
      return;
    }

    async function fetchOrders() {
      const response = await api.get(`/api/v1/orders?profile_id=${user.id}`)
      setListOfOrders(response.data)
    }
    fetchOrders()
  }, [user])

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW="container.xl"
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Text
            margin="20px 0"
            fontSize="24px"
            fontWeight="bold"
          >
            Meus pedidos
          </Text>
          <Box
            display="flex"
            justiy-content="between"
          >
            <Text
              fontSize="22px"
              fontWeight="bold"
              cursor="pointer"
              marginRight="20px"
              borderBottom={userChoice === "Pendentes de pagamento" ? `4px solid ${theme.colors.primary}` : `4px solid ${theme.colors.white}`}
              color={userChoice === "Pendentes de pagamento" ? theme.colors.primary : theme.colors.black}
              onClick={() => setUserChoice("Pendentes de pagamento")}
              _hover={{
                color: `${theme.colors.primary}`,
                borderBottom: `4px solid ${theme.colors.primary}`,
                transition: '1.5s ease'
              }}
            >
              Pendentes de pagamento
            </Text>
            <Text
              fontSize="22px"
              fontWeight="bold"
              cursor="pointer"
              marginRight="20px"
              borderBottom={userChoice === "Prontos para retirada" ? `4px solid ${theme.colors.primary}` : `4px solid ${theme.colors.white}`}
              color={userChoice === "Prontos para retirada" ? theme.colors.primary : theme.colors.black}
              onClick={() => setUserChoice("Prontos para retirada")}
              _hover={{
                color: `${theme.colors.primary}`,
                borderBottom: `4px solid ${theme.colors.primary}`,
                transition: '1.5s ease'
              }}
            >
              Prontos para retirada
            </Text>
            <Text
              fontSize="22px"
              fontWeight="bold"
              cursor="pointer"
              marginRight="10px"
              color={userChoice === "Expirados" ? theme.colors.red500 : theme.colors.black}
              borderBottom={userChoice === "Expirados" ? `4px solid ${theme.colors.red500}` : `4px solid ${theme.colors.white}`}
              onClick={() => setUserChoice("Expirados")}
              _hover={{
                color: `${theme.colors.red500}`,
                borderBottom: `4px solid ${theme.colors.red500}`,
                transition: '1.5s ease'
              }}
            >
              Expirados
            </Text>
          </Box>
          {listOfOrders && listOfOrders?.map((order, index) => {
            return (
              <Box
                key={index}
                margin="15px 0"
                display="flex"
                flexDirection={["column", "row"]}
                justifyContent="space-between"
                alignItems="center"
                border={`1px solid ${order.order.paid === false ? theme.colors.red500 : theme.colors.primary}`}
                padding="10px"
                borderRadius="8px"
                boxShadow="md"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection={["column", "row"]}
                >
                  <Image 
                    src={order.photo}
                    width="100px"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    marginLeft="10px"
                  >
                    <Text
                      fontSize="18px"
                      fontWeight="bold"
                    >
                      {order.order.name} (Pedido: #{order.order.order_id})
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight="light"
                    >
                      {order.order.description}
                    </Text>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection={["column", "row"]}
                  gap="10px"
                >
                  {order.order.paid === true && (
                    order.order_products[0]?.rating[0] !== undefined ? (
                      <>
                        <Text>
                          Nota {order.order_products[0]?.rating[0]?.value}
                        </Text>
                      </>
                    ) : (
                      <>
                      <Button
                        onClick={onOpen}
                        background={theme.colors.yellow500}
                        _hover={{
                          background: theme.colors.yellow500,
                          opacity: 0.8
                        }}
                        cursor={order}
                      >
                        Avaliar produto
                      </Button>
  
                      <Modal 
                        blockScrollOnMount={false} 
                        isOpen={isOpen} 
                        onClose={onClose}
                      >
                        <ModalOverlay />
                        <RatingProduct
                          title={order.order.name}
                          product={order.order}
                          user={user}
                        />
                      </Modal>
                      </>
                    )
                  )}
                  <Button
                    background={order.order.paid === false ? theme.colors.red500 : theme.colors.primary}
                    color={theme.colors.white}
                    _hover={{
                      background: order.order.paid === false ? theme.colors.red500 : theme.colors.primary,
                      color: theme.colors.white,
                      opacity: 0.8
                    }}
                    cursor="pointer"
                  >
                    {order.order.paid === false ? ("Pagamento pendente") : ("Pagamento efetuado")}
                  </Button>
                  {order.order.paid === false && (
                    <MakePayment 
                      order={order}
                    />
                  )}
                  <Box>
                    <MakeCollectiongProduct 
                      productName={order.order?.name}
                      product={order.order}
                      qrCodeDelivery={order.order?.qrcode_svg}
                      qrCodeValidationNumber={order.order?.qr_code_identifier_validator}
                      isDisabled={order.order?.invoice_status === false}
                    />
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Container>
    </>
  )
}