import { useEffect, useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { PartnerMenu } from '../../components/PartnerMenu';

import {
  Box,
  Button,
  Container,
  Image,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { api } from '../../services/api';

import { useAuth } from '../../hooks/useAuth';
import Select from 'react-select'

import { theme } from '../../styles/theme';
import { userToken } from '../../contexts/AuthContext';
import { UpdateOrder } from './components/updateOrder';
import { MobileNavbar } from '../../components/MobileNavbar';

const STATUS_OPTIONS = [
  { value: "3", label: "Em preparo" },
  { value: "4", label: "Disponível para retirada"},
  { value: "5", label: "Pedido retirado e finalizado" },
  { value: "6", label: "Pedido cancelado pela empresa"},
]

export function PartnerOrders() {
  const { user } = useAuth();

  const [companyOrders, setCompanyOrders] = useState([]);
  const [productPhoto, setProductPhoto] = useState([]);

  const [search, setSearch] = useState("");
  const [selectedOrder, setSelectedOrder ] = useState([]);

  function handleSearchChange(e) {
    setSearch(e.value);
    filteredOrders(e.value);
  }

   function filteredOrders(value) {
     companyOrders.filter((order) => {
       setSelectedOrder([]);
       if (value === 'all') {
         setSelectedOrder(companyOrders);
       } else if (value === 'pending') {
         companyOrders.forEach((order) => {
            if (order.payment && order.payment.paid === false) {
              setSelectedOrder((selectedOrder) => [...selectedOrder, order]);
            }
         });  
       } else if (value === 'pagos') {
          companyOrders.forEach((order) => {
            if (order.payment && order.payment.paid === true) {
              setSelectedOrder((selectedOrder) => [...selectedOrder, order]);
            }
          }
        );
       }
     });
   }
  
  async function handleUpdateOrderStatus(value, id) {
    const data = {
      status: value,
    }

    const response = await api.patch(`/api/v1/order_products/${id}/update_order_status` , data, {
      headers: {
        Authorization: `Bearer ${userToken()}`,
        "access-token": userToken(),
        client: window.localStorage.getItem("@app/client"),
        uid: window.localStorage.getItem("@app/uid"),
        "Access-Control-Allow-Origin": "*",
      },
    });

    window.location.reload();

  }

  useEffect(() => {
    filteredOrders('all');   
  }, [companyOrders]);
  
    
  const {
    isOpen: isUpdateModalOpen,
    onOpen: openUpdateModal,
    onClose: closeUpdateModal,
  } = useDisclosure();

  const {
    isOpen: isDetailsModalOpen,
    onOpen: openDetailsModal,
    onClose: closeDetailsModal,
  } = useDisclosure();

  const formatDate = dateTimeString => {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  };

  const formatTime = dateTimeString => {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
    });
    return formattedTime;
  };

  async function fetchAllOrders() {
    if (!user) return;

    const response = await api.get(
      `/api/v1/companies/${user.company_id}/company_orders`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
          uid: user.uid,
          client: user.client,
          'access-token': user.accessToken,
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    setCompanyOrders(response.data);
  }

  useEffect(() => {
    fetchAllOrders();
  }, [user]);

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container 
        maxW={["container.sm", "container.xl"]} 
        padding="30px 0"
      >
        <PartnerMenu />
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection={["column", "row"]}
          alignItems="center"
          margin={["20px 0", "0"]}
        >
          <Text
            fontSize="36px"
            fontWeight="500"
            lineHeight="42px"
            margin="52px 0 43px 0"
          >
            Pedidos
          </Text>
          <Text
            fontSize="20px"
            lineHeight="23px"
            margin="15px 20px 0px 43px"
            fontWeight="500"
          >
            Filtrar:
          </Text>
          <Select
            placeholder="Todos"
            classNamePrefix="custom-select"
            options={[
              { value: 'all', label: 'Todos' },
              { value: 'pending', label: 'Pendentes' },
              { value: 'pagos', label: 'Pagos' },
            ]}
            styles={{
              control: (base, state) => ({
                margin: '15px 0px 0px 0px',
                width: '200px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #000000',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                display: 'flex',
              }),
            }}
            onChange={e => handleSearchChange(e)}
          />
        </Box>
        <Box>
          <Box
            display={["none", "flex"]}
            justifyContent="space-around"
            // alignItems="center"
            marginBottom="18px"
          >
            <Box>
              <Text
                fontSize="20px"
              >
                No.
              </Text>
            </Box>
            <Box>
              <Text
                fontSize="20px"
                lineHeight="33px"
              >
                Pedido
              </Text>
            </Box>
            <Box>
              <Text
                fontSize="20px"
                lineHeight="33px"
              >
                Valor
              </Text>
            </Box>
            <Box>
              <Text
                fontSize="20px"
                lineHeight="33px"
              >
                Cliente
              </Text>
            </Box>
            <Box>
              <Text
                fontSize="20px"
                lineHeight="33px"
              >
                Status
              </Text>
            </Box>
            <Text></Text>
            <Box>
              <Text
                fontSize="20px"
                lineHeight="33px"
              >
                Detalhes
              </Text>
            </Box>
          </Box>
            {selectedOrder && selectedOrder.map((order) => {
              return (
                <Box
                  key={order.order.id}
                  bgColor={
                    order.payment.paid === false
                      ? theme.colors.BgRed100
                      : theme.colors.bgGreen100
                  }
                  backdropFilter="blur(21px)"
                  borderRadius="8px"
                  marginBottom="13px"
                  px="20px"
                >
                  <Box
                    display="flex"
                    flexDirection={["column", "row"]}
                    justifyContent="space-around"
                    alignItems="center"
                    border={[`1px solid ${theme.colors.primary}`, ""]}
                    borderRadius="8px"
                  >
                    <Text
                      fontSize="20px"
                      lineHeight="28px"
                    >
                      000{order.order.id}
                    </Text>
                    <Box 
                      display="flex" 
                      alignItems="center"
                      marginLeft="20px"
                    >
                      <Image
                        src={order.photos[0]}
                        alt="Imagem do produto"
                        width="80px"
                        height="54px"
                        borderRadius="8px"
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        marginLeft="10px"
                      >
                        <Text
                          fontSize="18px"
                          lineHeight="21px"
                        >
                          {order.product.name.slice(0, 12)}
                        </Text>
                        <Text
                          fontSize="18px"
                          lineHeight="21px"  
                        >
                          {order.order_products[0].amount} unidades
                        </Text>
                      </Box>
                    </Box>
                    <Text
                      fontSize="18px"
                      lineHeight="21px"
                      paddingRight="60px"
                    >
                      R$ {order.order_products[0].total_price}
                    </Text>
                    <Text
                      fontSize="18px"
                      lineHeight="21px" 
                      paddingRight="60px"
                    >
                      {order.profile.full_name.slice(0, 15)}
                    </Text>
                    <Text>
                      <Button
                        width="200px"
                        margin="10px 0"
                        padding="20px"
                        background={
                          order.payment.paid === false
                            ? theme.colors.red500
                            : theme.colors.primary
                        }
                        _hover={{
                          background:
                            order.payment.paid === false
                              ? theme.colors.red500
                              : theme.colors.primary,
                        }}
                        cursor="pointer"
                      >
                        <Text color={theme.colors.white}>
                          {order.payment.paid === false
                            ? 'Pagamento pendente'
                            : 'Pedido pago'}
                        </Text>
                      </Button>
                    </Text>
                    <UpdateOrder 
                      order={order}
                    />
                  </Box> 
                  </Box>
              )
            })} 
        </Box>
      </Container>
    </>
  );
}
