import { Box, Button, Image, Input, Text, Flex, Center, Stack, InputGroup, InputLeftElement, InputRightElement, IconButton, ChakraProvider, Container } from "@chakra-ui/react";
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons';
import { Navbar } from "../../components/Navbar";
import { theme } from "../../styles/theme";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";


import SinginImage1 from '../../assets/images/sign_in.png';
import SinginImage2 from '../../assets/images/sign_in_2.png';
import SigninImage3 from '../../assets/images/sign_in_3.png';
import SigninImage4 from '../../assets/images/sign_in_4.png';
import EmailIcon from '../../assets/images/icon_material_email.png';
import PasswordIcon from '../../assets/images/icon_password.png';
import SigninCarousel from '../../components/SigninCarousel';

import InputMask from "react-input-mask";
import { MobileNavbar } from "../../components/MobileNavbar";

export function SignIn() {
  const { user, signInUser } = useAuth();

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    const width = window.innerWidth;
    setPageWidth(width);
  }, []);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  }

  const history = useNavigate();

  function handleUserLogin(e) {
    e.preventDefault();
    if (userEmail && userPassword) {
      signInUser(userEmail, userPassword)
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleUserLogin(e);
    }
  }

  useEffect(() => {
    if (user) {
      history('/')
    }
  }, [user])

  const images = [
    SinginImage1,
    SinginImage2,
    SigninImage3,
    SigninImage4,
  ];

  
  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW={["container.sm", "container.lg"]}
        paddingTop="20px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            marginLeft={["0px", "80px"]}
          >
            <Text
              fontSize={"30px"}
              marginTop={"15px"}
              fontWeight={"bold"}
            >
              LOGIN
            </Text>
            <Text
              color={theme.colors.primary}
              fontSize={"35px"}
              fontWeight="500"
            >
              Bem-vindo de volta!
            </Text>
            <Stack spacing={4} minWidth="310px" height={"70px"} marging="0 auto">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Image src={EmailIcon} color={theme.colors.gray400} padding={"30px 0 0 10px"} />}
                />
              </InputGroup>
              <Input
                placeholder="Digite seu email"
                paddingLeft={"65px"}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Stack>
            <Stack spacing={4} minWidth="310px" height={"70px"} margni="0 auto">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<Image src={PasswordIcon} color={theme.colors.gray400} padding={"30px 0 30px 10px"} />}
                />
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Digite sua senha"
                  paddingLeft={"65px"}
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  onKeyPress={onKeyPress}
                  />
                <InputRightElement>
                  <IconButton
                    size="md"
                    color={theme.colors.primary}
                    variant="ghost"
                    onClick={handleTogglePassword}
                    aria-label={showPassword ? "Hide password" : "Show password"}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    />
                </InputRightElement>
              </InputGroup>
            </Stack>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              margin={"10px 105px 0 auto"}
              cursor={"pointer"}
            >
              <Link to="/password">
                <Text
                  color={theme.colors.red500}
                  fontSize={"15px"}
                  fontWeight={"semibold"}
                  float={"right"}
                >
                  Esqueceu sua senha?
                </Text>
              </Link>
            </Box>
            <Button
              width={"300px"}
              height={"50px"}
              color={"white"}
              backgroundColor={theme.colors.primary}
              fontWeight={"bold"}
              fontSize={"24px"}
              marginTop={"30px"}
              _hover={{
                backgroundColor: theme.colors.primary,
                opacity: "0.8"
              }}
              onClick={(e) => handleUserLogin(e)}
            >
              Entrar
            </Button>
            <Box
              marginTop={"10px"}
              alignItems={"center"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Text>Ainda não é usuário?</Text>
              <Link to="/sign_up">
                <Text
                  color={theme.colors.primary}
                  fontWeight={"bold"}
                  marginLeft={"15px"}
                  cursor={"pointer"}
                >Inscreva-se</Text>
              </Link>
            </Box>
          </Box>
          {pageWidth > 768 && (
            <Box
              width="50%"
              display="flex"
              alignItems={"center"}
              padding="10px"
            >
              {pageWidth > 768 && (
                <SigninCarousel 
                  images={images} 
                  maxWidth="20%"
                />
              )}
            </Box>
          )}
        </Box>
      </Container>
    </>
  )
}
