import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import { api } from '../../services/api';
import { useEffect, useState } from "react";
import { ProductCard } from "../ProductCard";
import { Link } from "react-router-dom";

export function LastDays() {
  const [lastDaysOffers, setLastDaysOffers] = useState([])

  async function getLastDaysOffers() {
    const response = await api.get('/api/v1/last_days_products')
    setLastDaysOffers(response.data)
  }

  useEffect(() => {
    getLastDaysOffers()
  }, [])

  return (
    <Box
      margin="50px 0"
    >
      <Box
        display="flex"
        // justifyContent="space-between"
        alignItems="center"
        marginBottom="40px"
      >
        <Box>
          <Text
            fontSize="30px"
            marginRight="30px"
            fontWeight="bold"
          >
            Últimos dias
          </Text>
        </Box>
        <Box>
          <Link to="/acabando">
            <Text>Ver mais</Text>
          </Link>
        </Box>
      </Box>
      <Box
        padding={["0px 0px", "0px 50px"]}
      >
        <Grid 
          templateColumns={[`repeat(1, 266px)`, `repeat(3, 33%)`]} 
          gap={2}
        >
          {lastDaysOffers && lastDaysOffers.map((product) => {
            return (
              <GridItem
                key={product.id}
              >
                <ProductCard 
                  key={product.id}
                  company={product.company}
                  productCompanyName={product.company?.name}
                  productId={product.id}
                  productName={product.name}
                  productPhoto={product.photos[0]}
                  productPrice={product.price}
                  productMarketValue={product.market_value}
                  productEndDate={product.end_date}
                  productDescription={product.description}
                  productDiscountPercentage={product.discount_percentage}
                />
              </GridItem>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}