import { Box, Spinner, Button, Container, Image, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import AddressCard from "../../components/Address";
import { theme } from "../../styles/theme";
import { useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { useAuth } from "../../hooks/useAuth";
import { api } from "../../services/api";
import { Link } from "react-router-dom";

export function Payment() {
  const { user } = useAuth()
  const [productInformation, setProductInformation] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  
  const[paymentInputs, setPaymentInputs] = useState({
    number: "",
    totalValue: 0,
    verificationCode: "",
    fullName: "",
    expirationMonth: "",
    expirationYear: "",
    numberOfInstallments: 1
  })

  async function fetchOrder() {
    if (!user) {
      return;
    }

    const response = await api.get(`api/v1/user_last_open_order?user_id=${user.id}`)
    setProductInformation(response.data)
  }

  useEffect(() => {
    setIsLoading(true);
    fetchOrder();
    setIsLoading(false)
  }, [user])

  useEffect(() => {

  }, [])


  function handleProductCard() {
    return (
      <Box
        marginTop="50px"
      >
        <Box
          width="100%"
          height={["420px", "177px"]}
          marginTop={["50px", "0px"]}
          shadow="2xl"
          borderRadius="22px"
          padding="10px"
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Box>
            <Image 
              width="233px"
              src={productInformation && productInformation?.order?.order_product?.photos[0]} 
              borderRadius="22px"
            />
          </Box>
          <Box
            display="flex"
            flexDirection={["column", "row"]}
          >
            <Box
              margin="0 20px"
            >
              <Text
                fontSize="20px"
                textAlign={["center", "left"]}
              >
                Produto
              </Text>
              <Text
                textAlign="center"
                fontSize="25px"
              >
                {productInformation && productInformation?.order?.order_product?.name}
              </Text>
            </Box>
            <Box
              margin={["0", "0 0 0 120px"]}
              paddingRight="20px"
              display="flex"
              flexDirection={["row", "column"]}
              alignItems={["center", "flex-start"]}
            >
              <Text
                fontSize="20px"
                marginRight={["20px", "0"]}
              >
                Valor do produto
              </Text>
              <Text
                fontSize="25px"
                fontWeight="bold"
                color={theme.colors.red500}
                textAlign="center"
              >
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productInformation && productInformation?.order?.order_product?.market_value)}
              </Text>
            </Box>
            
            <Box
              margin="0 20px"
              display="flex"
              flexDirection={["row", "column"]}
              alignItems={["center", "flex-start"]}
            >
            </Box>
            <Box
              margin="0 20px"
              paddingRight="20px"
              display="flex"
              flexDirection={["row", "column"]}
              alignItems={["center", "flex-start"]}
            >
              <Text
                fontSize="20px"
                marginRight={["20px", "0"]}
              >
                Valor total
              </Text>
              <Text
                fontSize="25px"
                fontWeight="bold"
                color={theme.colors.primary}
                textAlign="center"
              >
                  {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productInformation && productInformation?.order?.order_product?.price)}
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="0 20px"
            >
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  useEffect(() => {
    handleProductCard()
  }, [user])

  return (
    <>
      <Navbar />
      <Container
        maxW="container.xl"
      >
        <Box>
          {productInformation === null && (
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          )}
          {/* {user && handleProductCard()} */}
        </Box>
        <Box
          marginTop="60px"
          display="flex"
          flexDirection={["column", "row"]}
          justifyContent="space-between"
          margin={["0px", "60px 100px 100px"]}
        >
          <Box>
            {isLoading === true && (
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            )}
            {isLoading === false && productInformation && productInformation?.payment?.paid === false && (
                <>
                <Tabs variant='soft-rounded' colorScheme='green'>
                  <TabList>
                    <Tab>Pagar com PIX</Tab>
                    <Tab>Pagar com cartão</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Text>
                        <Image src={productInformation?.payment?.pix_qrcode} />
                      </Text>
                    </TabPanel>
                    <TabPanel>
                      {user && productInformation && (
                        <Link to={productInformation && productInformation?.payment?.invoice_url} target="_blank" rel="noopener noreferrer">Abrir Ambiente seguro</Link>
                        )
                      }
                    </TabPanel>
                    {/* <TabPanel>
                      <Box
                        background={theme.colors.gray500}
                        min-height={["375px", "312px"]}
                        width={["100%", "497px"]}
                        border={`1px solid ${theme.colors.primary}`}
                        borderRadius="28px"
                        padding="20px"
                      >
                        <Input
                          margin="10px 0"
                          placeholder="Nome do titular do cartão"
                          background={theme.colors.white}
                          onChange={(e) => setPaymentInputs({...paymentInputs, fullName: e.target.value})}
                        />
                        <Input
                          margin="10px 0"
                          placeholder="Número do cartão"
                          background={theme.colors.white}
                          onChange={(e) => setPaymentInputs({...paymentInputs, number: e.target.value})}

                        />

                        <Box
                          display="flex"
                          flexDirection={["column", "row"]}
                          gap={8}
                          margin="10px 0"
                        >
                          <Box
                            display="flex"
                            gap="8"
                          >
                            <Input
                              placeholder="Mês"
                              background={theme.colors.white}
                              onChange={(e) => setPaymentInputs({...paymentInputs, expirationMonth: e.target.value})}
                            />
                            <Input
                              placeholder="Ano"
                              background={theme.colors.white}
                              onChange={(e) => setPaymentInputs({...paymentInputs, expirationYear: e.target.value})}

                            />
                          </Box>
                          <Input
                            placeholder="CVV"
                            background={theme.colors.white}
                            onChange={(e) => setPaymentInputs({...paymentInputs, verificationCode: e.target.value})}

                          />
                        </Box>
                        <Box
                          margin="20px 0"
                        >
                          <Text
                            color={theme.colors.white}
                            margin="20px 0"
                          >
                            Selecione em quantas parcelas deseja pagar
                          </Text>
                          <Select
                            onChange={(e) => setPaymentInputs({...paymentInputs, numberOfInstallments: e.target.value})}
                          >
                            <option value="1">1x de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productInformation && productInformation?.order?.order_product?.price)}</option>
                            <option value="2">2x de  {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productInformation && productInformation?.order?.order_product?.price / 2)}</option>
                            <option value="3">3x de  {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productInformation && productInformation?.order?.order_product?.price / 3)}</option>
                          </Select>
                        </Box>
                        <Box
                          margin="40px 0"
                        >
                          <Button
                            background={theme.colors.primary}
                            color={theme.colors.white}
                            width="100%"
                            _hover={{
                              background: theme.colors.primary,
                              color: theme.colors.white,
                              opacity: 0.8
                            }}
                          >
                            Pagar {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productInformation && productInformation?.order?.order_product?.price)}
                          </Button>
                        </Box>
                      </Box>
                    </TabPanel> */}
                  </TabPanels>
                </Tabs>
                </>
              )}
          </Box>
          <Box
            marginTop={["20px", "0px"]}
            marginBottom={["70px", "0px"]}
          >
            <Text
              fontSize="30px"
              fontWeight="bold"
            >
              Endereço para retirada
            </Text>
            <AddressCard />
            {/* <Link to="/orders">
              <Button
                background={theme.colors.primary}
                color={theme.colors.white}
                borderRadius="5px"
                width="100%"
                _hover={{
                  background: theme.colors.primary,
                  color: theme.colors.white,
                  opacity: 0.8
                }}
                onClick={() => handleCreateMyOrder(cartProduct)}
              >
                  Finalizar e pagar depois
              </Button>
            </Link> */}
          </Box>
        </Box>
      </Container>  
    </>
  )
}