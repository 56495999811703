import { Box, Button, Container, Text } from "@chakra-ui/react";
import { ContractCaput } from "./components/ContractCaput";
import { ContractHeader } from "./components/ContractHeader";
import { theme } from "../../styles/theme";
import { useStepper } from "../../hooks/useStepper";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";

export function CompanyContract({
  userPartnerName,
  companyName,
  companyCnpj,
  companyAddressStreet,
  companyAddressNumber,
  companyAddressComplement,
  companyAddressNeighborhood,
  companyAddressCity,
  companyAddressState,
  companyAddressPostalCode,
}) {

  const { step, handleSetStepper } = useStepper();
  const { user, isAuthenticated } = useAuth();

  async function handleAcceptContract(e) {
    e.preventDefault();
    const data = {
      company: companyName,
      user: userPartnerName,
    }
    const response = await api.post(`/api/v1/companies/${user.company_id}/contract_signed_at`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
        uid: user.uid,
        client: user.client,
        'access-token': user.accessToken,
        'Access-Control-Allow-Origin': '*',
      }
    })

    if(response.data.status !== 200) {
      alert("Contrato aceito com sucesso!");
      handleSetStepper(step + 1);
    }
  }

  useEffect(() => {
    isAuthenticated();
  }, [])
  

  return (
    <Container
      maxW="container.xl"
    >
      <Box
        margin="10px 0"
      >
        <Text
          fontSize="32px"
          fontWeight="bold"
          textAlign="center"
        >
          TERMOS DE USO DA PLATAFORMA SCONTO PARA PARCEIROS
        </Text>
      </Box>
      <Box
        margin="10px 0"
      >
        <Text
          fontSize="16px"
          textAlign="center"
          fontWeight="bold"
          textDecoration="underline"
        >
          DAS PARTES CONTRATANTES
        </Text>
      </Box>
      <Box
        margin="20px 0"
      >
        <Text>
          <b>ALMATEIA LTDA</b>, com sede na cidade de Presidente Prudente, na Rua São Sebastião, nº 458, Vila 
          Machadinho, inscrita no CNPJ sob o nº 48.469.161/0001-55, doravante denominada por CONTRATADA, ou, 
          alternativamente, PLATAFORMA; tem como objetivo regulamentar a relação jurídica entre as partes na 
          utilização da plataforma Sconto sendo a intermediadora de vendas (Marketplace), e outras avenças, o 
          qual se regerá pelas cláusulas e pelas condições descritas no presente.  
        </Text>
        <Text>
          Você reconhece que analisou e aceitou as condições de uso. Leia-as atentamente pois o uso deste 
          aplicativo significa que você aceitou todos os termos e concorda em cumpri-los. Se você, parceiro, 
          for menor de idade ou declarado incapaz em quaisquer aspectos, precisará da permissão de seus pais ou 
          responsáveis que também deverão concordar com estes mesmos termos e condições.
        </Text>
      </Box>
      <ContractHeader 
        title="CONTRATO"
      />
      <ContractCaput 
        article="Cláusula 1ª."
        content="Após a celebração do presente contrato, ficarão as partes, CONTRATANTE e CONTRATADA, sujeitas 
        a todos os termos deste contrato, inclusive às penalidades previstas."
      />
      <ContractHeader 
        title="DO OBJETO DO CONTRATO"
      />
      <ContractCaput
        article="Cláusula 2ª."
        content="O presente instrumento tem por objeto regulamentar a relação jurídica entre as partes, que estabelecer-se-á mediante a prestação, pela CONTRATADA, de serviços de intermediação de vendas, através do sistema de Marketplace próprio, aplicando descontos específicos nos produtos inseridos na PLATAFORMA, de modo que os pedidos realizados pelos usuários finais sejam concluídos diretamente dentro do aplicativo, com o objetivo de intermediar e facilitar a relação entre usuários do aplicativo e anunciantes que somente serão passíveis de utilização mediante prévia reserva por intermédio do mencionado aplicativo, contudo, a retirada do produto adquirido, ocorrera no estabelecimento físico da CONTRATANTE."
      />
      <ContractCaput
        article="Parágrafo Primeiro:"
        content="A CONTRATANTE reconhece e concorda, ainda, que a Cláusula 2º, poderá ser alterado pela CONTRATADA, a qualquer momento, para possibilitar constantes melhorias e aprimoramento na PLATAFORMA, bem como incluir novos Planos de Contratação e/ou para alteração ou descontinuidade de Planos de Contratação então vigentes, mediante o envio de simples e-mail ou notificação por carta com aviso de recebimento, em até 30 (trinta) dias, contados a partir da data em que a alteração entrar em vigor."
      />
      <ContractHeader
        title="DA CONTRAPRESTAÇÃO"
      />
      <ContractCaput
        article="Cláusula 3ª."
        content="A título de contraprestação pelos serviços prestados, a CONTRATANTE pagará, à CONTRATADA, o montante correspondente a 10% (dez por cento) sobre o valor de cada venda."
      />
      <ContractCaput
        article="Parágrafo Único:"
        content="A CONTRATANTE reconhece e concorda, ainda, que a Cláusula 3º, poderá ser alterado pela CONTRATADA, a qualquer momento, podendo majorar ou reduzir o percentual supramencionado, sem necessidade de notificação prévia."
      />
      <ContractHeader
        title="DAS OBRIGAÇÕES GERAIS DA CONTRATADA"
      />
      <ContractCaput
        article="Cláusula 4ª."
        content="A CONTRATADA goza de autonomia para prestar os serviços objeto do presente contrato, assim como declara estar, e compromete-se a se manter, ao longo da vigência do presente contrato, amoldado à todas as legislações vigentes, para o exercício de suas atividades, utilizando-se de meios lícitos para a prestação de seus serviços, e responsabilizando-se exclusivamente pela idoneidade de sua atuação."
      />
      <ContractCaput 
        article="Cláusula 5ª."
        content="A CONTRATADA poderá contratar empregados ou terceiros para prestação dos serviços elencados na Cláusula 2ª."
      />
      <Box
        margin="10px 0"
      >
        <Text>
          <b>Parágrafo único:</b> Em eventual demanda envolvendo responsabilidade civil ou trabalhista, o CONTRATADO será o único e exclusivamente responsável pelo pagamento de todos os encargos trabalhistas e/ou remuneratórios de empregados e/ou terceiros que contratar para prestarem os serviços objeto do presente contrato, e <b>exime</b> a CONTRATANTE de qualquer responsabilidade perante estes, seja esta solidária ou subsidiária, salvo dano cometido pelo CONTRATANTE. 
        </Text>
      </Box>
      <ContractCaput 
        article="Cláusula 6ª."
        content="A CONTRATADA pode prestar treinamento inicial à CONTRATANTE, para o melhor funcionamento da plataforma, caso este esteja disponível e adequado à modalidade de contratação."
      />
      <ContractCaput
        article="Cláusula 7ª."
        content="A CONTRATADA pode, de acordo com seus objetivos de negócio, modificar, suspender ou descontinuar temporariamente as funcionalidades, Serviços e/ou conteúdo da plataforma, segundo sua exclusiva conveniência, sempre que entender necessário.  Intervenções, de qualquer natureza, poderão ocorrer dentro do horário comercial ou de pico, e sem aviso prévio, sendo que a CONTRATADA não será responsabilizada pela indisponibilidade."
      />
      <ContractCaput
        article="Parágrafo Primeiro:" 
        content="A CONTRATADA poderá restringir, limitar ou impedir, por qualquer meio ou forma, o acesso da CONTRATANTE, conforme seu exclusivo critério, especialmente nos casos repetidos de suspeitas de fraude ou quaisquer outras ações que prejudiquem ou possam prejudicar o funcionamento da PLATAFORMA."
      />
      <ContractCaput
        article="Parágrafo Segundo:"
        content="A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da PLATAFORMA em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé ao evitar possíveis danos e prejuízos a terceiros, jamais como reconhecimento de culpa ou infração por parte da CONTRATADA."
      />
      <ContractHeader
        title="DAS OBRIGAÇÕES GERAIS DA CONTRATANTE"
      />
      <ContractCaput
        article="Cláusula 8ª."
        content=" A CONTRATANTE declara estar, e compromete-se a se manter, ao longo da vigência do presente contrato, amoldado a todas as legislações vigentes, para o exercício de suas atividades, utilizando-se de meios lícitos para a prestação de seus serviços."
      />
      <ContractCaput
        article="Parágrafo único:"
        content="A CONTRATANTE compromete-se a manter, sempre, em qualquer anúncio/ e ou comunicação dentro da plataforma, tratamento cordial e respeitoso com terceiros, assim como linguagem adequada, restando vedadas palavras de baixo calão, assim como expressões preconceituosas."
      />
      <ContractCaput
        article="Cláusula 9ª."
        content="A CONTRATANTE declara estar ciente, que deverá oferecer os produtos e/ou mercadorias com descontos mínimos de 30% (trinta por cento) do valor de gôndola, sendo alterado conforme o produto/ e ou mercadoria comercializada."
      />
      <ContractCaput
        article="Parágrafo Primeiro:"
        content="A CONTRATANTE declara estar ciente, e concorda expressamente, que os descontos são exclusivos aos usuários finais do aplicativo, sendo vedado oferecer desconto idêntico ou semelhante aos consumidores que não apresentarem o cupom ou QR code do aplicativo, sob pena de multa de 30% (trinta por cento) do valor do faturamento bruto das vendas realizadas por intermédio do aplicativo, servindo como valor indenizatório mínimo, em eventuais perdas e danos."
      />
      <ContractCaput
        article="Parágrafo Segundo:"
        content="A CONTRATANTE declara estar ciente, que deverá inserir o valor de gôndola dos produtos comercializadas, juntamente com o valor do desconto, inclusive deverá comprovar mediante imagens das gôndolas, que será exposta ao usuário final, ao passo que é expressamente vedado expor produtos com valores zerados, sob pena de suspensão do aplicativo e/ou de não ser possível expor os mesmos produtos no aplicativo."
      />
      <ContractCaput
        article="Cláusula 10ª."
        content="A CONTRATANTE compromete-se, em realizar semanalmente a manutenção de novas ofertas, assim como de estoque, além de fornecer, sempre que solicitado, à CONTRATADA, todas as informações e documentações necessárias para demonstrar a obrigação aqui descrita, sob pena de suspensão da plataforma, ou exclusão em caso de reiteração."
      />
      <ContractCaput
        article="Parágrafo Primeiro:"
        content="A CONTRATANTE declara estar ciente de que qualquer oferta, exposta por intermédio do aplicativo, terá como prazo máximo 7 (sete) dias, podendo o prazo ser reduzido a critério da CONTRATADA, sob pena da oferta ser excluída automaticamente."
      />
      <ContractCaput
        article="Parágrafo Segundo:"
        content="A CONTRATANTE compromete-se em indicar, em seu anúncio, exatamente a quantidade de produtos disponíveis para a respectiva promoção, bem como manter, em seu estoque, no mínimo mais 50% (cinquenta por cento) da determinada quantidade, para fins preventivos."
      />
      <ContractCaput
        article="Cláusula 11ª."
        content="A CONTRATANTE demonstra plena ciência acerca de que eventual encerramento da promoção, não gera isenção automática da CONTRATANTE, ou seja, deverá entregar os produtos adquiridos pelo usuário final, o qual terá até 7 (sete) dias para fazer a retirada, contado da data da reserva, ainda que a data da retirada seja posterior ao encerramento da promoção (desde que a reserva tenha sido efetuada enquanto vigente o anúncio)."
      />
      <ContractCaput
        article="Cláusula 12ª."
        content="A CONTRATANTE é responsável por limitar os produtos adquiridos pelos usuários finais, controlando a utilização de apenas um CPF por cupom, sendo expressamente vedado operações com CNPJ, assim como compra em grande quantidade, ou seja, que desvirtuem o objetivo do aplicativo, e transformem as operações em compras via atacado."
      />
      <ContractCaput
        article="Cláusula 13ª."
        content="A CONTRATANTE declara estar ciente acerca do fato de que deverá utilizar plataformas de gateway (intermediação) de pagamentos para todos a venda de todos os produtos adquiridos com cupons da CONTRATADA, sendo expressamente vedado o adimplemento do usuário final por meio de dinheiro em espécie; dessa forma, compromete-se a fornecer, sempre que solicitado, todas as informações e documentações necessárias para demonstrar o cumprimento da obrigação aqui descrita, sob pena de exclusão da plataforma e multa no montante de 10 (dez) salários mínimos, bem como eventuais perdas e danos."
      />
      <ContractCaput
        article="Cláusula 14ª."
        content="A CONTRATANTE autoriza expressamente a realização de auditorias e fiscalizações externas, para verificar a regularidade dos documentos, estoques e respeito a legislação vigente, e devendo sempre que solicitado, providenciar todas as informações e documentações necessárias."
      />
      <ContractHeader
        title="DA LIMITAÇÃO DE RESPONSABILIDADE"
      />
      <ContractCaput
        article="Cláusula 15ª."
        content="A CONTRATANTE reconhece de maneira incontestável, sua posição jurídica de fornecedora, frente aos usuários finais, ora consumidores, visto que o teor dos anúncios promocionais, bem como os valores, imagens, entrega e disponibilidade dos produtos, é de sua exclusiva responsabilidade cível e criminal. Dessa forma, a CONTRATANTE, exime, expressamente, à CONTRATADA de qualquer responsabilidade perante os usuários finais, seja esta solidária ou subsidiária, havendo condenação da CONTRATADA por qualquer evento de responsabilidade da CONTRATANTE, à CONTRATADA resta facultado o direito de regresso, em face da CONTRATANTE."
      />
      <ContractCaput
        article="Parágrafo único:"
        content="A CONTRATADA não possui nenhuma relação com a produção ou com a organização dos produtos publicados em sua plataforma. Atua, portanto, como mera intermediadora, utilizando sua tecnologia com intuito de facilitar as relações entre os usuários finais e a CONTRATANTE, para os fins de compra e venda com a utilização de cupons de desconto."
      />
      <ContractCaput
        article="Cláusula 16ª."
        content="Em nenhuma hipótese a CONTRATADA se responsabilizará:"
      />
      <Box
        marginLeft={8}
      >
        <Text>
          <strong>(I)</strong> Por quaisquer problemas, indisponibilidades, divergências entre cupons/descontos e o produto final, falha ou má prestação de serviço, eventuais danos ao consumidor final, de modo que a responsabilidade mediante tais transtornos, é exclusivamente da CONTRATANTE.
        </Text>
        <Text>
          <strong>(II)</strong> Pelas falhas de acesso à plataforma decorrentes de circunstâncias relacionadas à indisponibilidade da Internet em geral, quedas de energia, mau funcionamento digital ou físico de qualquer rede de telecomunicações, interrupções ou suspensões de conexão e falhas nos softwares e/ou hardwares utilizados pela CONTRATANTE;
        </Text>
        <Text>
          <strong>(III)</strong> Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, como ataque de hackers, falhas no sistema, no servidor ou na conexão à Internet, inclusive por ações de softwares maliciosos, como vírus, cavalos de Tróia e outros que possam, de algum modo, danificar o equipamento ou a conexão da CONTRATANTE em decorrência do acesso, utilização ou navegação na PLATAFORMA, bem como a transferência de dados e arquivos nela contidos;
        </Text>
        <Text>
          <strong>(IV)</strong> A CONTRATADA não será responsável, sob quaisquer circunstâncias, por eventuais perdas e danos, incluindo lucros cessantes, relacionados à suspensão do acesso à plataforma. A CONTRATANTE não possui qualquer direito em relação ao não funcionamento ou indisponibilidade da PLATAFORMA, tampouco poderá pleitear indenização ou reparação de danos em caso de a plataforma permanecer fora do ar, independentemente da motivação.
        </Text>
      </Box>
      <ContractHeader
        title="DA CESSÃO DE DIREITOS AUTORAIS"
      />
      <ContractCaput
        article="Cláusula 17ª."
        content="Em razão da natureza do presente contrato, a CONTRATANTE declara, de forma plena e inequívoca, que confere total cessão, à CONTRATADA, dos direitos de uso e direitos autorais sobre todo o projeto e todos os aspectos dos serviços em questão, sem reservas."
      />
      <ContractCaput
        article="Cláusula 18ª."
        content="A CONTRATADA está devidamente registrada e protegida pelas normas de propriedade industrial e, assim sendo, a prestação de seus serviços não implica, em nenhuma hipótese, em concessão do direito de uso de sua marca, símbolo ou desenho industrial, sem o seu prévio consentimento e expressa autorização para tanto."
      />
      <ContractHeader
        title="DOS DIREITOS DO CONTRATANTE"
      />
      <ContractCaput
        article="Cláusula 19ª."
        content="A CONTRATANTE possui a prerrogativa de realizar ofertas ilimitadas de seus produtos, desde que não viole a boa-fé, e as cláusulas aqui estabelecidas."
      />
      <ContractCaput
        article="Cláusula 20ª."
        content="A CONTRATANTE possui o direito em participar do treinamento inicial fornecido pela CONTRATADA, assim como, de ser qualificada como verificada e diferenciada por nível, sob critérios definidos e observados, exclusivamente, pela CONTRATADA, que poderá alterá-los a qualquer momento, sem necessidade de comunicação prévia."
      />
      <ContractCaput
        article="Parágrafo único:"
        content="A conclusão do treinamento inicial pela CONTRATANTE, fará jus a um selo de verificação, oportunizando diversos benefícios na plataforma, a critério da CONTRATADA."
      />
      <ContractCaput
        article="Cláusula 21ª."
        content="A CONTRATANTE poderá oferecer promoções com produtos de mesma qualidade, quantidade e características, ainda que de marcas distintas, inclusive oferecendo promoções exclusivas e ou descontos progressivos aos usuários finais, estando a CONTRATADA autorizada a alterar tais condições a qualquer momento."
      />
      <ContractHeader
        title="DA CONFIDENCIALIDADE"
      />
      <Box
        margin="10px 0"
      >
        <Text>
          <strong>Cláusula 22ª.</strong> A CONTRATANTE assume o compromisso de guardar sigilo a respeito de 
          qualquer informação sobre objeto do presente contrato, bem como de dados ou noções envolvendo à 
          CONTRATADA, ou projeto que permeie a relação jurídica entre as partes, existentes <strong>antes</strong>
          ou <strong>após</strong> este contrato, tais como aquelas sobre operações, dados, estratégias, códigos, 
          materiais, pormenores, documentos, sendo expressamente vedada a revelação/transmissão de quaisquer 
          informações à terceiros, salvo em caso de consentimento expresso, viabilizado por escrito, pela CONTRATADA.
        </Text>
      </Box>
      <ContractCaput
        article="Parágrafo Primeiro:"
        content="Ao guardar sigilo, a CONTRATANTE se compromete a não utilizar de informações confidenciais, ou toda e qualquer informação decorrente da relação jurídica para com a CONTRATADA, necessária a prestação de seus serviços, para benefício próprio, exclusivo, ou para uso de terceiros, seja no momento presente ou em eventual situação futura."
      />
      <ContractCaput
        article="Parágrafo Segundo:"
        content="A CONTRATANTE compromete-se, também, a não efetuar nenhuma gravação ou cópia de material ou documentação da CONTRATADA, ou de terceiros e ele relacionados, que tenha acesso em decorrência do presente contrato."
      />
      <Box
        margin="10px 0"
      >
        <Text>
          <strong>Parágrafo Terceiro:</strong> A CONTRATANTE deverá guardar sigilo da forma estabelecida neste 
          termo, durante a vigência do contrato firmado entre as partes, e por prazo <strong>ILIMITADO</strong>, após a data da 
          rescisão contratual.
        </Text>
      </Box>
      <ContractCaput
        article="Cláusula 23ª."
        content="Considera-se, para os devidos fins, como “informações confidenciais”, todas as informações, orais e/ou escritas, reveladas, transmitidas e/ou divulgadas pela CONTRATADA à CONTRATANTE, restritas e de propriedade da CONTRATADA, bem como todos os termos, condições e fatos relativos às discussões e negociações que eventualmente a CONTRATANTE participar ou for informado, em decorrência da relação jurídica aqui pactuada."
      />
      <ContractCaput
        article="Parágrafo Único:"
        content="Qualquer direito, licença, exploração de marcas, invenções, direitos autorais, patentes, direito de propriedade intelectual são igualmente considerados como informações confidenciais e estão incluídos ao bojo da presente cláusula de Confidencialidade."
      />
      <ContractCaput
        article="Cláusula 24ª."
        content="A CONTRATANTE recolherá, para imediata devolução à CONTRATADA, ao término do contrato firmado entre as partes, todo e qualquer material de propriedade da CONTRATADA, inclusive notas pessoais envolvendo matéria sigilosa, registro de documentos de qualquer natureza que tenham sido criados, utilizados ou mantidos sob seu controle ou posse, seja sua, de seus empregados, prepostos, prestadores de serviços, fornecedores, com ou sem vínculo empregatício."
      />
      <ContractCaput
        article="Cláusula 25ª."
        content="A CONTRATANTE assume o compromisso de zelar, de forma inequívoca, pela confidencialidade pactuada ao bojo do presente, sendo que esta se estende a seus funcionários, prepostos ou prestadores de serviço."
      />
      <ContractCaput
        article="Parágrafo Único:"
        content="Qualquer violação de confidencialidade, praticada por sujeito relacionado à CONTRATANTE (empregado, preposto, prestador de serviço etc.) será considerada como violação de confidencialidade da próprio CONTRATANTE, vez que expressamente responsável pelo zelo das informações e não repasse a terceiros."
      />
      <ContractCaput
        article="Cláusula 26ª."
        content="Em caso de violação do sigilo e, consequente descumprimento de quaisquer das cláusulas de confidencialidade, a CONTRATANTE arcará com multa por descumprimento no montante de 50 (cinquenta) salários mínimos, bem como será responsabilizado pelas perdas e danos causados à CONTRATADA, sem prejuízo das demais medidas legais cabíveis."
      />
      <ContractCaput
        article="Parágrafo Único:"
        content="A multa pelo descumprimento não exclui eventual responsabilidade civil e/ou criminal dos que estiverem envolvidos no descumprimento ou violação da confidencialidade aqui pactuada, bem como nas consequências da divulgação indevida."
      />
      <ContractHeader
        title="DA PROTEÇÃO DE DADOS"
      />
      <ContractCaput
        article="Cláusula 27ª."
        content="A CONTRATANTE e a CONTRATADA assegura que as operações efetuadas para o tratamento de dados pessoais de seus todos seus clientes, fornecedores, consumidores e empregados, incluindo, mas sem se limitar, as operações de registro, armazenamento, alteração, análise, utilização, transmissão, combinação, bloqueio, exclusão ou destruição, estão em absoluto cumprimento com os direitos do titular dos dados e respectiva legislação, e cumprirá, da mesma forma, o integral teor dos diplomas normativos vigentes, para fins do objeto do presente contrato."
      />
      <ContractCaput
        article="Cláusula 28ª."
        content="A CONTRATADA reconhece que, no decorrer da execução deste contrato, poderá vir a ter acesso a dados pessoais de clientes, empregados e parceiros da CONTRATANTE, e vice-versa. Sendo assim, as partes declaram e garantem que detêm os direitos para tratar os dados pessoais de seus respectivos clientes, empregados e parceiros, e para compartilhá-los com a outra parte e com terceiros, no âmbito do contrato, bem como que a coleta dos dados pessoais foi realizada de forma legítima pelas partes, em observância aos princípios da boa-fé e aqueles previstos no artigo 6º, da Lei Geral de Proteção de Dados, bem como aos requisitos estabelecidos pelos artigos 7º a 10º, da mesma Lei."
      />
      <ContractCaput
        article="Cláusula 29ª."
        content="Todos os dados serão utilizados somente para cumprimento das leis e poderão ser excluídos automaticamente após 10 (dez) anos, desde que não haja exigência legal e/ou judicial para a manutenção por prazo superior."
      />
      <ContractCaput
        article="Cláusula 30ª."
        content="As partes concordam e consentem, de parte a parte, que manterão a outra parte indene e a defenderão contra quaisquer reivindicações, reclamações ou demandas dos titulares dos dados pessoais, de órgãos administrativos ou da Autoridade Nacional de Proteção de Dados (ANPD) relacionada ao tratamento de dados pessoais de clientes, empregados e parceiros relacionados ao contrato, que se baseie ou decorra de: i) qualquer ato ou omissão por qualquer das partes ou de qualquer afiliado no processo de tratamento, sobretudo no tocante à coleta de dados pessoais; ou ii) tratamento de dados pessoais que não guarde qualquer relação com o presente contrato ou com as atividades prestadas pela outra parte, no âmbito deste contrato."
      />
      <ContractCaput
        article="Cláusula 31ª."
        content=" A CONTRATANTE, por si e por seus colaboradores, obriga-se a atuar, no que diz respeito ao objeto do presente contrato, em conformidade com a Legislação vigente sobre Proteção de Dados Pessoais e as determinações de órgãos reguladores/fiscalizadores sobre a matéria, em especial a Lei 13.709/2018, além das demais normas e políticas de proteção de dados de cada país onde houver qualquer tipo de tratamento dos dados dos clientes, o que inclui os dados dos clientes desta, de modo que, a CONTRATANTE, deverá:"
      />
      <Box
        marginLeft={8}
      >
        <Text>
          <strong>(I)</strong> Tratar os dados pessoais a que tiver acesso apenas de acordo com as instruções da CONTRATADA e em conformidade com estas cláusulas, e que, na eventualidade, de não mais poder cumprir estas obrigações, por qualquer razão, concorda em informar, de modo formal, este fato imediatamente à CONTRATADA, que terá o direito de rescindir o contrato sem qualquer ônus, multa ou encargo;
        </Text>
        <Text>
          <strong>(II)</strong>  Manter e utilizar medidas de segurança administrativas, técnicas e físicas apropriadas e suficientes para proteger a confidencialidade e integridade de todos os dados pessoais mantidas ou consultados/transmitidos eletronicamente, para garantir a proteção desses dados contra acesso não autorizado, destruição, uso, modificação, divulgação ou perda acidental ou indevida;
        </Text>
        <Text>
          <strong>(III)</strong> Acessar os dados dentro de seu escopo e na medida abrangida por sua permissão de acesso (autorização) e que os dados pessoais não podem ser lidos, copiados, modificados ou removidos sem autorização expressa e por escrito da CONTRATADA;
        </Text>
      </Box>
      <ContractCaput
        article="Cláusula 32ª."
        content="Caso a CONTRATANTE seja obrigada por determinação legal a fornecer dados pessoais que envolvam a relação objeto do presente contrato, a uma autoridade pública, deverá informar previamente à CONTRATADA para que esta tome as medidas que julgar cabíveis."
      />
      <ContractHeader
        title="DA CLASSIFICAÇÃO DA CONTRATANTE"
      />
      <ContractCaput
        article="Cláusula 33ª."
        content="A PLATAFORMA contará com níveis de classificação das CONTRATANTES, bem como dos USUÁRIOS, os critérios serão estabelecidos unilateralmente pela CONTRATADA, não ocasionando nenhum ônus às CONTRATANTES, apenas para auferir o grau de satisfação e recomendação do estabelecimento."
      />
      <ContractHeader
        title="DO REBAIXAMENTO, SUSPENSÃO E DESLIGAMENTO DO CONTRATANTE"
      />
      <ContractCaput
        article="Cláusula 34ª."
        content=" A CONTRATADA possui a prerrogativa de aplicar sanções a CONTRATANTE, se eventualmente ocorrer qualquer violação às cláusulas do presente instrumento contratual, ou violações das Políticas/Termos de Uso, bem como violações à boa-fé."
      />
      <ContractCaput
        article="Parágrafo único:"
        content="Considera-se violações a boa-fé, qualquer publicação de promoções fraudulentas ou enganosas, bem como oferecer descontos ilusórios ou apresentar produtos omitindo informações com relação a qualidade, quantidade ou características do produto, ou qualquer outra atitude que cause lesão ou exponha a lesão o usuário final ou a própria CONTRATADA."
      />
      <ContractCaput
        article="Cláusula 35ª."
        content="A CONTRATADA, poderá aplicar as seguintes sanções:"
      /> 
      <Box
        marginLeft={8}
      >
        <Text>
          <strong>(I)</strong> Rebaixamento da posição ocupada pela CONTRATANTE;
        </Text>
        <Text>
          <strong>(II)</strong> Suspensão da CONTRATANTE por até 30 (trinta) dias; 
        </Text>
        <Text>
          <strong>(III)</strong> Desligamento definitivo da CONTRATANTE. 
        </Text> 
      </Box>
      <ContractCaput
        article="Parágrafo Único:"
        content="As sanções supracitadas, não serão aplicadas de forma gradual, ou seja, a sanção aplicável será correspondente à lesão cometida, de modo que a gravidade das penas estará submetida ao exclusivo juízo de valor da CONTRATADA, que poderá impô-las, a qualquer momento, sem necessidade de notificação à CONTRATANTE, sem que possua direito a qualquer valor a título de indenização."
      />
      <ContractHeader
        title="DOS PRODUTOS PROIBIDOS"
      />

      <ContractCaput
        article="Cláusula 36ª." 
        content="É expressamente desautorizado expor à venda ou oferecer descontos de produtos proibidos, sendo vedado a inserção de cupons à PLATAFORMA dos seguintes produtos:"
      />
      <Box
        marginLeft={8}
      >
        <Text>
          (I) cigarros, cigarros eletrônicos, qualquer produto análogo ou derivado do tabaco;
        </Text>
        <Text>
          (II) sexuais ou sexualizados; 
        </Text>
        <Text>
          (III) ilícitas, como entorpecentes, armas de fogo ou simulacros; 
        </Text>
        <Text>
          (IV) remédios controlados;
        </Text>
        <Text>
          (V) qualquer produto que viole a legislação vigente ou os bons costumes; 
        </Text>
      </Box>

      <ContractHeader
        title="DA RESCISÃO CONTRATUAL"
      />

      <ContractCaput
        article="Cláusula 37ª."
        content="O presente contrato não poderá ser objeto de cessão, transferência ou subcontratação por quaisquer das partes, salvo em caso de aditivo contratual expresso."
      />
      <ContractCaput
        article="Cláusula 38ª."
        content="O presente instrumento contratual poderá ser rescindido de forma imotivada, por qualquer das partes, mediante simples cancelamento da conta na própria plataforma disponibilizada pela CONTRATADA."
      />
      <ContractCaput
        article="Parágrafo único"
        content="Independentemente de qual das partes manifestar a intenção rescisória e o cancelamento da conta, a CONTRATADA fica responsável a cumprir e efetivamente entregar os produtos nos moldes e preços dos anúncios das promoções que estavam vigentes quando da rescisão, para cada usuário final que reservou em tempo."
      />
      <ContractCaput
        article="Cláusula 39ª."
        content="A CONTRATADA poderá desligar a CONTRATANTE da PLATAFORMA, consequentemente rescindindo o presente instrumento contratual de forma motivada, por descumprimento da CONTRATANTE em qualquer das cláusulas e/ou condições previstas neste, estando a infratora obrigada ao pagamento de eventuais perdas e danos, sem exclusão de responsabilidade criminal."
      />
      <ContractCaput
        article="Parágrafo único:"
        content="A rescisão contratual não obsta a cobrança de quaisquer valores em aberto a serem adimplidos por qualquer das partes."
      />
      <ContractCaput
        article="Cláusula 40ª."
        content="A partir da rescisão, a CONTRATANTE compromete-se a cessar a utilização de quaisquer marcas, sinais gráficos, formulários, panfletos ou qualquer elemento que faça menção à CONTRATADA."
      />

      <ContractHeader
        title="DA ASSINATURA ELETRÔNICA"
      />

      <ContractCaput
        article="Cláusula 41ª."
        content="As partes concordam que, não existirá qualquer assinatura física, sendo assim, a ciência e concordância do presente instrumento, ocorrerá por simples aceite na própria plataforma SCONTO."
      />
      <ContractCaput
        article="Parágrafo único:"
        content="Após o aceite expresso, deverá ser encaminhado ao endereço eletrônico do CONTRATANTE, cópia do Contrato de Intermediação de Vendas."
      />

      <ContractHeader
        title="DAS DISPOSIÇÕES FINAIS"
      />

      <ContractCaput
        article="Cláusula 42ª."
        content="A tolerância do eventual descumprimento de qualquer disposição no presente documento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo."
      />

      <ContractCaput
        article="Cláusula 43ª." 
        content="Caso alguma disposição do presente documento seja julgada inaplicável ou sem efeito, o restante do documento continua a viger, sem a necessidade de medida judicial que declare tal assertiva."
      />
      <ContractCaput
        article="Cláusula 44ª." 
        content="As partes declaram que aceitam os termos, condições, cláusulas e itens contidos neste instrumento, bem que antes de aceitação, tiveram tempo suficiente para obtenção das informações e esclarecimentos que julgaram necessários."
      />
      <ContractCaput
        article="Cláusula 45ª." 
        content="As partes acordam que não possuem entre si qualquer relação de subordinação ou empregatícia, sendo, o presente contrato, pactuado a título de prestação de serviços autônoma, ausentes quaisquer dos requisitos da relação de emprego."
      />
      <ContractCaput
        article="Cláusula 46ª." 
        content="A CONTRATANTE declara plena e inequívoca ciência acerca do fato de que deve restringir a venda e efetiva entrega de produtos a usuários finais que efetivamente comprovarem que são maiores de 18 (dezoito) anos na data de retirada, nos termos da Lei, de modo que se responsabiliza exclusivamente pelas consequências de eventual venda e entrega de produtos à menores de idade, resguardando-se a CONTRATADA ao direito de regresso."
      />
      <ContractCaput
        article="Cláusula 47ª."
        content="As partes elegem, para dirimir qualquer controvérsia oriunda do presente contrato, com exclusão de qualquer outro, por mais privilegiado que seja, o Foro da Comarca de Presidente Prudente/SP."
      />
    <Box
      margin="20px 0"
    >
      <Text>
        Estando assim, de comum acordo e por livre espontânea vontade, firmam o presente instrumento, através de 
        simples adesão através da plataforma SCONTO, bem como será encaminhado ao endereço eletrônico do 
        Contratante cópia integral do presente instrumento.
      </Text>
    </Box>
    <Box
      margin="50px auto"
    >
      <Button
        width="100%"
        background={theme.colors.primary}
        color={theme.colors.white}
        _hover={{
          background: theme.colors.primary,
          color: theme.colors.white,
          opacity: 0.8
        }}
        onClick={(e) => handleAcceptContract(e)}
      >
        <Text
          fontSize={["14px", "18px"]}
          padding="0 5px"
          whiteSpace="normal"
          wordWrap="break-word"
        >
          Declaro que li e que <strong>ACEITO</strong> os termos aqui dispostos.
        </Text>
      </Button>
      <Box
        display="flex"
        justifyContent="flex-end"
        flexDirection="column"
        float="right"
        margin="100px 0"
      >
        <Text
          fontFamily={theme.fonts.signature}
          textDecoration="underline"
          fontSize="32px"
        >
          {userPartnerName}
        </Text>
        <Text>
          {companyName}
        </Text>
        <Text>
          CNPJ: {companyCnpj}
        </Text>
      </Box>
    </Box>
    </Container>
  )
}