import { Box, Text } from "@chakra-ui/react";

export function ContractCaput({
  article,
  content
}){
  return (
    <Box
      margin="10px 0"
    >
      <Text>
        <strong>{article}</strong> {content}
      </Text>
    </Box>
  )
}