import { createContext, useState } from "react";

export const MenuSelectionContext = createContext({});

export function MenuSelectionProvider(props) {
  const [startIndex, setStartIndex] = useState([])

  return (
    <MenuSelectionContext.Provider
      value={{startIndex, setStartIndex}}
    >
      {props.children}
    </MenuSelectionContext.Provider>
  );
}