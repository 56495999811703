import { Box, Image, Text } from "@chakra-ui/react";
import { MapPinLine } from "phosphor-react";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";

export function NextToMeCard({
  companyId,
  companyLogo,
  companyName,
  companyAddressStreet,
  companyAddressNumber,
  companyAddressComplement,
  companyAddressNeighborhood,
  companyAddressCity,
  companyAddressState,
  companyFantasyName
}) {
  return (
    <Link to={`/empresa/${companyId}`}>
      <Box
        border={`1px solid ${theme.colors.primary}`}
        borderRadius="8px"
        display="flex"
        alignItems={"center"}
        width={["92%", "100%"]}
      >
        <Box>
          <Image 
            src={companyLogo} 
            minWidth={["150px", "200px"]}
            height={["230px", "230px"]}
            borderRadius="8px 0 0 8px"
          />
        </Box>
        <Box
          padding="10px"
        >
          <Box>
            <Text
              fontWeight="bold"
              textAlign="center"
            >
              {companyFantasyName}
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            margin="5px 0"
          >
            <Box>
              <MapPinLine 
                size={32} 
                weight="fill" 
                color={theme.colors.primary} 
              />
            </Box>
            <Box>
              <Text
                fontSize="14px"
                marginLeft="10px"
              >
                {companyAddressStreet}, 
                {companyAddressNumber} {companyAddressComplement && `, ${companyAddressComplement}`},
                {companyAddressNeighborhood}, {companyAddressCity} - {companyAddressState}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}