import { Container, Grid, GridItem, Spinner, Image, Text } from "@chakra-ui/react";
import { MobileNavbar } from "../../components/MobileNavbar";
import { Navbar } from "../../components/Navbar";
import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";

export function Companies() {
  const [companies, setCompanies] = useState();
  const [isLoading, setIsLoading] = useState(false);

  async function handleCompaniesData() {
    const response = await api.get("api/v1/companies")
    setCompanies(response.data)
    setIsLoading(false);
  }
  useEffect(() => {
    setIsLoading(true)
    handleCompaniesData()
  }, [])

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW={["container.sm", "container.xl"]}
      >
        {isLoading && (
            <Spinner
            margin="30px 0"
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color={theme.colors.primary}
            size='xl'
          />
        )}
          <Grid 
            templateColumns={[`repeat(1, 366px)`, `repeat(2, 266px)`, `repeat(4, 266px)`]}
            gap={6}
            padding={["0", "0 39px"]}
            marginTop="20px"
          >
           {companies?.map((company) => {
              return (
                <Link to={`/empresa/${company.id}`} key={company.id}>
                  <GridItem
                    borderRadius="8px"
                    boxShadow='xl'
                    _hover={{
                      boxShadow: 'dark-lg'
                    }}
                    cursor="pointer"
                    minHeight="300px"
                  >
                    <Image 
                      borderRadius="8px 8px 0 0"
                      src={company.logo_url} 
                      height="180px"
                      width="100%"
                    />
                    <Text
                      fontWeight="bold"
                      textAlign="center"
                      margin="10px 0"
                      padding="20px"
                    >
                      {company.name}
                    </Text>
                  </GridItem>
                </Link>
              );
          })}
        </Grid>
      </Container>
    </>
  )
}