import React from "react";
import { Box, Button, Image, Text } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
// import { Heart } from "phosphor-react";
// import IconCircle from "../../assets/images/icon_plus_circle.svg";
import { useNavigate } from "react-router-dom";

export function Recommended({id, name, description, price, photo}) {
  const history = useNavigate()

  function handleSetSelectedProduct(id) {
    history(`/product/${id}`)
    window.location.reload()
  }

  return (
    <Box
      width={["98%", "100%"]}
      height={["100%", "185px"]}
      padding="20px"
      boxShadow="2xl"
      display="flex"
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      borderRadius="10px"
    >
      <Box>
        <Image
          src={photo}
          height={["286px", "156px"]}
          minWidth={["100%", "122px"]}
          borderRadius="15px"
          padding="0 10px"
        />
      </Box>
      <Box
        margin="0 auto"
        padding="0 10px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={["100%", "50%"]}
      >
        <Box
          display="flex"
          width="100%"
        >
          <Text
            margin={["10px auto", "0 auto"]}
            fontSize="20px"
            lineHeight="23px"
            textAlign="center"
            fontWeight="bold"
          >
            {name}
          </Text>
        </Box>
        
        <Text
          display={["", "none"]}
          color={theme.colors.gray500}
          fontSize="15px"
          lineHeight="18px"
          textAlign="center"
        >
          {description.substring(0,50)} ...
        </Text>
        <Box>
          <Box
            margin="10px 0px"
            width={["330px", ""]}
            padding="0 20px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={["row", "column"]}
          >
            <Box
            >
              <Text
                fontSize="22px"
                fontWeight="bold"
                lineHeight="29px"
                color={theme.colors.orange500}
              >
                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price)}
              </Text>
            </Box>
            <Box>
              <Button
                marginTop="20px"
                background={theme.colors.primary}
                color="white"
                borderRadius="5px"
                width="100%"
                _hover={{
                  background: theme.colors.opacity,
                  color: theme.colors.primary,
                  border: `1px solid ${theme.colors.primary}`,
                  transition: "0.5s ease",
                }}
                onClick={(e) => handleSetSelectedProduct(id)}
              >
                Ver mais
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
