import { Box, Container, Button, Input, Text } from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import { PartnerMenu } from "../../components/PartnerMenu";
import { theme } from "../../styles/theme";
import { api } from "../../services/api";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { MobileNavbar } from "../../components/MobileNavbar";

export function QRCode() {
  const { user } = useAuth();

  const [QRCode, setQRCode] = useState(null);
  const [productData, setProductData] = useState(null);
  const [QRCodeIsValid, setQRCodeIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [deliveryConfirmed, setDeliveryConfirmed] = useState(false)

  async function handleQRCodeValidation(e) {
    if(!user) {
      return;
    }

    if(QRCode === null){
      return;
    }

    const data = {
      qr_code: QRCode
    }

    const response = await api.post(`/api/v1/companies/${user.company_id}/code_validations`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
        uid: user.uid,
        client: user.client,
        'access-token': user.accessToken,
        'Access-Control-Allow-Origin': '*',
      },
    })

    if(response.data.error) {
      setErrorMessage(response.data.error)
    }

    setProductData(response.data)
    setQRCodeIsValid(true)
  }

  async function handleCustomerHasCollectTheProduct() {
    const data = {
      qr_code: QRCode
    }

    const response = await api.post(`/api/v1/companies/${user.company_id}/product_collected`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
        uid: user.uid,
        client: user.client,
        'access-token': user.accessToken,
        'Access-Control-Allow-Origin': '*',
      },
    })

    if (response.status === 200) {
      alert("RETIRADA REGISTRADA")
      window.location.reload();
    }
  }

  return (
    <>
      <Navbar />
      <MobileNavbar />
      <Container  
        maxW={["container.sm", "container.xl"]}
        padding="30px 0"
      >
        <PartnerMenu /> 
        <Box
          margin="20px 0"
          display="flex"
          flexDirection={"column"}
          width="100%"
          padding={["20px", "0"]}
        >
          <Text
            fontSize="18px"
            fontWeight="bold"
          >
            Insira o código abaixo para validar retirada do produto</Text>
          <Input
            placeholder="Código"
            onChange={(e) => setQRCode(e.target.value)}
          />
          {QRCode !== null && (
            <Button
              background={theme.colors.primary}
              onClick={(e) => handleQRCodeValidation(e)}
            >
              Validar
            </Button>
          )}
        </Box>
        <Box>
          {QRCodeIsValid && productData && !errorMessage ? (
            <Box
              border={`1px solid ${theme.colors.primary}`}
              padding="20px"
            >
              <Text
                fontWeight="bold"
                fontSize="22px"
              >
                INFORMAÇÕES PARA A RETIRADA
              </Text>
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box>
                    <Text><b>Cliente: </b>{productData?.profile?.full_name}</Text>
                    <Text><b>Product: </b>{productData?.product?.name} - {productData?.product?.price} </Text>
                  </Box>
                  <Box>
                    {productData.payment?.paid === true && (
                      <Button
                        background={theme.colors.primary}
                        color={"#fff"}
                        cursor="none"
                        _hover={{
                          background: `${theme.colors.primary}`,
                          color: "#fff"
                        }}
                      >
                        PAGAMENTO CONFIRMADO
                      </Button>
                    )}
                  </Box>
                </Box>   
                <Box
                  margin="30px 0"
                >
                  <Button
                     background={theme.colors.primary}
                     color={"#fff"}
                     _hover={{
                      background: `${theme.colors.primary}`,
                      color: "#fff"
                    }}
                    onClick={() => handleCustomerHasCollectTheProduct()}
                  >
                    Confirmar retirada do produto
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Text>
                {errorMessage}
              </Text>
            </>
          )}
        </Box>
      </Container>
    </>
  )
}