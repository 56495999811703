import { useEffect, useState } from "react";
import { Box, Button, Checkbox, Container, Grid, GridItem, Image, Input, Text } from "@chakra-ui/react";
import { Navbar } from "../../components/Navbar";
import { MobileNavbar } from "../../components/MobileNavbar";

import { theme } from '../../styles/theme';
import { Link, useNavigate } from "react-router-dom";

import IconLogo from '../../assets/images/icon.svg';
import LineIcon from '../../assets/images/line.svg';
import AddPhoto from '../../assets/images/add_photo.svg';
import { useAuth } from "../../hooks/useAuth";

import InputMask from "react-input-mask";

export function SignUp() {
  const { user, signUpUser } = useAuth();

  const [emailIsInserted, setEmailIsInserted] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordConfirmation, setUserPasswordConfirmation] = useState("");
  const history = useNavigate();

  const handleEmailInsertion = (e) => {
    e.preventDefault();
    setEmailIsInserted(true);
  };
  
  async function handleRegistration(e) {
    e.preventDefault();
    signUpUser(userEmail, userPassword);
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !emailIsInserted) {
      e.preventDefault();
      handleEmailInsertion(e);
    } else if (e.key === "Enter" && emailIsInserted) {
      e.preventDefault(); 
      handleRegistration(e);
    }
  };

  useEffect(() => {
    if (user) {
      history.push(`/`);
    }
  }, []);

  return (
    <Box>
      <Navbar />
      <MobileNavbar />
      <Container
        maxW="container.md"
      >
        {emailIsInserted === false ? (
          <>
            <Box
              marginBottom="20px"
              width="100%"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              marginTop={"75px"}
            >
              <Image 
                src={IconLogo} 
                alt="Logo" 
              />
              <Text
                fontSize={"50px"}
                marginTop={"15px"}
                fontWeight={"bold"}
              >
                CADASTRO
              </Text>
              <Text
                fontSize={"40px"}  
              >
                Email
              </Text>
              <Input
                width={"375px"}
                placeholder="Digite seu email"
                marginTop={"20px"}
                onKeyPress={handleKeyPress}
                onChange={(e) => {setUserEmail(e.target.value)}}
              />
            </Box>
            {/* <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              margin={"20px 0"}
            >
              <Image 
                src={LineIcon}
              />  
              <Text
                fontSize={"25px"}
                margin={"0 10px"}
              >
                OU
              </Text>
              <Image 
                src={LineIcon}
              />
            </Box> */}
            <Box
              width="100%"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Button
                width={"375px"}
                height={"65px"}
                color={"white"}
                backgroundColor={theme.colors.primary}
                _hover={{
                  backgroundColor: theme.colors.primary,
                  opacity: 0.9,
                  transition: "0.2s"
                }}
                onClick={handleEmailInsertion}
              >
                Cadastrar
              </Button>
              <Box
                marginTop={"10px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Text>Já tem uma conta?</Text>
                <Text
                  color={theme.colors.primary}
                  fontWeight={"bold"}
                >
                  <Link to="/sign_in">
                    Faça o login
                  </Link>
                </Text>
              </Box>
            </Box>
          </>
        ) : (
          <>
           <Box
              width="100%"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              marginTop={"75px"}
            >
              <Image 
                src={IconLogo} 
                alt="Logo" 
              />
              <Text
                fontSize={"50px"}
                marginTop={"15px"}
                fontWeight={"bold"}
              >
                REGISTRO
              </Text>
              <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                <GridItem w='100%'>
                  <Input 
                    type="text" 
                    placeholder="Nome completo" 
                    marginBottom={"20px"}
                    value={userFullName}
                    onChange={(e) => setUserFullName(e.target.value)}
                  />
                  <Input 
                    type="text"
                    placeholder="Número de telefone"
                    marginBottom={"20px"}
                    as={InputMask} mask="(99) 99999-9999" maskChar={null}
                    value={userPhone}
                    onChange={(e) => setUserPhone(e.target.value)}
                  />
                  <Input 
                    value={userEmail} 
                    placeholder="Digite seu email"
                    marginBottom={"20px"}
                  />
                  <Input 
                    type="password" 
                    placeholder="Digite sua senha" 
                    marginBottom={"20px"}
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                  <Input 
                    type="password" 
                    placeholder="Confirme sua senha" 
                    marginBottom={"20px"}
                    value={userPasswordConfirmation}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setUserPasswordConfirmation(e.target.value)}
                  />
                </GridItem>
                <GridItem w='100%' marginBottom="300px">
                  <Box
                    width="100%"
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Image 
                      src={AddPhoto}
                    />
                    <Box>
                      <Checkbox
                      >
                        Ao criar uma conta, você concorda com nossos Termos de Serviço e Políticas de Privacidade
                      </Checkbox>
                    </Box>
                    <Button
                      width={"375px"}
                      backgroundColor={theme.colors.primary}
                      color={"white"}
                      margin={"20px 0"}
                      onClick={handleRegistration}
                    >
                      Registrar
                    </Button>
                    <Box
                      marginTop={"10px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection={"column"}
                    >
                      <Text>Já tem uma conta?</Text>
                      <Text
                        color={theme.colors.primary}
                        fontWeight={"bold"}
                      >
                        Faça o login
                      </Text>
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </Box>
  )
}
