import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { MapPinLine, Star } from 'phosphor-react';
import { theme } from '../../styles/theme';
import { Stars } from '../Stars';
import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';
import { Link } from 'react-router-dom';
import { NextToMeCard } from '../NextToMeCard';

export function NextToMe({company}){
  const { user } = useAuth();

  const [userProfile, setUserProfile] = useState();
  const [companiesNextToMe, setCompaniesNextToMe] = useState();

  const [allCompanies, setAllCompanies] = useState();

  const [showAllCompanies, setShowAllCompanies] = useState(companiesNextToMe);

  async function handleUserProfile() {
    if (!user) {
      return;
    }

    const response = await api.get(`api/v1/profiles?user_id=${user.id}`)
    const data = response.data;
    setUserProfile(data);
  }

  async function handleNextToMeOffers() {
    if (!user) {
      return;
    }

    const response = await api.get(`api/v1/companies_next_to_me?user_id=${user.id}`)
    const data = response.data
    setCompaniesNextToMe(data);
  }

  async function fetchCompanies(){
    const response = await api.get("/api/v1/companies");
    setAllCompanies(response.data)
  }


  useEffect(() => {
    handleUserProfile()
  }, [user]);

  useEffect(() => {
    handleNextToMeOffers()
    fetchCompanies()
  }, [userProfile]);

  return (
    <Container
      maxW="container.xl"
      padding="0"
    >
      <Box
        width={["100%", "100%"]}
        display="flex"
        flexDirection={["row"]}
        alignItems={["center", "center"]}
        marginBottom="10px"
        marginTop="50px"    
      >
        <Text
          fontSize="30px"
          marginRight="30px"
          fontWeight="bold"
        >
          Próximos a mim
        </Text>
        {allCompanies && (
          <>
            <Link to="/empresas">
              <Text
                onClick={(e) => setShowAllCompanies(allCompanies)}
                cursor="pointer"
                _hover={{
                  textDecoration: "underline",
                  color: theme.colors.primary,
                }}
              >
                Ver mais
              </Text>
            </Link>
          </>
        )
      }
      </Box>
      <Box
        display="flex"
        flexDirection={["column", "row"]}
        width={["87%", "100%"]}
      >
      <Grid 
        // templateColumns="repeat(4, 266px)" 
        templateColumns={[`repeat(1, 366px)`, `repeat(2, 266px)`, `repeat(3, 33%)`]}
        gap={6}
        padding={["0", "0 39px"]}
      >
        {
          user && companiesNextToMe?.length > 0 ? (
            companiesNextToMe?.slice(0,3).map((company) => {
              console.log(company)
              return (
                <GridItem>
                  <NextToMeCard 
                    companyId={company.id}
                    companyLogo={company.logo_url || `https://images.unsplash.com/photo-1626128021426-ec3c94f61626?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=465&q=80` }
                    companyName={company.name}
                    companyAddressStreet={company.address_street}
                    companyAddressNumber={company.address_number}
                    companyAddressComplement={company.address_complement}
                    companyAddressCity={company.address_city}
                    companyAddressNeighborhood={company.address_neighborhood}
                    companyAddressState={company.address_state}
                    companyFantasyName={company.fantasy_name}
                  />
                </GridItem>
              )
            })
        ) : (
            <>
              {!user && (
                <Text>Faça o seu login e receba as melhores ofertas</Text>
              )}
            </>
          )
        }   
      </Grid>
       
      </Box>
    </Container>
  )
}