import { Routes, Route } from 'react-router-dom'
import { Cart } from './pages/Cart';
import { Configuration } from './pages/Configuration';
import { Home } from "./pages/Home";
import { Product } from './pages/Product';
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { Success } from './pages/Success';
import { PartnerSignUp } from './pages/PartnerSignUp';
import { PartnerDashboard } from './pages/PartnerDashboard';
import { PartnerProducts } from './pages/PartnerProducts';
import { Orders } from './pages/Orders';
import { PartnerOrders } from './pages/PartnerOrders';
import { LandingPage } from './pages/LandingPage';
import { Payment } from './pages/payment';
import { Products } from './pages/Products';
import { QRCode } from './pages/QRCode';
import { LastDays } from './pages/LastDays';
import { Password } from './pages/Password';
import { Companies } from './pages/Companies';
import { Company } from './pages/Company';

export default function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/sign_up" element={<SignUp />} />
      <Route path="/sign_in" element={<SignIn />} />
      <Route path="/success" element={<Success />} />
      <Route path="/configuration" element={<Configuration />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/produtos" element={<Products />} />
      <Route path="/empresas" element={<Companies />} />
      <Route path="/empresa/:id" element={<Company />} />
      <Route path="/acabando" element={<LastDays />} />
      <Route path="/password" element={<Password />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/partner/sign_up" element={<PartnerSignUp />} />
      <Route path="/partner/dashboard" element={<PartnerDashboard />} />
      <Route path="/partner/dashboard/products" element={<PartnerProducts />} />
      <Route path="/partner/dashboard/orders" element={<PartnerOrders />} />
      <Route path="/partner/dashboard/qr_code_validator" element={<QRCode />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/parceiro" element={<LandingPage />} />
      <Route path="pagamento" element={<Payment />} />
    </Routes>
  )
}
