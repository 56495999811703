import { 
  Box,
  Button, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  Text, 
  useDisclosure 
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {
  BsStar,
  BsStarFill
} from 'react-icons/bs'
import { theme } from "../../styles/theme";
import { api } from "../../services/api";

export function RatingProduct({
  title,
  product,
  user
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [rating, setRating] = useState(0);

  const handleStarHover = (starIndex) => {
    setRating(starIndex);
  };

  const handleStarLeave = () => {
    setRating(0);
  };

  async function handleStarClick(starIndex) {
    console.log(product)
    setRating(starIndex);

    const data = {
      rating: {
        value: starIndex,
        product_id: product.product_id,
        user_id: user.id
      }
    }
    
    const response = await api.post('/api/v1/ratings', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.token}`,
        uid: user.uid,
        client: user.client,
        'access-token': user.accessToken,
        'Access-Control-Allow-Origin': '*',
      }
    })

    if(response.status === 201) {
      window.location.reload();
    } else {
      alert("Erro ao avaliar o produto")
      window.location.reload();
    }
  };

  return (
    <>
      <ModalContent>
        <ModalHeader>Avalie o produto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            marginBottom="20px"
          >
            Como você avalia de 1 a 5 o produto {title}?
          </Text>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            width="50"
            margin="20px 0"
          >
          {[1, 2, 3, 4, 5].map((starIndex) => (
            <BsStarFill
              key={starIndex}
              size="30px"
              color={starIndex <= rating ? theme.colors.yellow500 : theme.colors.gray500}
              onMouseEnter={() => handleStarHover(starIndex)}
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick(starIndex)}
              cursor="pointer"
            />
          ))}
          </Box>
        </ModalBody>
      </ModalContent>
    </>
  )
}