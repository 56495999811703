import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Image, IconButton } from '@chakra-ui/react';
import { api } from '../../services/api';
import { theme } from '../../styles/theme';

import SnackIcon from '../../assets/images/snacks.png';
import SnackWhite from '../../assets/images/snacks_white.png';

import { CategoryContext } from '../../contexts/CategoryContext';

import { 
  FaShoppingBasket,
  FaShoppingCart,
  FaStoreAlt,
  FaStore,
  FaWineBottle,
  FaTv,
  FaRegFutbol,
  FaWrench,
  FaSpa,
  FaRegKeyboard,
  FaTshirt,
  FaClinicMedical,
  FaBroom,
  FaShoppingBag,
  FaSprayCan,
  FaCandyCane 
} from 'react-icons/fa';

import { BiCookie } from "react-icons/bi";

import {
  MdPhoneAndroid,
  MdDirectionsCar,
 } from 'react-icons/md';

import { GiLipstick, GiChipsBag, GiHerbsBundle } from 'react-icons/gi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

export default function Categories() {
  const [startIndex, setStartIndex] = useState(0);

  const { handleSetCategory } = useContext(CategoryContext);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(1);

  const [itemsPerRow, setItemsPerRow] = useState(2);

  useEffect(() => {
    async function fetchCategories() {
      const response = await api.get('/api/v1/categories');
      setCategories(response.data);
    }

    fetchCategories();
  }, []);

  useEffect(() => { 
    const width = window.innerWidth;

    if (width > 600) {
      setItemsPerRow(6); // Number of items per row for wider screens
    }
  }, [])

  function handleSetSelectedCategory(id) {
    setSelectedCategory(id);
    handleSetCategory(id);
  }

  function handleDisplayIcon(icon) {
    if (icon === 'todos') {
      return (
        <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="center" 
      >
        {selectedCategory === 1 ? 
          <FaShoppingBag 
            color={theme.colors.white} 
            fontSize="42px"
          /> 
        : 
          <FaShoppingBag 
            color={theme.colors.primary } 
            fontSize="42px"
          />
        }
      </Box>

      )
    } else if (icon === 'mercado') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
        >
          {selectedCategory === 2 ? 
            <FaShoppingBasket 
              color={theme.colors.white} 
              fontSize="42px"
            /> 
          : 
            <FaShoppingBasket 
              color={theme.colors.primary } 
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'conveniencia') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 3 ? 
            <FaShoppingCart 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaShoppingCart
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'acougue') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 4 ? 
            <FaStoreAlt 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaStoreAlt 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'padaria') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 5 ? 
            <FaStore 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaStore 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'bebidas') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 6 ? 
            <FaWineBottle 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaWineBottle 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'eletrodomesticos') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 7 ? 
            <FaTv 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaTv 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'artigos_esportivos') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 8 ? 
            <FaRegFutbol 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaRegFutbol 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'materiais_construcao') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 9 ? 
            <FaWrench 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaWrench 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'decoracao') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 10 ?
            <FaSpa 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaSpa 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'computadores_e_acessorios') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 11 ?
            <FaRegKeyboard 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaRegKeyboard 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'aparelhos_moveis') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 12 ?
            <MdPhoneAndroid 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <MdPhoneAndroid 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'vestuario') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 13 ?
            <FaTshirt 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaTshirt 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'produtos_automotivos') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 14 ?
            <MdDirectionsCar 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <MdDirectionsCar 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'beleza_e_higiene') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 15 ?
            <FaSprayCan 
              color={theme.colors.white}
              fontSize="42px"
            />
          : 
            <FaSprayCan 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'farmacia') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 16 ?
            <FaClinicMedical 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaClinicMedical 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'limpeza') {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 17 ?
            <FaBroom 
              color={theme.colors.white}
              fontSize="42px"
            /> 
          : 
            <FaBroom 
              color={theme.colors.primary}
              fontSize="42px"
            />
          }
        </Box>
      );
    } else if (icon === 'loja_de_cosmeticos') {
      return (
        <Box
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 18 ?
            <GiLipstick 
              color={theme.colors.white}
              fontSize="42px"
            /> 
            :
            <GiLipstick 
              color={theme.colors.primary}
              fontSize="42px"
            /> 
          }
        </Box>
      )
    } else if (icon === 'doces') {
      return (
        <Box
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 19 ?
            <FaCandyCane
              color={theme.colors.white}
              fontSize="42px"
            /> 
            :
            <FaCandyCane 
              color={theme.colors.primary}
              fontSize="42px"
            /> 
          }
        </Box>
      )
    } else if (icon === 'biscoito_ou_bolachas') {
      return(
        <Box
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 20 ?
            <BiCookie
              color={theme.colors.white}
              fontSize="42px"
            /> 
            :
            <BiCookie 
              color={theme.colors.primary}
              fontSize="42px"
            /> 
          }
        </Box>
      )
    } else if (icon === "petiscos") {
      return (
        <Box
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 21 ?
            <GiChipsBag
              color={theme.colors.white}
              fontSize="42px"
            /> 
            :
            <GiChipsBag 
              color={theme.colors.primary}
              fontSize="42px"
            /> 
          }
        </Box>
      )
    } else if (icon === 'ervas') {
      return (
        <Box
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 22 ?
            <GiHerbsBundle
              color={theme.colors.white}
              fontSize="42px"
            /> 
            :
            <GiHerbsBundle 
              color={theme.colors.primary}
              fontSize="42px"
            /> 
          }
        </Box>
      )
    }
    else {
      return (
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center"
        >
          {selectedCategory === 4 ? <Image src={SnackWhite} /> : <Image src={SnackIcon} />}
        </Box>
      );
    }
  }

  return (
    <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    width="100%"
    height={135}
    margin={["0", "14px auto 0"]}      
    borderRadius={20}
  >
    {startIndex > 0 ? (
      <IconButton
        icon={<BsChevronLeft />}
        onClick={() => setStartIndex(startIndex - 1)}
      />
    ) : (
      <IconButton
        icon={<BsChevronLeft />}
      />
    )}
    {categories &&
      // if mobile must be only 2 items if more than 600px must be 6 items
      
      categories.slice(startIndex, startIndex + itemsPerRow).map(category => {
        return (
          <Box
            key={category.id}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            cursor="pointer"
          >
            <Box
              width="72px"
              height="72px"
              borderRadius="50%"
              backgroundColor={
                selectedCategory === category.id
                  ? theme.colors.primary
                  : theme.colors.opacity
              }
              onClick={() => handleSetSelectedCategory(category.id)}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="16px"
              >
                {handleDisplayIcon(category.icon)}
              </Box>
            </Box>

            <Text fontSize="16px" lineHeight="19px" fontWeight="bold">
              {category.title}
            </Text>
          </Box>
        );
      })}
      {startIndex < categories.length - 6 ? (
        <IconButton
          icon={<BsChevronRight />}
          onClick={() => setStartIndex(startIndex + 1)}
        />
      ) : (
        <IconButton
          icon={<BsChevronRight />}
        />
      )}
    </Box>
  );
}
