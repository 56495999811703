import { 
  Box, 
  Button, 
  Center, 
  Flex, 
  Image, 
  Input, 
  InputGroup, 
  InputLeftElement, 
  InputRightElement,
  IconButton,
  ChakraProvider,
  Stack, 
  Text, 
  Select,
  Container
} from "@chakra-ui/react";
import {ArrowBackIcon, ViewIcon, ViewOffIcon} from '@chakra-ui/icons';

import { Navbar } from "../../components/Navbar";
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import InputMask from "react-input-mask";
import SigninCarouselPartner from "../../components/SigninCarouselPartner";

import SignUpPartner from '../../assets/images/sign_up_partner.png';
import EmailIcon from '../../assets/images/icon_material_email.png';
import LineIcon from '../../assets/images/line.svg';
import SmileLogo from '../../assets/images/smile_logo.svg';
import Rectangle from '../../assets/images/rectangle.png';

import { theme } from "../../styles/theme";
import { Link } from 'react-router-dom';
import { CompanyContract } from "../../components/CompanyContract";
import { useStepper } from "../../hooks/useStepper";

import SingupPartner1 from '../../assets/images/sign_up_partner.png';
import SingupPartner2 from '../../assets/images/sign_up_partner_2.png';
import SingupPartner3 from '../../assets/images/sign_up_partner_3.png';
import SingupPartner4 from '../../assets/images/sign_up_partner_4.png';
import { MobileNavbar } from "../../components/MobileNavbar";

export function PartnerSignUp() {
  const { user, isAuthenticated, signUpUser, signInUser } = useAuth();
  const [screenWidth, setScreenWidth] = useState(true);

  const { step, handleSetStepper } = useStepper();

  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [userPartnerName, setUserPartnerName] = useState("");
  const [userPartnerEmail, setUserPartnerEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [userPasswordConfirmation, setUserPasswordConfirmation] = useState("");
  const [userPartnerNameCompany, setUserPartnerNameCompany] = useState("");
  const [userPartnerFantasyName, setUserPartnerFantasyName] = useState("");
  const [userPartnerCnpj, setUserPartnerCnpj] = useState("");
  const [rawUserPartnerCnpj, setRawUserPartnerCnpj] = useState("");
  const [userPartnerAddressStreet, setUserPartnerAddressStreet] = useState("");
  const [userPartnerAddressPostalCode, setUserPartnerAddressPostalCode] = useState("");
  const [userPartnerAddressNumber, setUserPartnerAddressNumber] = useState("");
  const [userPartnerAddressComplement, setUserPartnerAddressComplement] = useState("");
  const [userPartnerAddressNeighborhood, setUserPartnerAddressNeighborhood] = useState("");
  const [userPartnerAddressCity, setUserPartnerAddressCity] = useState("");
  const [userPartnerAddressState, setUserPartnerAddressState] = useState("");
  const [segments, setSegments] = useState([])
  const [partnerCompanySegment, setPartnerCompanySegment] = useState("")

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
    setShowPasswordConfirmation(!showPasswordConfirmation)
  };

  useEffect(() => {
    setRawUserPartnerCnpj(userPartnerCnpj.replace(/\D/g, ''));
  }, [userPartnerCnpj]);

  useEffect(() => { 
    const width = window.innerWidth;

    if (width < 768) {
      setScreenWidth(false);
    }
  }, [])

  function handlePasswordIsValid() {
    if((userPartnerName !== "" && userPartnerEmail !== "" ) && (userPassword !== "" && userPasswordConfirmation !== "") && userPassword === userPasswordConfirmation) {
      setPasswordIsValid(true)
    } else {
      setPasswordIsValid(false)
    }
  }

  useEffect(() => {
    handlePasswordIsValid()
  }, [userPassword, userPasswordConfirmation])
   
  async function handleViaCnpj(e) {
    try{
      const response = await api.get(`https://publica.cnpj.ws/cnpj/${rawUserPartnerCnpj}`)
      setUserPartnerNameCompany(response.data.razao_social)
      setUserPartnerFantasyName(response.data.estabelecimento.nome_fantasia || "")
      setUserPartnerAddressStreet(response.data.estabelecimento.logradouro)
      setUserPartnerAddressNeighborhood(response.data.estabelecimento.bairro)
      setUserPartnerAddressNumber(response.data.estabelecimento.numero)
      setUserPartnerAddressComplement(response.data.estabelecimento.complemento || "")
      setUserPartnerAddressCity(response.data.estabelecimento.cidade.nome)
      setUserPartnerAddressState(response.data.estabelecimento.estado.nome)
      setUserPartnerAddressPostalCode(response.data.estabelecimento.cep)
    } catch (error) {
      alert("CNPJ inválido")
      return;
    }
  }

  async function handleCompanySegment() {
    try {
      const response = await api.get("/api/v1/segments");
      const data = response.data.map((segment) => ({
        value: segment.id,
        label: segment.name,
      }));
      setSegments(data);
    } catch (error) {
      alert("Erro ao carregar os segmentos");
    }
  }
  
  async function handlePartnerRegistration(e) {
    e.preventDefault();
    const data = {
      user: {
        email: userPartnerEmail,
        password: userPassword,
        password_confirmation: userPasswordConfirmation,
        access: 'partner',
      },
      company: {
        name: userPartnerNameCompany,
        fantasy_name: userPartnerFantasyName,
        segment_id: partnerCompanySegment,
        cnpj: userPartnerCnpj,
        address_street: userPartnerAddressStreet,
        address_number: userPartnerAddressNumber,
        address_complement: userPartnerAddressComplement,
        address_neighborhood: userPartnerAddressNeighborhood,
        address_city: userPartnerAddressCity,
        address_state: userPartnerAddressState,
        address_postal_code: userPartnerAddressPostalCode,
      }
    }

    const response = await api.post('/api/v1/partner_profiles', data, {
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      }
    })
    if (response.status === 201) {
      handleSetStepper(4)
    }

    signInUser(userPartnerEmail, userPassword)
 }

 useEffect(() => {
    handleStepController()
 }, [step])

 useEffect(() => {
  handleCompanySegment()
 }, [])

  async function handleRegistration(e) {
    e.preventDefault();
     handlePartnerRegistration(e);
  }

  const images = [
    SingupPartner1,
    SingupPartner2,
    SingupPartner3,
    SingupPartner4
  ];

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSetStepper(step + 1)
    }
  }

  function handleStepper() { 
    return (
      <>
       <Box
          width="24px"
          height="24px"
          background={step >=2  ? "green" : "gray.300"}
          borderRadius="50%"
        />
        <Box
          width="24px"
          height="24px"
          background={step >= 3 ? "green" : "gray.300"}
          border={step >= 3 ? "" : "2px solid green"}
          borderRadius="50%"
        />
      </>
    )
  }

  function handleStepController() {
     if(step === 1) {
        return (
          <Container
            maxW={["container.sm", "container.xl"]}
            padding="20px 0"
          >
            <Box
              display="flex"
              justifyContent={["center", "space-around"]}
            >
              {screenWidth && (
                <Box>
                  <SigninCarouselPartner images={images} />
                </Box>
                )
              }
              <Box>
                <Box
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  marginLeft={["0", "80px"]}
                >
                  <Text
                    fontSize={"50px"}
                    marginTop={"100px"}
                    textAlign="center"
                  >
                    Cadastro de Parceiro
                  </Text>
                  <Stack spacing={4} margin={["0 auto", ""]} width={["87%", "440px"]} height={"70px"}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<Image src={EmailIcon} color={theme.colors.gray400} padding={"30px 0 0 10px"} />}
                      />
                    </InputGroup>
                    <Input
                      placeholder="Digite seu email"
                      paddingLeft={"65px"}
                      onKeyDown={handleKeyPress}
                      onChange={(e) => {setUserPartnerEmail(e.target.value)}}
                    />
                  </Stack>
                  <Box
                    width="90%"
                    alignItems={"center"}
                    marginTop={"50px"}
                    margin={["0 auto", "50px 0 0 0"]}
                  >
                    <Box
                      marginTop={"10px"}
                      alignItems={"center"}
                      margin={["0 auto", "0 0 0 35px"]}
                    >
                      <Button
                        width={["100%", "375px"]}
                        height={"65px"}
                        color={"white"}
                        backgroundColor={theme.colors.primary}
                        _hover={{
                          backgroundColor: theme.colors.primary,
                          opacity: 0.9,
                          transition: "0.2s"
                        }}
                        onClick={() => handleSetStepper(2)}
                      >
                        Cadastrar
                      </Button>
                    </Box>
                    <Box
                      marginTop={"10px"}
                      alignItems={"center"}
                      marginLeft={["0", "0"]}
                    >
                      <Text
                        textAlign="center"
                      >
                        Já tem uma conta?
                      </Text>
                      <Link to="/sign_in">
                        <Text
                          color={theme.colors.primary}
                          fontWeight={"bold"}
                          marginLeft={"25px"}
                          textAlign="center"
                        >
                          Faça o login
                        </Text>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        )
     } else if(step === 2) {
      return (
        <Container
          maxW={["container.sm", "container.lg"]}
        >
          <Box
            width={["100%", "100%"]}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            marginTop={"25px"}
          >
            <Text
              fontSize={"50px"}
              marginTop={"30px"}
              textAlign={["center", ""]}
            >
              Registre seu negócio
            </Text>
            <Stack direction="row">
              {handleStepper()}
            </Stack>
            <Text 
              fontSize={"30px"}
              marginTop={"21px"}
              color={theme.colors.gray500}
            >
              Sobre você
            </Text>
            <Box
              width="100%"
            >
              <InputGroup>
              <Input
                placeholder="Nome Completo"
                width={"100%"}
                height={"50px"}
                marginTop={"34px"}
                onChange={(e) => setUserPartnerName(e.target.value)}
              />
              </InputGroup>
              <InputGroup>
                <Input
                  placeholder="Email"
                  width={"100%"}
                  height={"50px"}
                  marginTop={"25px"}
                  value={userPartnerEmail}
                  disabled
                />
              </InputGroup>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Digite sua senha"
                    width={"100%"}
                    height={"50px"}
                    marginTop={"25px"}
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                  <InputRightElement
                  >
                    <IconButton
                      size="md"
                      variant="ghost"
                      marginTop={"60px"}
                      marginRight={"10px"}
                      color={theme.colors.primary}
                      onClick={handleTogglePassword}
                      aria-label={showPassword ? "Hide password" : "Show password"}
                      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    />
                  </InputRightElement>
                </InputGroup>
                <InputGroup>
                  <Input
                    type={showPasswordConfirmation ? "text" : "password"}
                    placeholder="Confirmar Senha"
                    width={"100%"}
                    height={"50px"}
                    marginTop={"25px"}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setUserPasswordConfirmation(e.target.value)}
                  />
                  <InputRightElement
                  >
                    <IconButton
                      size="md"
                      variant="ghost"
                      marginTop={"60px"}
                      marginRight={"10px"}
                      color={theme.colors.primary}
                      onClick={handleTogglePassword}
                      aria-label={showPasswordConfirmation ? "Hide password" : "Show password"}
                      icon={showPasswordConfirmation ? <ViewOffIcon /> : <ViewIcon />}
                    />
                  </InputRightElement>
              </InputGroup>
              <Box
                display="flex"
                flexDirection={["row", "row"]}
                justifyContent="space-between"
                alignItems="center"
                gap={8}
              >
                <Button
                  width="10%"
                  background={theme.colors.red500}
                  onClick={() => handleSetStepper(1)}
                  _hover={{
                    background: theme.colors.red500,
                    opacity: 0.8,
                    transition: "0,2s"
                  }}
                >
                <ArrowBackIcon 
                  color={theme.colors.white}
                /> 
                </Button>
                {passwordIsValid === true ? (
                  <Button
                    width={"90%"}
                    backgroundColor={theme.colors.primary}
                    color={"white"}
                    margin={"20px 0"}
                    _hover={{
                      backgroundColor: theme.colors.primary,
                      opacity: 0.9,
                      transition: "0.2s"
                    }}
                    onClick={() => handleSetStepper(3)}
                  >
                    Continuar
                  </Button>
                ) : (
                  <Button
                    width={"90%"}
                    backgroundColor={theme.colors.gray500}
                    color={"white"}
                    margin={"20px 0"}
                    disabled={true}
                    _hover={{
                      backgroundColor: theme.colors.gray500,
                      opacity: 1,
                    }}
                  >
                    Continuar
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      )
     } else if(step === 3) {
        return (
          <>
            <Box
              width="100%"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              marginTop={"25px"}
            >
              <Text
                fontSize={"50px"}
                marginTop={"30px"}
              >
                Registre seu negócio
              </Text>
              <Stack direction="row">
                {handleStepper()}
              </Stack>
              <Text 
                fontSize={"30px"}
                marginTop={"21px"}
                color={theme.colors.gray500}
              >
                Sobre a sua empresa
              </Text>
              <Box 
                width={["87%", "100%"]}
                display={"flex"}
                flexDirection={["column", "row"]}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={"33px"}
                gap={"21px"}
              >
                <Input
                  placeholder="CNPJ"
                  width={["100%", "240px"]}
                  height={"50px"}
                  as={InputMask} mask="99.999.999/9999-99" maskChar={null}
                  value={userPartnerCnpj}
                  onChange={(e) => setUserPartnerCnpj(e.target.value)}
                  onBlur={(e) => handleViaCnpj(e.target.value)}
                />
                <Input
                  placeholder="Nome do Estabelecimento"
                  width={["100%", "310px"]}
                  height={"50px"}
                  value={userPartnerNameCompany}
                  onChange={(e) => setUserPartnerNameCompany(e.target.value)}
                />
                <Input
                  placeholder="Nome Fantasia"
                  width={["100%", "240px"]}
                  height={"50px"}
                  value={userPartnerFantasyName}
                  onChange={(e) => setUserPartnerFantasyName(e.target.value)}
                />
              </Box>
              <Box
                width={["87%", "100%"]}
                display={"flex"}
                flexDirection={["column", "row"]}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={"24px"}
                gap={"21px"}
              >
                <Input
                  placeholder="CEP"
                  width={["100%", "310px"]}
                  height={"50px"}
                  value={userPartnerAddressPostalCode}
                  as={InputMask} mask="99999-999" maskChar={null}
                  onChange={(e) => setUserPartnerAddressPostalCode(e.target.value)}
                />
                <Input
                  placeholder="Endereço"
                  width={["100%", "508px"]}
                  height={"50px"}
                  value={userPartnerAddressStreet}
                  onChange={(e) => setUserPartnerAddressStreet(e.target.value)}
                />
              </Box>
              <Box
                width={["87%", "100%"]}
                display={"flex"}
                flexDirection={["column", "row"]}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={"24px"}
                gap={"21px"}
              >
                <Input
                  placeholder="Bairro"
                  width={["100%", "508px"]}
                  height={"50px"}
                  value={userPartnerAddressNeighborhood}
                  onChange={(e) => setUserPartnerAddressNeighborhood(e.target.value)} 
                />
                <Input
                  placeholder="Número"
                  width={["100%", "129px"]}
                  height={"50px"}
                  value={userPartnerAddressNumber}
                  onChange={(e) => setUserPartnerAddressNumber(e.target.value)}
                />
                <Input
                  placeholder="Complemento"
                  width={["100%", "168px"]}
                  height={"50px"}
                  value={userPartnerAddressComplement}
                  onChange={(e) => setUserPartnerAddressComplement(e.target.value)}
                />
              </Box>
              <Box
                width={["87%", "100%"]}
                display={"flex"}
                flexDirection={["column", "row"]}
                justifyContent={"center"}
                alignItems={"center"}
                marginTop={"24px"}
                gap={"21px"}
              > 
                <Input
                  placeholder="Cidade"
                  width={["100%", "240px"]}
                  height={"50px"}
                  value={userPartnerAddressCity}
                  onChange={(e) => setUserPartnerAddressCity(e.target.value)}
                />
                <Input
                  placeholder="Estado"
                  width={["100%", "240px"]}
                  height={"50px"}
                  value={userPartnerAddressState}
                  onChange={(e) => setUserPartnerAddressState(e.target.value)}
                />
                <Select
                  placeholder="Segmento de Atuação"
                  width={["100%", "310px"]}
                  height={"50px"}
                  color={theme.colors.gray500}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setPartnerCompanySegment(e.target.value)}
                >
                  {segments.map((segment) => (
                    <option key={segment.value} value={segment.value}>
                      {segment.label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box
                width="100%"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  width={"50%"}
                  backgroundColor={theme.colors.primary}
                  color={"white"}
                  margin={"20px 0"}
                  _hover={{
                    backgroundColor: theme.colors.primary,
                    opacity: 0.9,
                    transition: "0.2s"
                  }}
                  onClick={(e) => handleRegistration(e)}
                >
                  Finalizar
                </Button>
              </Box>   
            </Box>
          </>
        ) 
     } else if(step === 4) {
        return (
          <CompanyContract 
            userPartnerName={userPartnerName}
            companyName={userPartnerNameCompany}
            companyCnpj={userPartnerCnpj}
            companyAddressStreet={userPartnerAddressStreet}
            companyAddressNumber={userPartnerAddressNumber}
            companyAddressComplement={userPartnerAddressComplement}
            companyAddressNeighborhood={userPartnerAddressNeighborhood}
            companyAddressCity={userPartnerAddressCity}
            companyAddressState={userPartnerAddressState}
            companyAddressPostalCode={userPartnerAddressPostalCode}
          />
        )
     } else {
        return (
          <>
            <Box
              width="100%"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'column'}
              backgroundImage={`url(${Rectangle})`}
              backgroundPosition={'center'}
              backgroundRepeat={'no-repeat'}
              backgroundSize={'contain'}
              marginTop={'75px'}
            >
            <Box/>
              <Image
                src={SmileLogo}
                alt="Logo"
                marginTop={'15px'}
                width={'150px'}
                height={'187px'}
              />
              <Text
                fontSize={'30px'}
                fontWeight={'bold'}
                marginTop={'15px'}
                color={theme.colors.secondary}
                textAlign={'center'}
              >
                Conta criada com <br /> sucesso!
              </Text>
      
              <Text
                fontSize={'20px'}
                marginTop={'15px'}
                color={theme.colors.gray500}
                textAlign={'center'}
              >
                Agora você já pode começar a aproveitar <br /> os melhores Descontos
              </Text>
              <Link to="/partner/dashboard">
                <Button
                  width={'315px'}
                  height={'50px'}
                  color={'white'}
                  margin={'30px auto'}
                  backgroundColor={theme.colors.primary}
                  _hover={{
                    backgroundColor: theme.colors.primary,
                    opacity: 0.9,
                    transition: '0.2s',
                  }}
                >
                  Continuar
                </Button>
              </Link>
            </Box>
          </>
        )
      }
  }

  return (
    <>
      <Box>
        <Navbar />
        <MobileNavbar />
        {handleStepController()}
      </Box>
    </>
  );
}
