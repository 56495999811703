import { Box, Image, Flex, IconButton, useDisclosure } from "@chakra-ui/react";


import { useEffect, useState } from "react";

const ImageCarousel = ({ images, autoPlayInterval = 5000 }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, autoPlayInterval);

    return () => {
      clearInterval(timer);
    };
  }, [images, autoPlayInterval]);


  return (
    <Box
      width="100%"
    >
      <Image 
        src={images[currentIndex]} 
        alt={`Image ${currentIndex}`} 
      />
    </Box>
  );
};

export default ImageCarousel;
