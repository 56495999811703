export function formatCurrency(value) {
  if (!value || typeof value !== "string") {
    return "";
  }

  let digitsOnly = value.replace(/\D/g, "");

  if (!digitsOnly) {
    return "";
  }

  const number = parseFloat(digitsOnly.replace(",", "."));

  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number / 100);

  return formattedValue;
}
