import { InfoIcon } from "@chakra-ui/icons"
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { theme } from "../../styles/theme"

export function ProductDetails({ 
  productDescription,
  isAlcoholic 
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        background={theme.colors.white}
        onClick={onOpen}
        border={`1px solid ${theme.colors.white}`}
        _hover={{
          background: theme.colors.white,
          border: `1px solid ${theme.colors.primary}`
        }}
      >
        <InfoIcon
          color={theme.colors.primary}
          _hover={{
            background: theme.colors.white
          }}
        />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Informações</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              marginBottom="20px"
            >
              {productDescription}
            </Text>
            <hr />
            <Text
              marginTop="20px"
              fontSize="12px"
              textAlign="center"
            >
              Imagem meramente ilustrativa
            </Text>
            {isAlcoholic && (
              <Text
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
              >
                Produtos para maiores de 18 anos
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}