import { useEffect, useState } from "react";
import { createContext } from "react";

export const CategoryContext = createContext({});

export function CategoryContextProvider(props) {
  const [category, setCategory] = useState(1);

  function handleSetCategory(id) {
    setCategory(id);
  }

  return (
    <CategoryContext.Provider
      value={{ category, handleSetCategory}}
    >
      {props.children}
    </CategoryContext.Provider>
  );
}
