import { Box, Button, Container, Image, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { theme } from '../../styles/theme';

import Logo from '../../assets/images/logo.svg';
import { useAuth } from '../../hooks/useAuth';
import { ShoppingCart} from 'phosphor-react';
import { Link, useLocation } from 'react-router-dom';
import { api } from '../../services/api';

export function Navbar() {
  const { user, isAuthenticated, signOutUser} = useAuth();

  const [userProfile, setUserProfile] = useState([]);

  const location = useLocation();

  async function fetchUserAddress() {
    if (!user) return;
    const response = await api.get(`/api/v1/profiles/?user_id=${user.id}`)
    setUserProfile(response.data[0])
  }

  function handleDisplayAddress() {
    return (
      <>
        {user?.access !== "partner" && userProfile ? (
          <>
            <Text>{userProfile.address_city} {userProfile.address_state}</Text>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  useEffect(() => {
    handleDisplayAddress();
  }, [userProfile])

  useEffect(() => {
    fetchUserAddress();
  }, [])

  useEffect(() => {
    isAuthenticated()
  }, [])

  const isLoginPage = location.pathname === '/sign_in';
  const isSignUpPage = location.pathname === '/partner/sign_up';

  return (
    <Box
      background={theme.colors.primary}
      height={144}
      width="100%"
      display={["none" ,"flex"]}
    >
      <Container
        maxW="container.xl"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Link
          to="/"
        >
          <Image 
            src={Logo} 
            alt="Logo" 
            width={350}
            padding={"30px 0 0 41px"}
          />
        </Link>
        
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          marginLeft={"20px"}
        >
          {!user && !isLoginPage && !isSignUpPage && (
          <Link
            to="/partner/sign_up"
          >
            <Button
              marginRight={"20px"}
            >
              Seja um parceiro
            </Button>
          </Link>
          )}
          
          {user && user.access === "partner" && (
            <Link
              to="/partner/dashboard"
            >
              <Button>
                Dashboard
              </Button>
            </Link>
          )}

          {!user && !isLoginPage && !isSignUpPage && (
            <Link
              to="/sign_in"
            >
              <Button
                marginLeft={"20px"}
              >
                Entrar
              </Button>
            </Link>
          )}
        </Box>
        
        {user && (
          <>
            <Box
              border={`2px solid ${theme.colors.lightPurple500}`}
              width={"380px"}
              padding={"10px"}
              borderRadius={"8px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
              <Link
                to="/cart"
              >
                <Box
                  display={"flex"}
                  flexDirection="column"
                  alignItems={"center"}
                  justifyContent={"center"}
                  borderRadius="11px"
                  height="102px"
                  width="92px"
                  cursor="pointer"
                >
                  <Box
                    background={theme.colors.background}
                    height="42px"
                    width="42px"
                    padding="5px"
                    borderRadius="17px"
                  >
                    <ShoppingCart 
                      size={32} 
                      weight="fill" 
                      color={theme.colors.primary}
                    />
                  </Box>
                  <Text
                    color={theme.colors.background}
                    fontSize={"15px"}
                  >
                    {/* R$ 500,00 total must be placed here */}
                  </Text>
                </Box>
              </Link>
            </Box>
              <Box>
                <Text
                  fontSize={"16px"}
                  fontWeight={"bold"}
                >
                  {user.email}
                  {handleDisplayAddress()}
                </Text>
              </Box>
              <Box
                borderRadius={"10px"}
                width={"42px"}
                height={"42px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                paddingBottom={"10px"}
              >
                <Menu
                  width={"100px"}
                  placement={"bottom-end"}
                >
                  <MenuButton 
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    as={Button} 
                    rightIcon={
                      <Image 
                        src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=461&q=80" 
                        width={"55px"}
                        height={"55px"}
                        borderRadius={"50%"}
                        marginRight={"10px"}
                      />
                    }
                  >
                  </MenuButton>
                  <MenuList>
                    <MenuItem minH='48px'>
                      <Link to={user.access === "partner" ? "/partner/dashboard" : "/orders"}>
                        {user.access === "partner" ? "Dashboard" : "Meus pedidos"}
                      </Link>
                    </MenuItem>
                    <MenuItem minH='48px'>
                      <Link to="/configuration">
                        Configurações
                      </Link>
                    </MenuItem>
                    <MenuItem minH='40px'
                      onClick={(e) => signOutUser()}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Text>
                          Sair
                        </Text>
                      </Box>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Box>
          </>
        )}
      </Container>
      
    </Box>
  )
}