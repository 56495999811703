import { Box, Button, GridItem, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { Link } from "react-router-dom";

import { MapPinLine } from 'phosphor-react';
import { CountDown } from "../CountDown";
import { ProductDetails } from "../ProductDetails";


export function ProductCard({
  productId,
  company,
  productName,
  productPhoto,
  productPrice,
  productMarketValue,
  productCompanyName,
  productCompanyFantasyName,
  productEndDate,
  productDescription,
  productDiscountPercentage,
  isAlcoholic
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box>
      {/* <Link to={`/product/${productId}`} key={productId}> */}
        <GridItem 
          key={productId}
          width={["336px", "100%"]}
          border={`1px solid ${theme.colors.primary}`}
          borderRadius="8px"
        >
          <Box>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              padding="20px"
              height="100px"
            >
              <ProductDetails
                productDescription={productDescription}
                isAlcoholic={isAlcoholic}
              />
              <Text
                fontSize="18px"
                fontWeight="bold"
                textAlign="center"
              >
                {productName}
              </Text>
            </Box>
            <Box
              display="flex"
              gap={4}
              padding="10px"
            >
              <Box
                width="40%"
              >
                <Image 
                  src={productPhoto} 
                  width="100%"
                  height="180px"
                />
              </Box>
              <Box
                width="60%"
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Box>
                    <Text
                      textDecoration="line-through"
                    >
                      De: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productMarketValue)}
                    </Text>
                    <Text
                      fontWeight="bold"
                      fontSize="18px"
                    >
                      Por: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productPrice)}
                    </Text>
                  </Box>
                    <Box
                      background={theme.colors.primary}
                      color={theme.colors.white}
                      padding="5px 10px"
                      borderRadius="10px"
                    >
                      <Text>{productDiscountPercentage}%</Text>
                    </Box>
                  <Box>
                  </Box>  
                </Box>
                <Box
                  margin="10px 0"
                >
                  <hr/>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  padding="5px 0"
                >
                  <Box>
                    <Text
                   
                      fontSize="14px"
                    >
                      <CountDown
                        productEndDate={productEndDate}
                      />
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    margin="20px 0"
                  >
                    <Button 
                      background={theme.colors.white}
                      onClick={onOpen}
                      border={`1px solid ${theme.colors.white}`}
                      _hover={{
                        background: theme.colors.white,
                        border: `1px solid ${theme.colors.primary}`
                      }}
                    >
                      <MapPinLine
                        color={theme.colors.primary}
                        width="24px"
                        height="24px"
                      />
                      <Text
                        textAlign="center"
                        width="70%"
                        padding="0 5px"
                      >
                        {company?.fantasy_name.substring(0,10)}
                      </Text>
                    </Button>
                    <Modal isOpen={isOpen} onClose={onClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Endereço de Retirada</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <Text>{company?.address_street}, {company?.address_number} {company?.address_complement && company.address_complement}</Text>
                          <Text>{company?.address_neighborhood}, {company?.address_city}, {company?.address_state}</Text>
                        </ModalBody>

                        <ModalFooter>
                          <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Fechar
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              padding="10px"
            >
              <Link to={`/product/${productId}`} key={productId}>
                <Button
                  width="100%"
                  color={theme.colors.primary}
                  _hover={{
                    background: theme.colors.primary,
                    color: theme.colors.white
                  }}
                >
                  Reservar
                </Button>
              </Link>
            </Box>
          </Box>
        </GridItem>
      {/* </Link> */}
    </Box>
  )
}