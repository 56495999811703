import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Box } from "@chakra-ui/react";

// const labels = ["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"];

export default function LineChart({
  productsOnDatabase,
  productsSoldLastDays,
  productsLastDaysDiscounts,
  lastDaysOrdersByDay
}) {

  const labels = []

  const data = {
    labels: labels,
    datasets: [
      // {
      //   label: "Produtos cadastrados",
      //   backgroundColor: "#58C0FC",
      //   borderColor: "#58C0FC",
      //   data: [0, 10, 5, 2, 20, 30, 50],
      //   tension: 0.4,
      // },
      {
        label: "Vendas - 7 dias",
        backgroundColor: "#FFC529",
        borderColor: "#FFC529",
        data: lastDaysOrdersByDay,
        tension: 1
      },
      // {
      //   label: "Promoções acabando",
      //   backgroundColor: "#FE2121",
      //   borderColor: "#FE2121",
      //   data: [1, 3, 2, 4, 9, 3, 2],
      //   tension: 0.4,
      // },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 50,
        stepSize: 10,
        ticks: {
          callback: (value) => (Number.isInteger(value) ? value : null),
        },
      },
    },
  };

  return (
    <Box>
      <Line 
        data={data} 
        options={options}
      />
    </Box>
  );
};
